import i18n from "@tecma/i18n";
import { AvailableSpaces } from "store/slices/dateSelectorState/slice";

export interface AvailabilityLabel {
  label: string;
  value: { [key: string]: number | string | undefined } | undefined;
}

export const isLastFewApartments = (apartments: number): boolean => {
  return apartments <= 10;
};

export const getAvailabilityLabel = ({
  count,
  firstEntryAvailable,
  loading,
}: AvailableSpaces): AvailabilityLabel => {
  if (loading) {
    return {
      label: "checking-availability",
      value: undefined,
    };
  }
  if (firstEntryAvailable?.startDate) {
    const firstAvailabilityDateLabel = new Date(
      firstEntryAvailable?.startDate,
    ).toLocaleString(i18n.language, {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return {
      label: "first-availability",
      value: { date: firstAvailabilityDateLabel },
    };
  }
  if (count === 0) {
    return {
      label: "sold-out-message",
      value: undefined,
    };
  }
  if (count === 1) {
    return {
      label: "one-apartment-available",
      value: undefined,
    };
  }
  if (isLastFewApartments(count)) {
    return {
      label: "few-apartments",
      value: { apartments: count },
    };
  }
  return {
    label: "apartments-available",
    value: { apartments: count },
  };
};
