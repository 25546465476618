import {
  GET_PROJECT_INFO,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
} from "./modules/Project";
import { UPDATE_MODEL_MUTATION, UPDATE_MODEL_OPTIONS } from "./modules/Model";
import {
  UPDATE_TYPOLOGY_MUTATION,
  UPDATE_TYPOLOGY_OPTIONS,
} from "./modules/Typology";
import { UPDATE_PLAN_MUTATION, UPDATE_PLAN_OPTIONS } from "./modules/Plan";
import {
  GET_ALL_APARTMENTS,
  GET_FILTERED_APARTMENTS,
  GET_FEATURES,
  GET_FEATURES_IN_RANGE,
  GET_FEATURES_IN_RANGE_OPTIONS,
  GET_APARTMENTS_DEFAULT_OPTIONS,
  GET_FILTERED_APARTMENTS_DEFAULT_OPTIONS,
  GET_FEATURES_DEFAULT_OPTIONS,
  GET_AVAILABLE_SPACE_COUNT,
  GET_AVAILABLE_SPACE_COUNT_DEFAULT_OPTIONS,
} from "./modules/Appartment";
import {
  GET_QUOTE,
  GET_QUOTE_BY_CLIENT,
  CREATE_QUOTE_MUTATION,
  UPDATE_QUOTE_MUTATION,
  SEND_QUOTE_MUTATION,
  GET_QUOTE_OPTIONS,
  GET_QUOTE_BY_CLIENT_OPTIONS,
  CREATE_QUOTE_OPTIONS,
  UPDATE_QUOTE_OPTIONS,
} from "./modules/Quote";
import {
  GET_ALL_POLICIES,
  GET_ALL_POLICIES_DEFAULT_OPTIONS,
} from "./modules/Policy";
import {
  GET_ALL_USERS,
  GET_USER_BY_CLIENT_ID,
  GET_CLIENT,
  ACTIVATE_MYHOME_MUTATION,
  GET_USER_BY_CLIENT_ID_OPTIONS,
  ACTIVATE_MYHOME_OPTIONS,
  GET_CLIENT_DEFAULT_OPTIONS,
  GET_USER_INFO,
  GET_USER_INFO_DEFAULT_OPTIONS,
} from "./modules/User";
import { CONTACT_MUTATION, CONTACT_OPTIONS } from "./modules/Contact";
import {
  GET_APPLICATION_CONFIGURATION_DATA_TO_RETRIEVE,
  GET_APPLICATION_CONFIGURATION_OPTIONS,
  GET_INITIAL_INFO_DATA_TO_RETRIEVE,
  GET_INITIAL_INFO_OPTIONS,
  CREATE_REQUEST_MUTATION,
  CREATE_REQUEST_OPTIONS,
  VALIDATE_REQUEST_OPTION,
  GET_VALIDATE_REQUEST_DATA_TO_RETRIEVE,
} from "./modules/SpaceConfigurator";
import {
  DO_LOGIN,
  DO_LOGIN_OPTIONS,
  DO_CONFIRM_USER,
  DO_CONFIRM_USER_OPTIONS,
  DO_REGISTRATION_OPTIONS,
  DO_REGISTRATION,
  CHECK_CLIENT_INFO,
  CHECK_CLIENT_INFO_OPTIONS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_DEFAULT_OPTIONS,
  DO_RECOVERY_PASSWORD,
  DO_RECOVERY_PASSWORD_OPTIONS,
  DO_CHANGE_PASSWORD,
  DO_CHANGE_PASSWORD_OPTIONS,
  GET_USER_CONFIRMATION_BY_TOKEN,
  GET_USER_CONFIRMATION_BY_TOKEN_OPTIONS,
} from "./modules/Auth";
import * as StepModule from "./modules/Step";

const Client = {
  ...StepModule,
  DO_LOGIN,
  DO_REGISTRATION,
  DO_CONFIRM_USER,
  DO_RECOVERY_PASSWORD,
  DO_CHANGE_PASSWORD,
  REFRESH_TOKEN,
  CHECK_CLIENT_INFO,
  GET_ALL_APARTMENTS,
  GET_FILTERED_APARTMENTS,
  GET_FEATURES,
  GET_PROJECT_INFO,
  GET_CLIENT,
  GET_QUOTE,
  GET_QUOTE_BY_CLIENT,
  SEND_QUOTE_MUTATION,
  CREATE_QUOTE_MUTATION,
  UPDATE_QUOTE_MUTATION,
  UPDATE_MODEL_MUTATION,
  UPDATE_TYPOLOGY_MUTATION,
  UPDATE_PLAN_MUTATION,
  CONTACT_MUTATION,
  ACTIVATE_MYHOME_MUTATION,
  GET_ALL_USERS,
  GET_USER_BY_CLIENT_ID,
  GET_ALL_POLICIES,
  GET_FEATURES_IN_RANGE,
  GET_AVAILABLE_SPACE_COUNT,
  CREATE_REQUEST_MUTATION,
  CREATE_REQUEST_OPTIONS,
  VALIDATE_REQUEST_OPTION,
  GET_AVAILABLE_SPACE_COUNT_DEFAULT_OPTIONS,
  GET_FEATURES_IN_RANGE_OPTIONS,
  REFRESH_TOKEN_DEFAULT_OPTIONS,
  DO_CONFIRM_USER_OPTIONS,
  DO_LOGIN_OPTIONS,
  DO_RECOVERY_PASSWORD_OPTIONS,
  DO_CHANGE_PASSWORD_OPTIONS,
  GET_USER_CONFIRMATION_BY_TOKEN,
  GET_USER_CONFIRMATION_BY_TOKEN_OPTIONS,
  DO_REGISTRATION_OPTIONS,
  CHECK_CLIENT_INFO_OPTIONS,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
  GET_APARTMENTS_DEFAULT_OPTIONS,
  GET_FILTERED_APARTMENTS_DEFAULT_OPTIONS,
  GET_FEATURES_DEFAULT_OPTIONS,
  GET_CLIENT_DEFAULT_OPTIONS,
  GET_USER_INFO,
  GET_USER_INFO_DEFAULT_OPTIONS,
  GET_QUOTE_OPTIONS,
  GET_QUOTE_BY_CLIENT_OPTIONS,
  CREATE_QUOTE_OPTIONS,
  UPDATE_QUOTE_OPTIONS,
  UPDATE_MODEL_OPTIONS,
  UPDATE_PLAN_OPTIONS,
  UPDATE_TYPOLOGY_OPTIONS,
  CONTACT_OPTIONS,
  ACTIVATE_MYHOME_OPTIONS,
  GET_USER_BY_CLIENT_ID_OPTIONS,
  GET_ALL_POLICIES_DEFAULT_OPTIONS,
  GET_APPLICATION_CONFIGURATION_DATA_TO_RETRIEVE,
  GET_VALIDATE_REQUEST_DATA_TO_RETRIEVE,
  GET_APPLICATION_CONFIGURATION_OPTIONS,
  GET_INITIAL_INFO_DATA_TO_RETRIEVE,
  GET_INITIAL_INFO_OPTIONS,
};

export default Client;
