import i18n from "@tecma/i18n";

export const getMoodNameTranslation = (mood: string) => {
  return mood.replaceAll(" ", "-").toLowerCase();
};

export const firstLetterCapitalize = (string: string) => {
  return string[0].toUpperCase() + string.slice(1);
};

/**
 * Removes dash characters and replaces them with spaces for a more readable name
 * @param name
 * @returns
 */
export const getCleanName = (name: string) => {
  if (!name) return "";
  return name.replace(/-/g, " ");
};

export const currencyFormatter = (
  value: number,
  currency = "EUR",
  options: Intl.NumberFormatOptions = {},
) => {
  return new Intl.NumberFormat(i18n.language || "en-GB", {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    ...options,
  }).format(value);
};
