import React from "react";
import classNames from "classnames";
import { Avatar, Button, Icon, Spinner } from "@tecma/ui";

import { DefaultProps } from "utils/types/defaultProps";
import { useTranslation } from "@tecma/i18n";
import { useDevice } from "hooks/device/device";

import "./step-wizard-header.scss";

interface StepWizardHeaderRequiredProps {
  title: React.ReactElement;
  stepNumber: number;
  icon?: string;
  isOpen?: boolean;
  isLoading?: boolean;
  isActive: boolean;
  isCompleted: boolean;
  isFarthestStepIndex: boolean;
  isCurrentStepIndex: boolean;
  isUserSelectionModified: boolean;
  disabled: boolean;
  onClick: () => void;
}
// Optional Props
type StepWizardSectionOptionalProps = DefaultProps;

export interface StepWizardHeaderProps
  extends StepWizardHeaderRequiredProps,
    StepWizardSectionOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: StepWizardSectionOptionalProps = {
  "data-testid": "space-configurator-stepWizardHeader",
};

const StepWizardHeader: React.FC<StepWizardHeaderProps> = ({
  icon,
  title,
  stepNumber,
  isActive,
  isOpen,
  isLoading,
  isCompleted,
  isFarthestStepIndex,
  isCurrentStepIndex,
  isUserSelectionModified,
  disabled,
  onClick,
  ...rest
}) => {
  const { t } = useTranslation();
  const { type } = useDevice();

  const classList = classNames("step-wizard-header", {
    "is-active": isActive,
    "is-open": isOpen,
    "is-completed": isCompleted,
    "is-modified": isUserSelectionModified,
  });

  const getButtonIcon = () => {
    if (type !== "mobile" && isLoading) {
      return "";
    }
    return isCompleted && !isCurrentStepIndex ? "check" : "arrow-down";
  };

  const getButtonText = () => {
    if (isCompleted && !isCurrentStepIndex) {
      return t("steps.completed");
    }
    if (isCurrentStepIndex) {
      return t("steps.confirm");
    }
    if (isFarthestStepIndex && !isCurrentStepIndex) {
      return t("steps.continue");
    }
    return t("steps.confirm");
  };

  const getButton = () => {
    if (type === "mobile") {
      return (
        <Icon
          iconName={getButtonIcon()}
          className='arrow-mobile'
          size='small'
        />
      );
    }
    return (
      <Button
        onClick={!isLoading ? onClick : () => {}}
        size='small'
        rightIcon
        iconName={getButtonIcon()}
        className='step-wizard-header-button'
        disabled={disabled && !isLoading}
      >
        {isLoading && <Spinner type='dotted-circle' size='small' />}
        {getButtonText()}
      </Button>
    );
  };

  const stepToRender = (
    <div className={classList} {...rest}>
      <div className='step-wizard-header-wrapper'>
        <Avatar
          className='step-wizard-header-wrapper-icon'
          icon={icon}
          size='large'
        />
        <p className='step-wizard-header-wrapper-title'>
          {stepNumber}. {title}
        </p>
      </div>
      {(isCompleted || isActive) && getButton()}
    </div>
  );

  return type === "mobile" ? (
    <Button
      onClick={!isLoading ? onClick : () => {}}
      className='step-wizard-header-mobile'
      disabled={!isActive}
    >
      {stepToRender}
    </Button>
  ) : (
    stepToRender
  );
};

StepWizardHeader.defaultProps =
  defaultProps as Partial<StepWizardSectionOptionalProps>;

export default StepWizardHeader;
