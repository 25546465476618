import { useTranslation } from "@tecma/i18n";
import React from "react";
import { SpaceTypeStepModule } from "steps/SpaceTypeStep";
import { getCleanName } from "utils/functions/formatters";
import type { DefaultProps } from "../../../../utils/types/defaultProps";
import type { RecapStepsComponentProps } from "../../../types/step";

import "./space-type-recap.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SpaceTypeRecapRequiredProps {}

// Optional Props
type SpaceTypeRecapOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface SpaceTypeRecapProps
  extends SpaceTypeRecapRequiredProps,
    SpaceTypeRecapOptionalProps,
    RecapStepsComponentProps<SpaceTypeStepModule> {}

const SpaceTypeRecap: React.FC<SpaceTypeRecapProps> = ({
  userSelectionDetails,
  buildingName,
}) => {
  const { t } = useTranslation();
  return (
    <p className='space-type-recap'>
      {userSelectionDetails &&
        t([
          `${buildingName}.space-picker.spaces.${userSelectionDetails.spaceTypeName}`,
          `space-picker.spaces.${userSelectionDetails.spaceTypeName}`,
          `space-picker.spaces.${userSelectionDetails.spaceTypeName}`,
          getCleanName(userSelectionDetails.spaceTypeName),
        ])}
    </p>
  );
};

export default SpaceTypeRecap;
