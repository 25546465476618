import { createAction } from "@reduxjs/toolkit";
import { StartEndDates } from "store/slices/dateSelectorState/slice";

const DATE_SELECTOR_TYPE = "dateSelectorType";

export const dateSelectorActionTypes = {
  datesInitRequested: `${DATE_SELECTOR_TYPE}/datesInit`,
  datesInitCompleted: `${DATE_SELECTOR_TYPE}/datesInitCompleted`,
  datesUpdateRequested: `${DATE_SELECTOR_TYPE}/datesUpdate`,
  datesUpdateCompleted: `${DATE_SELECTOR_TYPE}/datesUpdateCompleted`,
  firstAvailabilityDatesUpdateRequested: `${DATE_SELECTOR_TYPE}/firstAvailabilityDatesUpdate`,
};

export const dateSelectorActions = {
  datesInitRequested: createAction<StartEndDates>(
    dateSelectorActionTypes.datesInitRequested,
  ),
  datesInitCompleted: createAction(dateSelectorActionTypes.datesInitCompleted),
  datesUpdateRequested: createAction<StartEndDates>(
    dateSelectorActionTypes.datesUpdateRequested,
  ),
  datesUpdateCompleted: createAction(
    dateSelectorActionTypes.datesUpdateCompleted,
  ),
  firstAvailabilityDatesUpdateRequested: createAction<StartEndDates>(
    dateSelectorActionTypes.firstAvailabilityDatesUpdateRequested,
  ),
};
