import React, { useState } from "react";
import { DefaultProps } from "utils/types/defaultProps";
import { Button, DropDown, Icon } from "@tecma/ui";
import { useDevice } from "hooks/device/device";
import { IUser } from "utils/types/auth";
import { useDispatch } from "react-redux";
import { authActions } from "store/sagas/auth/auth.actions";
import { useTranslation } from "@tecma/i18n";
import classNames from "classnames";

// styles
import "./headerLoggedUser.scss";

// Required Props
interface HeaderLoggedUserRequiredProps {
  headerRef: React.MutableRefObject<HTMLDivElement>;
  handleGoToReservedArea: () => void;
}

// Optional Props
interface HeaderLoggedUserOptionalProps extends DefaultProps {
  user?: IUser | null;
  impersonatedUser?: IUser | null;
}

// Combined required and optional props to build the full prop interface
export interface HeaderLoggedUserProps
  extends HeaderLoggedUserRequiredProps,
    HeaderLoggedUserOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: HeaderLoggedUserOptionalProps = {
  "data-testid": "space-configurator-headerLoggedUser",
};

const HeaderLoggedUser: React.FC<HeaderLoggedUserProps> = ({
  className,
  headerRef,
  user,
  handleGoToReservedArea,
  impersonatedUser,
  ...rest
}) => {
  const { t } = useTranslation();
  const device = useDevice();
  const dispatch = useDispatch();
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

  const handleLogout = () => {
    setIsUserDropdownOpen(false);
    dispatch(authActions.doLogout());
  };

  const classList = classNames("logged-user-btn", {
    impersonated: impersonatedUser,
  });

  return device.type !== "mobile" ? (
    <DropDown
      {...rest}
      className='logged-user-dropdown'
      parentElement={headerRef.current}
      isOpen={isUserDropdownOpen}
      onToggle={() => setIsUserDropdownOpen(!isUserDropdownOpen)}
      position='bottom-left'
      trigger={
        <Button
          onClick={() => {}}
          transparent
          iconName='user'
          iconSize='small'
          className={classList}
        >
          <p>
            {impersonatedUser ? (
              <>
                <span>{t("header.customer")}: </span>
                <br />
                {impersonatedUser?.firstName} {impersonatedUser?.lastName}
              </>
            ) : (
              <>
                <span>{t("header.hello")} </span>
                <br />
                {user?.firstName}
              </>
            )}
          </p>
          <Icon
            iconName='arrow-down'
            size='small'
            className={isUserDropdownOpen ? `open` : ""}
          />
        </Button>
      }
    >
      <DropDown.Element onClick={handleGoToReservedArea}>
        <Icon iconName='home' size='small' />
        {t("header.my-home")}
      </DropDown.Element>
      <DropDown.Divider />
      <DropDown.Element onClick={handleLogout}>
        <Icon iconName='sign-out' size='small' />
        {t("header.logout")}
      </DropDown.Element>
    </DropDown>
  ) : (
    <div className='logged-user-mobile'>
      <p className='logged-user-greetings'>
        <span>{t("header.hello")},</span>
        <br />
        {impersonatedUser ? impersonatedUser.firstName : user?.firstName}
      </p>
      <Button iconName='home' onClick={handleGoToReservedArea}>
        {t("header.my-home")}
      </Button>
    </div>
  );
};

HeaderLoggedUser.defaultProps =
  defaultProps as Partial<HeaderLoggedUserOptionalProps>;

export default React.memo(HeaderLoggedUser);
