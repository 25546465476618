import React from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { DefaultProps } from "utils/types/defaultProps";

// styles
import "./scaffold.scss";
import { useSelector } from "react-redux";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";

// Required Props
interface ScaffoldRequiredProps {
  children: React.ReactNode;
}

// Optional Props
type ScaffoldOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
interface ScaffoldProps extends ScaffoldRequiredProps, ScaffoldOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: ScaffoldOptionalProps = {
  "data-testid": "space-configurator-scaffold",
};

/**
 * This component is the scaffold of the application. It is used ad Routes wrapper in App component
 * The Scaffold contains the Header component that usually is present in each project page.
 * It could be use to show different pages if the user is authenticated or not.
 * Example:
 * <div> {hideStructure ? children : <header></header><div>{children}</div>}</div>
 */
const Scaffold: React.FC<ScaffoldProps> = ({
  className,
  children,
  ...rest
}) => {
  const classList = classNames("space-configurator-scaffold", className);
  const projectInfo = useSelector(
    applicationStateSelectors.selectProjectInfo,
  ) as ProjectInfo;

  return (
    <div className={classList} {...rest}>
      <Helmet>
        <link
          rel='icon'
          href={projectInfo?.logo.favicon}
          sizes='16x16'
          type='image/png'
        />
        <title>{projectInfo?.pageTitle}</title>
      </Helmet>
      {children}
    </div>
  );
};

Scaffold.defaultProps = defaultProps as Partial<ScaffoldOptionalProps>;

export default React.memo(Scaffold);
