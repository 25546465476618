import { useLocation } from "react-router-dom";
import queryString from "query-string";

import { LazyImage, Spinner } from "@tecma/ui";
import { Header } from "components/Header";
import { useSelector } from "react-redux";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { selectCurrentStepImages } from "steps/selector";
import AuthModal from "components/AuthModal";

import "./manage-password-page.scss";
import { logger } from "@tecma/logs-fe";

type ManagePasswordPageMode = "confirm" | "recovery" | "refreshToken";

interface ManagePasswordPageProps {
  mode: ManagePasswordPageMode;
}

const ManagePasswordPage: React.FC<ManagePasswordPageProps> = ({ mode }) => {
  const { search } = useLocation();

  const { token } = queryString.parse(search);

  if (!token) {
    logger.error("Missing token");
    throw new Error("Missing token");
  }

  const projectInfo = useSelector(
    applicationStateSelectors.selectProjectInfo,
  ) as ProjectInfo;

  const { carouselImages } = useSelector(selectCurrentStepImages);

  return (
    <div className='password-page'>
      <LazyImage.Background
        className='password-page-illustration'
        src={carouselImages?.[0] ?? ""}
        loadingElement={<Spinner />}
      >
        <>
          <Header projectInfo={projectInfo} />
          <AuthModal mode={mode} token={String(token)} />
        </>
      </LazyImage.Background>
    </div>
  );
};

export default ManagePasswordPage;
