import { gql } from "@apollo/client";

const LOGIN_DATA = `
token {
    accessToken
    refreshToken
    expiresIn
  }
  user{
    id
    firstName
    lastName
    email
    client
    language
    tel
  }
`;

const CLIENT_INFO = `
  firstName,
  status
`;

const TOKEN_DATA = `
  accessToken
  refreshToken
  expiresIn
`;

const USER_CONFIRMATION_DATA = `
  email
  confirmed
  confirmationTokenExpired
`;

export interface RefreshTokenInput {
  email: string;
  refreshToken: string;
}

export interface LoginByProjectInput {
  email: string;
  password: string;
  project_id: string;
}

export interface ClientUserInput {
  firstName: string;
  lastName: string;
  tel: string;
  email: string;
  trattamento: boolean;
  profilazione: boolean;
  marketing: boolean;
}

export interface CheckClientInfoInput {
  email: string;
  project_id: string;
}

export interface GetUserConfirmationByTokenInput {
  projectId: string;
  token: string;
}

export interface AskChangePasswordInput {
  project_id: string;
  email: string;
  template_type: string;
  platform: string;
}

export interface ChangePasswordInput {
  project_id: string;
  token: string;
  password: string;
}
export interface ConfirmUserAndChangePasswordInput {
  project_id: string;
  token: string;
  password: string;
  newPassword: string;
}

export interface CreateClientAndUserInput {
  project_id: string;
  input: ClientUserInput;
  platform: string;
  isConfigurationComplete?: boolean;
}
export interface GetUserConfirmationByTokenOptions {
  variables: GetUserConfirmationByTokenInput;
  fetchPolicy: "no-cache";
}

export interface TokenOptions {
  variables: {
    refreshTokenInput: RefreshTokenInput;
  };
  fetchPolicy: "no-cache";
}

export interface CheckClientOptions {
  variables: CheckClientInfoInput;
  fetchPolicy: "no-cache";
}
export interface AuthOptions {
  variables: {
    loginByProjectInput: LoginByProjectInput;
  };
  fetchPolicy: "no-cache";
}
export interface RegistrationOptions {
  variables: CreateClientAndUserInput;
  fetchPolicy: "no-cache";
}

export interface RecoveryPasswordOptions {
  variables: AskChangePasswordInput;
  fetchPolicy: "no-cache";
}

export interface ChangePasswordOptions {
  variables: ChangePasswordInput;
  fetchPolicy: "no-cache";
}

export interface ConfirmUserOptions {
  variables: ConfirmUserAndChangePasswordInput;
  fetchPolicy: "no-cache";
}

export const GET_USER_CONFIRMATION_BY_TOKEN = gql`
query getUserConfirmationByToken(
  $projectId: ID!
  $token: String!
  ){
  getUserConfirmationByToken(
    projectId: $projectId
    token: $token){
    ${USER_CONFIRMATION_DATA}
  }
}`;

export const DO_LOGIN = gql`
mutation loginByProject($loginByProjectInput: LoginByProjectInput!){
  loginByProject(input: $loginByProjectInput){
    ${LOGIN_DATA}
  }
}`;

export const DO_REGISTRATION = gql`
  mutation createClientAndUser(
    $project_id: ID!
    $input: ClientUserInput!
    $platform: Platforms
    $isConfigurationComplete: Boolean
  ) {
    createClientAndUser(
      project_id: $project_id
      input: $input
      platform: $platform
      isConfigurationComplete: $isConfigurationComplete
    )
  }
`;

export const DO_CONFIRM_USER = gql`
  mutation confirmUserAndChangePassword(
    $project_id: ID!
    $token: String!
    $password: String!
    $newPassword: String!
  ) {
    confirmUserAndChangePassword(
      project_id: $project_id
      token: $token
      password: $password
      newPassword: $newPassword
    )
  }
`;

export const DO_RECOVERY_PASSWORD = gql`
  mutation askChangePassword(
    $project_id: ID!
    $email: String!
    $template_type: String!
    $platform: Platforms
  ) {
    askChangePassword(
      project_id: $project_id
      email: $email
      template_type: $template_type
      platform: $platform
    )
  }
`;

export const DO_CHANGE_PASSWORD = gql`
  mutation changePassword(
    $project_id: ID!
    $token: String!
    $password: String!
  ) {
    changePassword(project_id: $project_id, token: $token, password: $password)
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshTokenInput: RefreshTokenInput!) {
    refreshToken(input: $refreshTokenInput) {
      ${TOKEN_DATA}
    }
  }
`;

export const CHECK_CLIENT_INFO = gql`
  mutation checkClientInfo($project_id: ID!, $email: String!) {
    checkClientInfo(project_id: $project_id, email: $email){
      ${CLIENT_INFO}
    }
  }
`;

export const CHECK_CLIENT_INFO_OPTIONS = (
  checkClientInfoInput: CheckClientInfoInput,
): CheckClientOptions => {
  const options: CheckClientOptions = {
    variables: checkClientInfoInput,
    fetchPolicy: "no-cache",
  };
  return options;
};

export const GET_USER_CONFIRMATION_BY_TOKEN_OPTIONS = (
  getUserConfirmationByTokenInput: GetUserConfirmationByTokenInput,
): GetUserConfirmationByTokenOptions => {
  const options: GetUserConfirmationByTokenOptions = {
    variables: getUserConfirmationByTokenInput,
    fetchPolicy: "no-cache",
  };
  return options;
};

export const REFRESH_TOKEN_DEFAULT_OPTIONS = (
  refreshTokenInput: RefreshTokenInput,
): TokenOptions => {
  const options: TokenOptions = {
    variables: {
      refreshTokenInput,
    },
    fetchPolicy: "no-cache",
  };
  return options;
};

export const DO_REGISTRATION_OPTIONS = (
  createClientAndUserInput: CreateClientAndUserInput,
): RegistrationOptions => {
  const options: RegistrationOptions = {
    variables: createClientAndUserInput,
    fetchPolicy: "no-cache",
  };
  return options;
};

export const DO_RECOVERY_PASSWORD_OPTIONS = (
  askChangePasswordInput: AskChangePasswordInput,
): RecoveryPasswordOptions => {
  const options: RecoveryPasswordOptions = {
    variables: askChangePasswordInput,
    fetchPolicy: "no-cache",
  };
  return options;
};

export const DO_CHANGE_PASSWORD_OPTIONS = (
  changePasswordInput: ChangePasswordInput,
): ChangePasswordOptions => {
  const options: ChangePasswordOptions = {
    variables: changePasswordInput,
    fetchPolicy: "no-cache",
  };
  return options;
};

export const DO_CONFIRM_USER_OPTIONS = (
  confirmUserAndChangePasswordInput: ConfirmUserAndChangePasswordInput,
): ConfirmUserOptions => {
  const options: ConfirmUserOptions = {
    variables: confirmUserAndChangePasswordInput,
    fetchPolicy: "no-cache",
  };
  return options;
};

export const DO_LOGIN_OPTIONS = (
  loginByProjectInput: LoginByProjectInput,
): AuthOptions => {
  const options: AuthOptions = {
    variables: {
      loginByProjectInput,
    },
    fetchPolicy: "no-cache",
  };
  return options;
};
