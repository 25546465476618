import React from "react";
import classNames from "classnames";
import { DefaultProps } from "utils/types/defaultProps";

// styles
import "./planDetails.scss";
import { PlanDetailsLabel } from "./component/PlanDetailsLabel";

type MainFeatures = {
  rooms: number;
  bathroom: number;
  bedroom: number;
  openPlanKitchen: boolean;
};

// Required Props
interface PlanDetailsRequiredProps {
  mainFeatures: MainFeatures;
  spaceName: string;
}

// Optional Props
interface PlanDetailsOptionalProps extends DefaultProps {
  complex?: string;
  horizontal?: boolean;
}

// Combined required and optional props to build the full prop interface
export interface PlanDetailsProps
  extends PlanDetailsRequiredProps,
    PlanDetailsOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: PlanDetailsOptionalProps = {
  "data-testid": "spaceConfigurator-planDetails",
};

const PlanDetails: React.FC<PlanDetailsProps> = ({
  className,
  mainFeatures,
  spaceName,
  complex,
  horizontal,
  ...rest
}) => {
  const classList = classNames("space-configurator-plan-details", className, {
    horizontal,
  });
  const { bathroom, bedroom, openPlanKitchen } = mainFeatures;

  return (
    <div className={classList} {...rest}>
      {bedroom > 0 && (
        <PlanDetailsLabel
          room='bedroom'
          count={bedroom}
          spaceName={spaceName}
          complex={complex}
        />
      )}
      {bathroom > 0 && (
        <PlanDetailsLabel
          room='bathroom'
          count={bathroom}
          spaceName={spaceName}
          complex={complex}
        />
      )}
      {openPlanKitchen ? (
        <PlanDetailsLabel
          room='openplan'
          count={1}
          spaceName={spaceName}
          complex={complex}
        />
      ) : (
        <>
          <PlanDetailsLabel
            room='living-room'
            count={1}
            spaceName={spaceName}
            complex={complex}
          />
          <PlanDetailsLabel
            room='kitchen'
            count={1}
            spaceName={spaceName}
            complex={complex}
          />
        </>
      )}
      <PlanDetailsLabel
        room='extra-info'
        count={1}
        spaceName={spaceName}
        complex={complex}
      />
    </div>
  );
};

PlanDetails.defaultProps = defaultProps as Partial<PlanDetailsOptionalProps>;

export default React.memo(PlanDetails);
