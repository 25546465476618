import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { getIsUserSelectionCompleteSelector } from "../selectors";
import { StepsEntity } from "../../store/domain/entities/steps.entity";
import { STEP_COMPONENTS_REGISTRY } from "..";
import {
  AnyStepModule,
  RecapStepsComponentProps,
  StepPageContentRendererProps,
  RootStateSelector,
  StepConfig,
  StepsComponentProps,
  UserSelection,
  UserSelectionDetails,
} from "../types/step";

export const useStepRegistry = (step: StepsEntity) => {
  const dispatch = useDispatch();
  const stepType = step.getType();
  const stepModule = STEP_COMPONENTS_REGISTRY[stepType];

  if (!stepModule) {
    throw new Error(`Step module not found for type: ${stepType}`);
  }

  const StepRenderer = stepModule.stepRenderer as React.FC<
    StepsComponentProps<AnyStepModule>
  >;

  const RecapStepRenderer = stepModule.recapStepRender as React.FC<
    RecapStepsComponentProps<AnyStepModule>
  >;

  const RecapAdditionalStepRenderer = stepModule.recapStepAdditionalRender as
    | React.FC<RecapStepsComponentProps<AnyStepModule>>
    | undefined;

  const StepPageContentRenderer = stepModule.stepPageContentRenderer as
    | React.FC<StepPageContentRendererProps<AnyStepModule>>
    | undefined;

  const MobileModalRenderer = stepModule.mobileModalRenderer as
    | React.FC<StepsComponentProps<AnyStepModule>>
    | undefined;

  const stepConfig = useSelector(
    stepModule.configSelector as RootStateSelector<StepConfig | undefined>,
    shallowEqual,
  );
  const userSelection = useSelector(
    stepModule.userSelectionSelector as RootStateSelector<
      UserSelection | undefined
    >,
    shallowEqual,
  );
  const unconfirmedUserSelection = useSelector(
    stepModule.unconfirmedUserSelectionSelector as RootStateSelector<
      UserSelection | undefined
    >,
    shallowEqual,
  );
  const userSelectionDetail = useSelector(
    stepModule.userSelectionDetailSelector as RootStateSelector<
      UserSelectionDetails | undefined
    >,
    shallowEqual,
  );

  const stepPageContentCarouselImages = useSelector(
    stepModule.stepPageContentCarouselImagesSelector || (() => undefined),
    shallowEqual,
  );

  const mobileStepPageContentCarouselImages = useSelector(
    stepModule.mobileStepPageContentCarouselImagesSelector || (() => undefined),
  );

  const isUserSelectionModified = useSelector(
    stepModule.isUserSelectionModified ||
      (() => userSelection !== unconfirmedUserSelection),
  );

  const isUserSelectionComplete = useSelector(
    getIsUserSelectionCompleteSelector(stepModule, userSelection),
  );

  const handleUserSelectionChange = (newSelection: UserSelection) => {
    dispatch(
      (
        stepModule.updateUserSelectionAction as ActionCreatorWithPayload<UserSelection>
      )(newSelection),
    );
  };
  const handleUnconfirmedUserSelectionChange = (
    newSelection: UserSelection,
  ) => {
    dispatch(
      (
        stepModule.updateUnconfirmedUserSelectionAction as ActionCreatorWithPayload<UserSelection>
      )(newSelection),
    );
  };

  const isStepLoading = useSelector(stepModule.isStepLoading || (() => false));

  return {
    stepModule,
    StepRenderer,
    RecapStepRenderer,
    RecapAdditionalStepRenderer,
    StepPageContentRenderer,
    stepPageContentCarouselImages,
    mobileStepPageContentCarouselImages,
    stepConfig,
    userSelection,
    unconfirmedUserSelection,
    userSelectionDetail,
    handleUserSelectionChange,
    handleUnconfirmedUserSelectionChange,
    isUserSelectionComplete,
    isUserSelectionModified,
    MobileModalRenderer,
    mobileModalKey: stepModule.mobileModalKey,
    isStepLoading,
  };
};
