import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import type { RootState } from "../..";
import { generateID } from "../../utils/generateID";
import type { StepsStoreEntity } from "../entities/steps.entity";

export const getStepsStoreState = (state: RootState) => state.steps;

export const stepsAdapter = createEntityAdapter<StepsStoreEntity>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (step) => step.id,
});

const slice = createSlice({
  name: "steps",
  initialState: stepsAdapter.getInitialState(),
  reducers: {
    stepsLoaded: (state, action) => {
      const { steps } = action.payload;
      stepsAdapter.setAll(state, steps);
    },
    stepsAdded: (state, action) => {
      const steps = cloneDeep(action.payload);
      steps.forEach((step: Step & { id: string }) => {
        if (!step.id) {
          // eslint-disable-next-line no-param-reassign
          step.id = generateID();
        }
        stepsAdapter.setOne(state, step);
      });
    },
    stepsDeleted: (state, action) => {
      const { id } = action.payload;
      stepsAdapter.removeOne(state, id);
    },
    stepsUpdated: (state, action) =>
      stepsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      }),
  },
});

export const {
  selectById: selectStepsById,
  selectIds: selectStepsIds,
  selectEntities: selectStepsEntities,
  selectAll: selectAllSteps,
  selectTotal: selectTotalSteps,
} = stepsAdapter.getSelectors((state: RootState) => getStepsStoreState(state));

export const { stepsLoaded, stepsAdded, stepsDeleted, stepsUpdated } =
  slice.actions;

export const { reducer } = slice;
