import psl, { ParsedDomain, ParseError } from "psl";

/**
 * Function to get the Domain.
 * Ex. "www.google.it" => "google.it"
 * If localhost => "localhost"
 */
const getDomain = () => {
  let domain = "localhost";
  const isTest = (arg: any): arg is ParsedDomain => {
    return arg && arg.domain && typeof arg.domain === "string";
  };

  if (window.location.hostname !== "localhost") {
    const pslUrl: ParsedDomain | ParseError = psl.parse(
      window.location.hostname,
    );
    if (isTest(pslUrl)) domain = pslUrl.domain || domain;
  }

  return domain;
};

export default getDomain;
