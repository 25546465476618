/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";

export interface StartEndDates {
  startDate: string;
  endDate: string;
}

export interface AvailableSpaces {
  count: number;
  firstEntryAvailable?: {
    startDate?: string;
    endDate?: string;
  };
  loading?: boolean;
  error?: boolean;
}

export interface DateSelectorState {
  startDate: string;
  endDate: string;
  availableSpaces: AvailableSpaces;
}

const initialState: DateSelectorState = {
  startDate: "",
  endDate: "",
  availableSpaces: {
    count: 0,
    firstEntryAvailable: {
      startDate: "",
      endDate: "",
    },
  },
};

const slice = createSlice({
  name: "dateSelectorState",
  initialState,
  reducers: {
    updateStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    updateEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload;
    },
    updateAvailabilityCount: (
      state,
      action: PayloadAction<AvailableSpaces>,
    ) => {
      state.availableSpaces = action.payload;
    },
  },
});

export const dateSelectorStateActions = slice.actions;

export const getDateSelectorState = createSelector(
  (state: RootState) => state.dateSelectorState,
  (state) => state,
);

export const getCurrentStartEndDateSelector = createSelector(
  (state: RootState) => state.dateSelectorState,
  (state) => ({ startDate: state.startDate, endDate: state.endDate }),
);

export const getCheckInAndCheckOutDateSelector = (state: RootState) => ({
  checkInDate: state.dateSelectorState.startDate,
  checkOutDate: state.dateSelectorState.endDate,
});

export const { reducer } = slice;
