/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StartEndDates } from "store/slices/dateSelectorState/slice";

const initialState: ComplexStepSlice = {
  config: null,
  selectedComplex: null,
  unconfirmedComplex: null,
  isLoading: false,
  startEndDatesFetched: null,
};

const slice = createSlice({
  name: "complexStep",
  initialState,
  reducers: {
    resetConfig: (state) => {
      state.config = initialState.config;
    },
    putConfig: (state, action: PayloadAction<ComplexStepConfig>) => {
      state.config = action.payload;
    },
    setStartEndDateFetched: (state, action: PayloadAction<StartEndDates>) => {
      state.startEndDatesFetched = action.payload;
    },
    setSelectedComplex: (state, action: PayloadAction<ComplexName>) => {
      state.selectedComplex = action.payload;
    },
    setUnconfirmedComplex: (state, action: PayloadAction<ComplexName>) => {
      state.unconfirmedComplex = action.payload;
    },
    resetSelectedComplex: (state) => {
      state.config = initialState.config;
      state.selectedComplex = initialState.selectedComplex;
      state.unconfirmedComplex = initialState.unconfirmedComplex;
    },
    setIsStepLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

const stepConfigActions = slice.actions;

const { reducer } = slice;

export const complexStepSlice = {
  stepConfigActions,
  reducer,
};
