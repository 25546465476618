/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ExtraSpaceStepSlice = {
  config: null,
  selectedExtraSpace: null,
  unconfirmedExtraSpace: null,
  isLoading: false,
};

const slice = createSlice({
  name: "extraSpaceStepSlice",
  initialState,
  reducers: {
    putConfig: (state, action: PayloadAction<ExtraSpaceConfig>) => {
      state.config = action.payload;
    },
    resetExtraSpace: (state) => {
      state.selectedExtraSpace = initialState.selectedExtraSpace;
      state.unconfirmedExtraSpace = initialState.unconfirmedExtraSpace;
    },
    setSelectedExtraSpaces: (
      state,
      action: PayloadAction<ExtraSpacesUserSelection>,
    ) => {
      const extraSpaceID = Object.keys(action.payload)[0];

      if (action.payload[extraSpaceID] === 0) {
        delete state?.selectedExtraSpace?.[extraSpaceID];
      } else {
        state.selectedExtraSpace = {
          ...state.selectedExtraSpace,
          ...action.payload,
        };
      }
    },
    setUnconfirmedExtraSpaces: (
      state,
      action: PayloadAction<ExtraSpacesUserSelection>,
    ) => {
      state.unconfirmedExtraSpace = action.payload;
    },
    setIsStepLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

const stepConfigActions = slice.actions;

const { reducer } = slice;

export const extraSpaceStepSlice = {
  stepConfigActions,
  reducer,
};
