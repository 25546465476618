import React from "react";
import { Trans, useTranslation } from "@tecma/i18n";
import { Button } from "@tecma/ui";
import { useDispatch } from "react-redux";

import { useDevice } from "hooks/device/device";
import { DefaultProps } from "utils/types/defaultProps";
import InfoMessage from "components/StepsWizard/components/InfoMessage/InfoMessage";
import { applicationStateActions } from "store/slices/applicationState/slice";
import { SinglePack } from "./SinglePack";
import type { PackStepModule } from "..";
import { StepsComponentProps } from "../../types/step";

import "./pack-step.scss";
// Optional Props
type PackStepOptionalProps = DefaultProps;

export interface PackStepProps
  extends PackStepOptionalProps,
    StepsComponentProps<PackStepModule> {}

const PackStep: React.FC<PackStepProps> = ({
  userSelection,
  stepConfig: packs,
  complex,
}) => {
  const { t } = useTranslation();
  const device = useDevice();
  const dispatch = useDispatch();

  const selectedPacks = packs?.filter((pack) =>
    userSelection?.includes(pack.name),
  );

  const mobileRender = () => {
    dispatch(applicationStateActions.openAlert({ type: "packs", props: {} }));
  };

  return (
    <div className='pack-step'>
      <InfoMessage
        message={t("pack-picker.info-message.message")}
        tooltipMessage={
          <Trans
            i18nKey='pack-picker.info-message.tooltip-message'
            components={{ 1: <span /> }}
          />
        }
      />

      {device.type === "mobile" && (
        <Button
          className='pack-step-add-pack-btn'
          onClick={mobileRender}
          iconName='plus'
          iconSize='small'
          rightIcon
          fluid
        >
          {t("pack-picker.add-pack-button")}
        </Button>
      )}

      <div className='pack-step-container'>
        {selectedPacks.length === 0 && <SinglePack name='no-pack' />}
        {selectedPacks?.map(({ name, startingPrice, included }) => (
          <SinglePack
            key={name}
            name={name}
            startingPrice={startingPrice}
            included={included}
            buildingName={complex}
          />
        ))}
      </div>
    </div>
  );
};

export default PackStep;
