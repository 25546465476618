import { PriceDetails, StepSelectors } from "steps/types/step";
import type { RootState } from "store";
import type { ComplexStepModule } from "..";

export const complexStepSelectors: StepSelectors<ComplexStepModule> = {
  selectConfig: (rootState: RootState) =>
    rootState.complexStep.config || undefined,
  selectUserSelection: (rootState: RootState) =>
    rootState.complexStep.selectedComplex || undefined,
  selectPreviousFetchDataSelection: (rootState: RootState) =>
    rootState.complexStep.startEndDatesFetched,
  selectUserSelectionDetail: (rootState: RootState) => {
    const selectedComplex = complexStepSelectors.selectUserSelection(rootState);
    const config = complexStepSelectors.selectConfig(rootState);
    if (selectedComplex && config) {
      return config.options.find((complex) => complex.name === selectedComplex);
    }
    return undefined;
  },
  selectUnconfirmedUserSelection: (rootState: RootState) =>
    rootState.complexStep.unconfirmedComplex || undefined,
  selectUserSelectionPrice: (rootState: RootState): number => {
    const selectedComplexName =
      complexStepSelectors.selectUserSelection(rootState);
    const config = complexStepSelectors.selectConfig(rootState);
    const selectedComplex = config?.options.find(
      (complex) => complex.name === selectedComplexName,
    );
    if (selectedComplex) {
      return selectedComplex.price;
    }
    return 0;
  },
  selectUserSelectionPriceDetails: (rootState: RootState): PriceDetails => {
    const selectedComplexName =
      complexStepSelectors.selectUserSelection(rootState);
    const config = complexStepSelectors.selectConfig(rootState);
    const selectedComplex = config?.options.find(
      (complex) => complex.name === selectedComplexName,
    );
    if (selectedComplex) {
      return selectedComplex.priceDetails;
    }
    return {};
  },
  stepPageContentCarouselImagesSelector: (rootState) => {
    const currentComplex = complexStepSelectors.selectUserSelection(rootState);
    const unconfirmedComplex =
      complexStepSelectors.selectUnconfirmedUserSelection &&
      complexStepSelectors.selectUnconfirmedUserSelection(rootState);
    const carouselComplex = unconfirmedComplex || currentComplex;
    return rootState.complexStep.config?.options.find(
      (complex) => complex.name === carouselComplex,
    )?.carouselImages;
  },
  areOptionsAvailableSelector: (rootState) =>
    Boolean(rootState.complexStep.config?.options),
  selectUserSelectionFirstDate: (rootState: RootState): string | undefined => {
    const currentComplexName =
      complexStepSelectors.selectUserSelection(rootState);
    const currentComplex = rootState.complexStep.config?.options.find(
      (complex) => complex.name === currentComplexName,
    );
    return currentComplex?.availability.firstEntryAvailable?.startDate;
  },
  isStepLoading: (rootState) => rootState.complexStep.isLoading,
};
