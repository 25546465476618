/**
 * Type of entities
 */
export const ENTITY_TYPES = {
  EXAMPLE: "example",
  STEPS: "steps",
} as const;

export const UserInteractEntities = {
  examples: "examples",
  steps: "steps",
} as const;

export type UserInteractEntitiesType = keyof typeof UserInteractEntities;
