import React, { useState } from "react";
import classNames from "classnames";
import i18n, { Trans, useTranslation } from "@tecma/i18n";
import { useDispatch } from "react-redux";
import { useDevice } from "hooks/device/device";

import { CardItem } from "components/CardItem";
import { DefaultProps } from "utils/types/defaultProps";
import type { StepsComponentProps } from "steps/types/step";
import InfoMessage from "components/StepsWizard/components/InfoMessage/InfoMessage";
import { CardList } from "components/CardList";
import type { CardsListOptions } from "components/CardList/CardList";
import {
  getCleanName,
  firstLetterCapitalize,
} from "utils/functions/formatters";
import { applicationStateActions } from "store/slices/applicationState/slice";

import { Button, Icon, Spinner } from "@tecma/ui";
import type { ComplexStepModule } from "..";
import { ZoneFilter } from "./ZoneFilter";

// styles
import "./complexStep.scss";

// Required Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ComplexStepRequiredProps {}

// Optional Props
interface ComplexStepOptionalProps extends DefaultProps {
  isMobileModalContent?: boolean;
}

// Combined required and optional props to build the full prop interface
export interface ComplexStepProps
  extends ComplexStepRequiredProps,
    ComplexStepOptionalProps,
    StepsComponentProps<ComplexStepModule> {}

// use the optional prop interface to define the default props
const defaultProps: ComplexStepOptionalProps = {
  "data-testid": "space-configurator-complexStep",
  isMobileModalContent: false,
};

export interface ZonesMap {
  [key: string]: boolean;
}

const ComplexStep: React.FC<ComplexStepProps> = ({
  stepConfig,
  className,
  onUserSelectionChange,
  userSelection,
  confirmedUserSelection,
  zone: defaultZone,
  isMobileModalContent,
  ...rest
}) => {
  const { t } = useTranslation();
  const device = useDevice();
  const dispatch = useDispatch();

  const allZone = stepConfig.options.map((complex) => complex.zone);

  const zones = allZone.filter(
    (zone, index) => allZone.indexOf(zone) === index,
  );

  const hasDefaultZone = Boolean(defaultZone);

  const [selectedZonesMap, setSelectedZonesMap] = useState<ZonesMap>(
    zones.reduce(
      (zoneMap, curZone) => ({
        ...zoneMap,
        [curZone]: !hasDefaultZone || curZone === defaultZone,
      }),
      {},
    ),
  );

  const handleSelectAllZones = () => {
    setSelectedZonesMap(
      zones.reduce(
        (zoneMap, curZone) => ({
          ...zoneMap,
          [curZone]: true,
        }),
        {},
      ),
    );
  };

  const handleZoneToggle = (zone: string) => {
    const prevZoneValue = selectedZonesMap[zone];
    const newSelectedZonesMap = {
      ...selectedZonesMap,
      [zone]: !prevZoneValue,
    };
    const noZonesSelected = Object.values(newSelectedZonesMap).every(
      (value) => !value,
    );
    if (noZonesSelected) {
      handleSelectAllZones();
    } else {
      setSelectedZonesMap(newSelectedZonesMap);
    }
  };

  const handleSelectComplex = (complex: ComplexName) => {
    onUserSelectionChange(complex);
  };

  const firstAvailabilityDateLabel = (firstAvailability: string) =>
    new Date(firstAvailability).toLocaleString(i18n.language, {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

  const availabilityLabel = (complex: Complex) => {
    if (complex.availability.count) {
      return t("complex-picker.apartment-available", {
        count: complex.availability.count,
      });
    }
    if (complex.availability.firstEntryAvailable?.startDate) {
      return (
        <Trans
          i18nKey='complex-picker.available-from'
          values={{
            date: firstAvailabilityDateLabel(
              complex.availability.firstEntryAvailable.startDate,
            ),
          }}
        />
      );
    }
    return "";
  };

  const classList = classNames("space-configurator-complexStep", className);

  const cardsOptions: CardsListOptions[] = stepConfig.options
    .filter((complex) => selectedZonesMap[complex.zone])
    .map((complex) => {
      return {
        title: t([
          `buildings.${complex.name}`,
          firstLetterCapitalize(getCleanName(complex.name)),
        ]),
        subtitle: complex.zone && t(`zones.${complex.zone}`, complex.zone),
        image: complex.image,
        price: complex.price,
        onCardClick: () => handleSelectComplex(complex.name),
        active: userSelection === complex.name,
        confirmed: confirmedUserSelection === userSelection,
        availability: availabilityLabel(complex),
      };
    });

  const mobileRender = () => {
    dispatch(
      applicationStateActions.openAlert({
        type: "complex",
        props: { defaultZone, confirmedUserSelection },
      }),
    );
  };

  const complexActive = stepConfig.options.find(
    (complex) => userSelection === complex.name,
  );

  return (
    <div className={classList} {...rest}>
      {!isMobileModalContent && (
        <InfoMessage
          message={t("complex-picker.info-message.message")}
          tooltipMessage={
            <Trans
              i18nKey='complex-picker.info-message.tooltip-message'
              components={{ 1: <span /> }}
            />
          }
        />
      )}

      {(device.type === "desktop" ||
        device.type === "tablet" ||
        isMobileModalContent) && (
        <div className='complex-step-container'>
          {zones.length > 1 && (
            <ZoneFilter
              zones={selectedZonesMap}
              handleZoneToggle={handleZoneToggle}
              handleSelectAllZones={handleSelectAllZones}
            />
          )}
          {stepConfig?.options ? (
            <CardList
              usePagination={
                isMobileModalContent ? false : cardsOptions.length > 2
              }
              mode={isMobileModalContent ? "wrap" : "scroll"}
              cardsOptions={cardsOptions}
            />
          ) : (
            <Spinner />
          )}
        </div>
      )}

      {device.type === "mobile" && !isMobileModalContent && (
        <>
          <Button
            className='complex-step-mobile-select-btn'
            onClick={mobileRender}
            iconSize='small'
            rightIcon
            fluid
          >
            {t("complex-picker.select-complex-button")}
          </Button>
          {complexActive && (
            <>
              <p className='complex-step-mobile-selected-building'>
                {t("complex-picker.selected-building")}
              </p>
              <CardItem
                className='complex-step-mobile-complex-selected'
                title={t([
                  `buildings.${complexActive.name}`,
                  firstLetterCapitalize(getCleanName(complexActive.name)),
                ])}
                subtitle={
                  complexActive.zone &&
                  t(`zones.${complexActive.zone}`, complexActive.zone)
                }
                price={complexActive.price}
                onCardClick={() => {}}
              />
              {availabilityLabel(complexActive) && (
                <p className='complex-step-mobile-availability'>
                  <Icon iconName='warning' size='small' />
                  <span>{availabilityLabel(complexActive)}</span>
                </p>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

ComplexStep.defaultProps = defaultProps as Partial<ComplexStepOptionalProps>;

export default React.memo(ComplexStep);
