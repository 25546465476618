import React from "react";
import ReactDOM from "react-dom";
import { logger } from "@tecma/logs-fe";
import { WinstonProvider } from "winston-react/dist/context";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { ErrorBoundary } from "react-error-boundary";
import smoothscroll from "smoothscroll-polyfill";
import { GenericErrorPage } from "components/GenericErrorPage";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { storeFactory } from "./store";
import { instantiateRepositories } from "./store/domain";
import { AdaptersProvider } from "./hooks/storeRepositories/provider";
import { graphqlClient } from "./client/GraphqlClient";

const store = storeFactory();
const repos = instantiateRepositories(store);
smoothscroll.polyfill();

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary
      FallbackComponent={() => {
        return <GenericErrorPage />;
      }}
      onError={(error) => {
        logger.error(error);
      }}
    >
      <AdaptersProvider repos={repos}>
        <ApolloProvider client={graphqlClient}>
          <React.StrictMode>
            <WinstonProvider logger={logger}>
              <App />
            </WinstonProvider>
          </React.StrictMode>
        </ApolloProvider>
      </AdaptersProvider>
    </ErrorBoundary>
  </Provider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
