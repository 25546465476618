import React from "react";
import { Button, Spinner } from "@tecma/ui";
import { Trans } from "@tecma/i18n";
import classNames from "classnames";

import useConfirmButtonMobile from "hooks/useConfirmButtonMobile";
import "./confirm-button-mobile.scss";

const ConfirmButtonMobile = () => {
  const {
    isStart,
    buttonText,
    isDisabled,
    isCompleted,
    isLoading,
    isSavingConfiguration,
    isUserSelectionComplete,
    onMobileConfirmClick,
  } = useConfirmButtonMobile();

  const classList = classNames("confirm-button-mobile", {
    "is-finish": isCompleted,
    "is-selected": isUserSelectionComplete,
    "is-loading": isLoading,
  });

  return (
    <Button
      className={classList}
      disabled={!isLoading && (isDisabled || isSavingConfiguration || isStart)}
      secondary
      outlined={isStart || isDisabled}
      onClick={
        !(isLoading || isSavingConfiguration) ? onMobileConfirmClick : () => {}
      }
      fluid
    >
      <Trans i18nKey={buttonText} components={{ b: <strong /> }} />
      {(isLoading || isSavingConfiguration) && (
        <Spinner type='dotted-circle' size='small' />
      )}
    </Button>
  );
};

export default ConfirmButtonMobile;
