import { PayloadAction } from "@reduxjs/toolkit";
import { Task } from "redux-saga";
import { getCorrectDatesForConstraints } from "@tecma/date-picker-utils";
import {
  SagaReturnType,
  all,
  call,
  delay,
  fork,
  put,
  putResolve,
  select,
  takeLatest,
} from "redux-saga/effects";
import { DateTime } from "luxon";

import {
  StartEndDates,
  dateSelectorStateActions,
  getDateSelectorState,
} from "store/slices/dateSelectorState/slice";
import type {
  AvailableSpaces,
  DateSelectorState,
} from "store/slices/dateSelectorState/slice";
import { getDatesFromUrl } from "utils/functions/calculateDates";
import { firstDatePopupActions } from "components/AlertPopupFactory/components/FirstDatePopup/actions";
import { EntitiesRepositories } from "store/domain";
import { STEP_COMPONENTS_REGISTRY } from "steps";
import { dateSelectorActions } from "./availabilitySelector.actions";
import {
  GetAvailableSpaceCount,
  getAvailableSpacesCount,
} from "./availabilitySelector.api";
import { applicationStateSelectors } from "../../slices/applicationState/selectors";
import { applicationActions } from "../application/application.actions";
import { closeStep } from "../application/application.saga";

function* getAvailableSpacesCountFunction(startDate: string, endDate: string) {
  const projectId: string = yield select(
    applicationStateSelectors.selectProjectID,
  );
  const availableSpaces: SagaReturnType<typeof getAvailableSpacesCount> =
    yield getAvailableSpacesCount(projectId, startDate, endDate);
  return availableSpaces;
}

function* elaborateDates(dates: StartEndDates) {
  const { startDate, endDate } = dates;
  let parsedEndDate: string | undefined;

  if (!endDate) {
    parsedEndDate = DateTime.now().toISODate();
  }

  const rentConstraints: RentConstraints | undefined = yield select(
    applicationStateSelectors.selectRentConstraints,
  );
  if (!rentConstraints) {
    return dates;
  }

  const [newStartDate, newEndDate] = getCorrectDatesForConstraints(
    [startDate, parsedEndDate ?? endDate],
    {
      checkInDays: rentConstraints?.checkInDays,
      minMonthsToStay: rentConstraints?.minMonthsToStay,
    },
    startDate,
  );

  return { startDate: newStartDate, endDate: newEndDate };
}

function* setDatesSaga(
  startDate: string,
  endDate: string,
  availability: AvailableSpaces,
) {
  yield all([
    put(dateSelectorStateActions.updateStartDate(startDate)),
    put(dateSelectorStateActions.updateEndDate(endDate)),
    put(dateSelectorStateActions.updateAvailabilityCount(availability)),
  ]);
}

function* datesInitSaga(action: PayloadAction<StartEndDates>) {
  const { startDate, endDate }: StartEndDates = yield elaborateDates(
    action.payload,
  );
  const { startDate: startDateFromUrl } = yield getDatesFromUrl();
  const availability: GetAvailableSpaceCount =
    yield getAvailableSpacesCountFunction(startDate, endDate);
  if (
    availability.count === 0 &&
    availability.firstEntryAvailable &&
    !startDateFromUrl
  ) {
    const newElaboratedDates: StartEndDates = yield elaborateDates({
      startDate: availability?.firstEntryAvailable?.startDate,
      endDate: availability?.firstEntryAvailable?.endDate,
    });
    const newAvailability: GetAvailableSpaceCount =
      yield getAvailableSpacesCountFunction(
        newElaboratedDates.startDate,
        newElaboratedDates.endDate,
      );
    yield setDatesSaga(
      newElaboratedDates.startDate,
      newElaboratedDates.endDate,
      newAvailability,
    );
  } else {
    yield setDatesSaga(startDate, endDate, availability);
  }
  yield put(dateSelectorActions.datesInitCompleted());
}
function* forceStepOpening() {
  yield delay(5000);
  const { availableSpaces }: DateSelectorState = yield select(
    getDateSelectorState,
  );
  if (availableSpaces.loading) {
    yield put(
      dateSelectorStateActions.updateAvailabilityCount({
        count: 1,
        error: true,
      }),
    );
    yield put(applicationActions.resetSelectionsRequested());
  }
  yield true;
}

function* datesUpdateSaga(action: PayloadAction<StartEndDates>) {
  const { startDate, endDate }: StartEndDates = yield elaborateDates(
    action.payload,
  );
  yield setDatesSaga(startDate, endDate, { count: 1, loading: true });
  const currentStepIndex: number = yield select(
    applicationStateSelectors.selectCurrentStepIndex,
  );
  const currentStep =
    EntitiesRepositories.StepsEntityRepository?.selectAll()?.find(
      (step) => step.getOrder() === currentStepIndex,
    );
  if (currentStepIndex > 1) {
    yield putResolve(
      applicationActions.resetSelectionsRequested({ closeOpenStep: true }),
    );
  } else if (currentStep?.isOpen()) {
    closeStep(1);
    const currentStepModule = STEP_COMPONENTS_REGISTRY[currentStep.type];
    yield put(currentStepModule.setStepLoading(true));
  }
  yield delay(1000);

  const [availability, isStillLoading]: [GetAvailableSpaceCount, Task<void>] =
    yield all([
      call(getAvailableSpacesCountFunction, startDate, endDate),
      fork(forceStepOpening),
    ]);

  if (isStillLoading.isRunning()) {
    if (availability) {
      yield put(dateSelectorStateActions.updateAvailabilityCount(availability));
    }
    yield all([
      put(dateSelectorActions.datesUpdateCompleted()),
      put(applicationActions.resetSelectionsRequested()),
    ]);
  }
}

function* firstAvailabilityDatesUpdate(action: PayloadAction<StartEndDates>) {
  const { startDate, endDate } = yield elaborateDates(action.payload);
  const availability: GetAvailableSpaceCount =
    yield getAvailableSpacesCountFunction(startDate, endDate);
  yield setDatesSaga(startDate, endDate, availability);
  yield all([
    put(dateSelectorActions.datesUpdateCompleted()),
    put(applicationActions.closeAlertRequested()),
    put(firstDatePopupActions.confirmed()),
  ]);
}

export default function* dateSelectorSaga() {
  yield takeLatest(dateSelectorActions.datesInitRequested, datesInitSaga);
  yield takeLatest(dateSelectorActions.datesUpdateRequested, datesUpdateSaga);
  yield takeLatest(
    dateSelectorActions.firstAvailabilityDatesUpdateRequested,
    firstAvailabilityDatesUpdate,
  );
}
