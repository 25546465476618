/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { StartEndDates } from "../../../store/slices/dateSelectorState/slice";

export interface SpaceTypeStepSlice {
  config: SpaceTypeStepConfig | null;
  spaceType: SpaceTypeName | null;
  unconfirmedSpaceType: SpaceTypeName | null;
  isLoading: boolean;
  startEndDatesFetched: StartEndDates | null;
}

const initialState: SpaceTypeStepSlice = {
  config: null,
  spaceType: null,
  unconfirmedSpaceType: null,
  isLoading: false,
  startEndDatesFetched: null,
};

const slice = createSlice({
  name: "spaceTypeStep",
  initialState,
  reducers: {
    putConfig: (state, action: PayloadAction<SpaceTypeStepConfig>) => {
      state.config = action.payload;
    },
    resetConfig: (state) => {
      state.config = initialState.config;
    },
    setSpaceType: (state, action: PayloadAction<SpaceTypeName>) => {
      state.spaceType = action.payload;
    },
    setStartEndDateFetched: (state, action: PayloadAction<StartEndDates>) => {
      state.startEndDatesFetched = action.payload;
    },
    setUnconfirmedSpaceType: (state, action: PayloadAction<SpaceTypeName>) => {
      state.unconfirmedSpaceType = action.payload;
    },
    resetSpaceType: (state) => {
      state.spaceType = null;
      state.unconfirmedSpaceType = null;
    },
    setIsStepLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

const stepConfigActions = slice.actions;

const { reducer } = slice;

export const spaceTypeStepSlice = {
  stepConfigActions,
  reducer,
};
