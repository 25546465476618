import React from "react";
import { DefaultProps } from "utils/types/defaultProps";
import { Spinner } from "@tecma/ui";
import { StepsComponentProps } from "../../types/step";
import { ExtraSpaceType } from "./ExtraSpaceType";
import type { ExtraSpaceStepModule } from "..";

// styles
import "./extra-space-step.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExtraSpaceStepRequiredProps {}

// Optional Props
type ExtraSpaceStepOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface ExtraSpaceStepProps
  extends ExtraSpaceStepRequiredProps,
    ExtraSpaceStepOptionalProps,
    StepsComponentProps<ExtraSpaceStepModule> {}

// use the optional prop interface to define the default props
const defaultProps: ExtraSpaceStepOptionalProps = {
  "data-testid": "space-configurator-extraSpaceStep",
};

const ExtraSpaceStep: React.FC<ExtraSpaceStepProps> = ({
  stepConfig,
  userSelection,
  onUserSelectionChange,
  complex,
  ...rest
}) => {
  const handleChange = (
    selectedExtraSpaceId: string,
    extraSpacesNumber: number,
  ) => {
    onUserSelectionChange({
      [selectedExtraSpaceId]: extraSpacesNumber,
    });
  };

  return (
    <div
      className='space-configurator-extra-space'
      data-testid={rest["data-testid"]}
    >
      {stepConfig?.options ? (
        stepConfig.options.map(
          (extraSpace) =>
            userSelection && (
              <ExtraSpaceType
                key={extraSpace.type}
                extraSpace={extraSpace}
                onChange={handleChange}
                userSelection={userSelection}
                complex={complex}
              />
            ),
        )
      ) : (
        <Spinner />
      )}
    </div>
  );
};

ExtraSpaceStep.defaultProps =
  defaultProps as Partial<ExtraSpaceStepOptionalProps>;

export default React.memo(ExtraSpaceStep);
