import React from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "@tecma/i18n";
import { DefaultProps } from "utils/types/defaultProps";
import { PageSheet } from "components/PageSheet";
import type { PackStepModule } from "steps/PackStep";
import { useDevice } from "hooks/device/device";
import { PackCard } from "./components/CardPack";
import { StepPageContentRendererProps } from "../../../types/step";

// styles
import "./packsPageContent.scss";

// Required Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PacksPageContentRequiredProps
  extends StepPageContentRendererProps<PackStepModule> {}

// Optional Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PacksPageContentOptionalProps extends DefaultProps {}

// Combined required and optional props to build the full prop interface
export interface PacksPageContentProps
  extends PacksPageContentRequiredProps,
    PacksPageContentOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: PacksPageContentOptionalProps = {
  "data-testid": "space-configurator-packsPageContent",
};

const PacksPageContent: React.FC<PacksPageContentProps> = ({
  className,
  stepConfig,
  onUserSelectionChange,
  userSelection,
  complex,
  isZoomed,
  ...rest
}) => {
  const classList = classNames(
    "space-configurator-packsPageContent",
    className,
  );
  const device = useDevice();
  // use to force rerender in case of language change
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();

  const handleChange = (pack: PackName, added: boolean) => {
    if (added) {
      onUserSelectionChange([...(userSelection || []), pack]);
    } else {
      onUserSelectionChange([
        ...(userSelection ? userSelection.filter((name) => name !== pack) : []),
      ]);
    }
  };

  return (
    <PageSheet
      title={
        device.type === "desktop" ? (
          <Trans
            i18nKey='pack-picker.page-content-title'
            components={{ b: <strong /> }}
          />
        ) : undefined
      }
      subtitle={
        device.type === "desktop" ? (
          <Trans i18nKey='pack-picker.page-content-description' />
        ) : undefined
      }
      className='packs-page-sheet'
      overflow={device.type === "desktop"}
      isZoomed={isZoomed}
    >
      <div className={classList} {...rest}>
        {stepConfig.map((pack) => (
          <PackCard
            key={pack.name}
            packName={pack.name}
            packImage={pack.image}
            packDescription={pack.description}
            packPrice={pack.startingPrice}
            packDetails={pack.details}
            included={pack.included}
            added={Boolean(
              userSelection?.includes // TODO: why do we need this?! I've no idea
                ? userSelection.includes(pack.name)
                : false,
            )}
            onChange={(newAdded: boolean) => handleChange(pack.name, newAdded)}
            complex={complex}
          />
        ))}
      </div>
    </PageSheet>
  );
};

PacksPageContent.defaultProps =
  defaultProps as Partial<PacksPageContentOptionalProps>;

export default PacksPageContent;
