import React from "react";
import classNames from "classnames";
import { DefaultProps } from "utils/types/defaultProps";
import { Icon, Tooltip } from "@tecma/ui";
import { useDevice } from "hooks/device/device";

import "./info-message.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface InfoMessageRequiredProps {
  message: string;
  tooltipMessage: React.ReactElement;
}

// Optional Props
type InfoMessageOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface InfoMessageProps
  extends InfoMessageRequiredProps,
    InfoMessageOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: InfoMessageOptionalProps = {
  "data-testid": "pace-configurator-InfoMessage",
};

const InfoMessage: React.FC<InfoMessageProps> = ({
  className,
  message,
  tooltipMessage,
  ...rest
}) => {
  const classList = classNames("space-configurator-info-message", className);
  const device = useDevice();

  return (
    <div className={classList} {...rest}>
      <Tooltip
        className='space-configurator-info-message-tooltip'
        position='bottom-left'
        trigger={<Icon iconName='info' filled />}
        triggerEvent={device.type !== "desktop" ? "click" : "hover"}
        arrow
      >
        <div>{tooltipMessage}</div>
      </Tooltip>
      <p>{message}</p>
    </div>
  );
};

InfoMessage.defaultProps = defaultProps as Partial<InfoMessageOptionalProps>;

export default React.memo(InfoMessage);
