import type { RootState } from "store";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { STEP_COMPONENTS_REGISTRY } from ".";
import { AllStepModules, StepType } from "./types/step";

export const selectSteps = (state: RootState) => {
  const stepIds = state.steps.ids;
  const steps = stepIds.map((id) => state.steps.entities[id]);

  return steps;
};

const getStepModuleFromIndex = (state: RootState, stepIndex: number) => {
  const stepIds = state.steps.ids;
  const currentStepId = stepIds[stepIndex];

  if (!currentStepId) {
    return null;
  }

  const currentStep = state.steps.entities[currentStepId];

  const currentStepType = currentStep?.type as StepType;

  return STEP_COMPONENTS_REGISTRY[currentStepType];
};

const getStepImages = (state: RootState, stepModule: AllStepModules) => {
  if (!stepModule) {
    return null;
  }

  const currentStepImages =
    stepModule?.stepPageContentCarouselImagesSelector &&
    stepModule.stepPageContentCarouselImagesSelector(state);

  return currentStepImages;
};

export const selectCurrentStepImages = (state: RootState) => {
  const currentStepIndex =
    applicationStateSelectors.selectCurrentStepIndex(state) - 1; // TODO: refactor: index starts from 1

  const previousStepIndex = currentStepIndex - 1;

  const currentStepModule = getStepModuleFromIndex(state, currentStepIndex);

  if (currentStepModule) {
    const currentStepImages = getStepImages(state, currentStepModule);

    if (currentStepImages) {
      return {
        carouselImages: currentStepImages,
        imagesStepIndex: currentStepIndex + 1,
      };
    }
  }

  for (let i = previousStepIndex; i >= 0; i -= 1) {
    const previousStepModule = getStepModuleFromIndex(state, i);

    if (!previousStepModule) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const previousStepImages = getStepImages(state, previousStepModule);

    if (previousStepImages) {
      return { carouselImages: previousStepImages, imagesStepIndex: i };
    }
  }

  return {
    carouselImages:
      applicationStateSelectors.selectDefaultCarouselImages(state),
    imagesStepIndex: 0,
  };
};
