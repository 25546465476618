/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: PackStepSlice = {
  config: {
    options: null,
    carouselImages: [],
  },
  selectedPacks: undefined,
  unconfirmedPacks: [],
  isLoading: false,
};

const slice = createSlice({
  name: "packStep",
  initialState,
  reducers: {
    putConfig: (state, action: PayloadAction<PackStepConfig>) => {
      state.config = action.payload;
    },
    setSelectedPacks: (state, action: PayloadAction<PackName[]>) => {
      state.selectedPacks = action.payload;
    },
    setUnconfirmedPacks: (state, action: PayloadAction<PackName[]>) => {
      state.unconfirmedPacks = action.payload;
    },
    resetSelectedPacks: (state) => {
      state.selectedPacks = initialState.selectedPacks;
      state.unconfirmedPacks = initialState.selectedPacks;
    },
    setIsStepLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

const stepConfigActions = slice.actions;

const { reducer } = slice;

export const packStepSlice = {
  stepConfigActions,
  reducer,
};
