import React, { ReactElement } from "react";
import classNames from "classnames";
import { DefaultProps } from "utils/types/defaultProps";
import { useSelector } from "react-redux";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";

// styles
import "./page-sheet.scss";

// Required Props
interface PageSheetRequiredProps {
  children: React.ReactNode;
  isZoomed: boolean;
}

// Optional Props
interface PageSheetOptionalProps extends DefaultProps {
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  overflow?: boolean;
}

// Combined required and optional props to build the full prop interface
export interface PageSheetProps
  extends PageSheetRequiredProps,
    PageSheetOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: PageSheetOptionalProps = {
  "data-testid": "page-sheet",
};

const PageSheet: React.FC<PageSheetProps> = ({
  title,
  subtitle,
  children,
  className,
  overflow,
  isZoomed,
  ...rest
}) => {
  const classList = classNames(
    "page-sheet",
    overflow && "page-overflow",
    { isZoomed },
    className,
  );

  return (
    <div className={classList} {...rest}>
      {(title || subtitle) && (
        <div className='page-sheet-description'>
          <p className='page-sheet-title'>{title}</p>
          <span className='page-sheet-subtitle'>{subtitle}</span>
        </div>
      )}
      <div className='page-sheet-container'>{children}</div>
    </div>
  );
};

PageSheet.defaultProps = defaultProps as Partial<PageSheetOptionalProps>;

export default React.memo(PageSheet);
