// eslint-disable-next-line import/no-cycle
import { STEP_COMPONENTS_REGISTRY } from "steps";
import type { RootState } from "store";
import { calculateSpecificPriceDetails } from "utils/functions/extraSpace";
import type {
  ExtractModuleUserSelection,
  PriceDetails,
  StepRegistry,
  StepType,
  AllStepModules,
  UserSelection,
} from "./types/step";

export const getIsUserSelectionCompleteSelector = (
  stepModule: AllStepModules,
  userSelection: UserSelection | undefined,
) => {
  return (
    stepModule.isUserSelectionCompleteSelector || (() => userSelection != null)
  );
};

export type UserSelections = {
  [key in StepType]?: ExtractModuleUserSelection<StepRegistry[key]>;
};

export type UserSelectionsParams = {
  userSelections: UserSelections;
  complex: string;
  checkInDate: string;
  checkOutDate: string;
  projectId: string;
};

export const getTotalPriceSelector = (state: RootState) => {
  const stepIds = state.steps.ids;
  const total = stepIds?.reduce<{ basePrice: number; extraPrice: number }>(
    (curTotal, curStepId) => {
      const curStep = state.steps.entities[curStepId];
      const curStepType = curStep?.type as StepType;

      if (curStepType) {
        const curStepModule = STEP_COMPONENTS_REGISTRY[curStepType];
        const getCurStepPrice = curStepModule?.userSelectionPriceSelector;
        const priceRule = curStepModule?.priceCalculationMode;
        if (getCurStepPrice) {
          const curPrice = getCurStepPrice(state);
          if (priceRule === "REPLACEMENT") {
            return {
              ...curTotal,
              basePrice: curPrice || curTotal.basePrice,
            };
          }
          return {
            ...curTotal,
            extraPrice: curTotal.extraPrice + curPrice || 0,
          };
        }
        return curTotal;
      }
      throw new Error(
        `Cannot find step module ${curStepType} for step ${curStepId}`,
      );
    },
    { basePrice: 0, extraPrice: 0 },
  );
  return total.basePrice + total.extraPrice || 0;
};

export const getPriceDetailsSelector = (state: RootState) => {
  const stepIds = state.steps.ids;
  const total = stepIds?.reduce<{
    basePriceDetails: PriceDetails;
    extraPriceDetails: PriceDetails;
  }>(
    (curPriceDetails, curStepId) => {
      const curStep = state.steps.entities[curStepId];
      const curStepType = curStep?.type as StepType;

      if (curStepType) {
        const curStepModule = STEP_COMPONENTS_REGISTRY[curStepType];
        const getCurStepPriceDetails =
          curStepModule?.userSelectionPriceDetailsSelector;
        const priceRule = curStepModule?.priceCalculationMode;
        if (getCurStepPriceDetails) {
          const curStepPriceDetails = getCurStepPriceDetails(state);
          if (priceRule === "REPLACEMENT") {
            return {
              ...curPriceDetails,
              basePriceDetails: {
                ...curPriceDetails.basePriceDetails,
                ...curStepPriceDetails,
              },
            };
          }

          return {
            ...curPriceDetails,
            extraPriceDetails: calculateSpecificPriceDetails(
              curPriceDetails.extraPriceDetails,
              curStepPriceDetails,
            ),
          };
        }
        return curPriceDetails;
      }
      throw new Error(
        `Cannot find step module ${curStepType} for step ${curStepId}`,
      );
    },
    { basePriceDetails: {}, extraPriceDetails: {} },
  );
  return calculateSpecificPriceDetails(
    total.basePriceDetails,
    total.extraPriceDetails,
  );
};

export const getUserSelections = (state: RootState) => {
  const stepIds = state.steps.ids;
  const userSelections = stepIds?.reduce<Partial<UserSelections>>(
    (curSelections, stepID) => {
      const curStep = state.steps.entities[stepID];
      const curStepType = curStep?.type as StepType;

      if (curStepType) {
        const curStepModule = STEP_COMPONENTS_REGISTRY[curStepType];
        const getCurUserSelector = curStepModule?.userSelectionSelector;
        if (getCurUserSelector) {
          const currSelection = getCurUserSelector(state);
          return { ...curSelections, [curStepType]: currSelection };
        }
        return curSelections;
      }

      throw new Error(
        `Cannot find step module ${curStepType} for step ${curStepType}`,
      );
    },
    {},
  );
  return userSelections;
};

export const stepsStateSelectors = {
  selectPriceDetails: getPriceDetailsSelector,
  selectTotalPrice: getTotalPriceSelector,
  selectUserSelections: getUserSelections,
};
