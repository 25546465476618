import React from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "@tecma/i18n";
import { Icon, ValuePicker } from "@tecma/ui";

import { useDevice } from "hooks/device/device";
import InfoMessage from "../../../components/StepsWizard/components/InfoMessage/InfoMessage";
import { StepsComponentProps } from "../../types/step";
import type { DefaultProps } from "../../../utils/types/defaultProps";
import type { FloorStepModule } from "..";

// styles
import "./floor-step.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FloorStepRequiredProps {}

// Optional Props
type FloorStepOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface FloorStepProps
  extends FloorStepRequiredProps,
    FloorStepOptionalProps,
    StepsComponentProps<FloorStepModule> {}

// use the optional prop interface to define the default props
const defaultProps: FloorStepOptionalProps = {
  "data-testid": "space-configurator-floorStep",
};

const FloorStep: React.FC<FloorStepProps> = ({
  className,
  stepConfig,
  onUserSelectionChange,
  userSelection,
  ...rest
}) => {
  const classList = classNames("space-configurator-floor-step", className);
  const { t } = useTranslation();
  const device = useDevice();

  const floors = stepConfig.options;

  const options: { value: number; label: string }[] = floors.map((floor) => ({
    value: floor.number,
    label: `${floor.number}`,
  }));

  const handleChange = (floor: FloorNumber) => {
    onUserSelectionChange(floor);
  };

  const floorAvailabilityText =
    stepConfig.options.length === 1 &&
    device.type === "desktop" &&
    t("floor-picker.only-one-apartment-available", {
      defaultValue: "Availability only on this floor",
    });

  return (
    <div className={classList} data-testid={rest["data-testid"]}>
      <InfoMessage
        message={t("floor-picker.info-message.message")}
        tooltipMessage={
          <Trans
            i18nKey='floor-picker.info-message.tooltip-message'
            components={{ 1: <span /> }}
          />
        }
      />

      <div className='floor-container'>
        {floorAvailabilityText && (
          <div className='floor-container-availability'>
            <Icon iconName='warning' size='small' />
            <p className='floor-container-availability-text'>
              {floorAvailabilityText}
            </p>
          </div>
        )}
        {stepConfig && (
          <div className='floor-content'>
            <ValuePicker
              options={options}
              value={userSelection ?? null}
              onChange={handleChange}
            />
            <p className='floor-total-floors'>
              {t("floor-picker.total-floors", {
                totalFloors: stepConfig.buildingFloorsNumber,
              })}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

FloorStep.defaultProps = defaultProps as Partial<FloorStepOptionalProps>;

export default FloorStep;
