import React from "react";
import { useTranslation } from "@tecma/i18n";
import { Icon } from "@tecma/ui";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { DefaultProps } from "utils/types/defaultProps";
import { PriceDetailItem } from "components/RecapSection/components/PriceDetailItem";
import { useDevice } from "hooks/device/device";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { getPriceDetailsViewMode } from "utils/functions/prices";
import { applicationStateActions } from "store/slices/applicationState/slice";
import type { PriceDetailsTransformed } from "steps/types/step";
import "./price-details.scss";

interface PriceDetailsRequiredProps {
  prices: PriceDetailsTransformed[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PriceDetailsOptionalProps extends DefaultProps {}

export interface PriceDetailsProps
  extends PriceDetailsRequiredProps,
    PriceDetailsOptionalProps {}

const defaultProps: PriceDetailsOptionalProps = {
  "data-testid": "space-configurator-priceDetails",
};
const PriceDetails: React.FC<PriceDetailsProps> = ({ prices }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { type, typeDetailed } = useDevice();
  const isOpenRecap = useSelector(applicationStateSelectors.selectIsOpenRecap);
  const isOpenPriceDetails = useSelector(
    applicationStateSelectors.selectIsOpenPriceDetails,
  );

  const pricesDetailsClasses = classNames("price-details", {
    isOpenPriceDetails,
    isOpenRecap,
  });
  const pricesDetailsButtonClasses = classNames("price-details__button", {
    isOpenPriceDetails,
    isOpenRecap,
  });
  const pricesDetailsExcludedPriceNameClasses = classNames(
    "price-details__excluded-price-name",
    {
      isOpenPriceDetails,
      isOpenRecap,
    },
  );

  const pricesDetailsContentClasses = classNames("price-details__content", {
    isOpenPriceDetails:
      ["desktop_m", "desktop_l"].includes(typeDetailed) ||
      (type === "mobile"
        ? isOpenRecap && isOpenPriceDetails
        : isOpenPriceDetails),
  });

  const handleTogglePriceDetails = () => {
    if (isOpenPriceDetails) {
      dispatch(applicationStateActions.closePriceDetails());
    } else {
      dispatch(applicationStateActions.openPriceDetails());
    }
  };

  const getContent = () => {
    const pricesDetailsViewMode = getPriceDetailsViewMode(prices);
    if (pricesDetailsViewMode === "sentence") {
      const priceNameTranslated = t(`recap.prices-details.${prices[0].name}`, {
        defaultValue: prices[0].name,
      });
      return (
        <span className={pricesDetailsExcludedPriceNameClasses}>
          {t(`recap.prices-details.excluded-${prices[0].name}`, {
            defaultValue: `*${priceNameTranslated} excluded`,
          })}
        </span>
      );
    }
    return (
      <>
        {!["desktop_m", "desktop_l"].includes(typeDetailed) && (
          <button
            type='button'
            className={pricesDetailsButtonClasses}
            onClick={() => handleTogglePriceDetails()}
          >
            <p className='price-details__button__text'>
              {t("recap.prices-details.see-detail", {
                defaultValue: "See detail",
              })}
            </p>
            <Icon iconName='arrow-down' size='small' />
          </button>
        )}
        <div className={pricesDetailsContentClasses}>
          <div className='price-details__content__table'>
            <p className='price-details__content__title'>
              {t("recap.prices-details.title", {
                defaultValue: "Included in the price:",
              })}
            </p>
            {prices.map((price) => (
              <PriceDetailItem key={price.name} priceDetail={price} />
            ))}
          </div>
        </div>
      </>
    );
  };

  return <div className={pricesDetailsClasses}>{getContent()}</div>;
};

PriceDetails.defaultProps = defaultProps as Partial<PriceDetailsOptionalProps>;

export default PriceDetails;
