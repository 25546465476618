import { useTranslation } from "@tecma/i18n";
import React from "react";
import { getCleanName } from "utils/functions/formatters";
import type { MoodStepModule } from "steps/MoodStep";
import type { DefaultProps } from "../../../../utils/types/defaultProps";
import type { RecapStepsComponentProps } from "../../../types/step";
import "./mood-recap.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MoodRecapRequiredProps {}

// Optional Props
type MoodRecapOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface MoodRecapProps
  extends MoodRecapRequiredProps,
    MoodRecapOptionalProps,
    RecapStepsComponentProps<MoodStepModule> {}

const MoodRecap: React.FC<MoodRecapProps> = ({
  userSelectionDetails,
  buildingName,
}) => {
  const { t } = useTranslation();

  return (
    <p className='mood-recap'>
      {userSelectionDetails &&
        t([
          `${buildingName}.mood-picker.moods.${userSelectionDetails.name}`,
          `mood-picker.moods.${userSelectionDetails.name}`,
          getCleanName(userSelectionDetails.name),
        ])}
    </p>
  );
};

export default MoodRecap;
