import { PriceDetails, StepSelectors } from "steps/types/step";
import type { RootState } from "store";

import type { SpaceTypeStepModule } from "..";
import { isSpaceAvailable } from "../utils/isSpaceAvailable";

const selectConfigState = (state: RootState) =>
  state.spaceTypeStep?.config || undefined;

const selectPreviousFetchDataSelection = (state: RootState) =>
  state.spaceTypeStep.startEndDatesFetched;

const selectSelectedSpaceTypeState = (state: RootState) =>
  state.spaceTypeStep?.spaceType || undefined;

const selectUnconfirmedSpaceTypeState = (state: RootState) =>
  state.spaceTypeStep?.unconfirmedSpaceType || undefined;

const selectSelectedSpaceTypeDetailState = (
  state: RootState,
): SpaceType | undefined => {
  if (state.spaceTypeStep.spaceType) {
    return state.spaceTypeStep.config?.options?.find(
      (spaceType) => spaceType.spaceTypeName === state.spaceTypeStep?.spaceType,
    );
  }
  return undefined;
};

const selectSelectedSpaceTypePriceState = (state: RootState): number => {
  if (state?.spaceTypeStep.spaceType) {
    return (
      state.spaceTypeStep.config?.options?.find(
        (spaceType) =>
          spaceType.spaceTypeName === state.spaceTypeStep?.spaceType,
      )?.spaceTypeStartingPrice || 0
    );
  }
  return 0;
};

const selectSelectedSpaceTypePriceDetailsState = (
  state: RootState,
): PriceDetails => {
  if (state?.spaceTypeStep.spaceType) {
    return (
      state.spaceTypeStep.config?.options?.find(
        (spaceType) =>
          spaceType.spaceTypeName === state.spaceTypeStep?.spaceType,
      )?.priceDetails || {}
    );
  }
  return {};
};

const isSpaceTypeAvailable = (
  options: Array<SpaceType>,
  selectedSpace: string,
): boolean => {
  const spaceType = options?.find(
    (element) => element.spaceTypeName === selectedSpace,
  );
  const count = spaceType?.availability.count;
  const firstEntryAvailable =
    spaceType?.availability?.firstEntryAvailable?.startDate;
  return isSpaceAvailable(count, firstEntryAvailable);
};

const isSelectSpaceTypeSoldOut = (state: RootState): boolean => {
  if (
    state.spaceTypeStep.unconfirmedSpaceType &&
    state.spaceTypeStep.config?.options
  ) {
    return !isSpaceTypeAvailable(
      state.spaceTypeStep.config?.options,
      state.spaceTypeStep?.unconfirmedSpaceType,
    );
  }
  if (state.spaceTypeStep.spaceType && state.spaceTypeStep.config?.options) {
    return !isSpaceTypeAvailable(
      state.spaceTypeStep.config?.options,
      state.spaceTypeStep?.spaceType,
    );
  }
  return false;
};

const selectSelectedSpaceTypeFirsAvailabilityState = (
  state: RootState,
): string | undefined => {
  const spaceTypeConfig = state.spaceTypeStep.config?.options;
  const spaceTypeName = state.spaceTypeStep.spaceType;
  const spaceSelected = spaceTypeConfig?.find(
    (space) => space.spaceTypeName === spaceTypeName,
  );
  return spaceSelected?.availability.firstEntryAvailable?.startDate;
};

export const spaceTypeStepSelectors: StepSelectors<SpaceTypeStepModule> = {
  selectConfig: selectConfigState,
  selectUserSelection: selectSelectedSpaceTypeState,
  selectUnconfirmedUserSelection: selectUnconfirmedSpaceTypeState,
  selectUserSelectionDetail: selectSelectedSpaceTypeDetailState,
  selectUserSelectionPrice: selectSelectedSpaceTypePriceState,
  selectPreviousFetchDataSelection,
  selectUserSelectionPriceDetails: selectSelectedSpaceTypePriceDetailsState,
  selectUserSelectionFirstDate: selectSelectedSpaceTypeFirsAvailabilityState,
  isUserSelectionSoldOut: isSelectSpaceTypeSoldOut,
  stepPageContentCarouselImagesSelector: (state) => {
    const unconfirmedSpaceType = selectUnconfirmedSpaceTypeState(state);
    const selectedSpaceType = selectSelectedSpaceTypeState(state);
    return state.spaceTypeStep.config?.options?.find(
      (spaceType) =>
        spaceType.spaceTypeName === (unconfirmedSpaceType || selectedSpaceType),
    )?.carouselImages;
  },
  areOptionsAvailableSelector: (state) =>
    Number(state.spaceTypeStep.config?.options.length) > 0,
  isStepLoading: (state) => state.spaceTypeStep.isLoading,
};
