import React from "react";
import classNames from "classnames";
import { useTranslation, Trans } from "@tecma/i18n";
import { Carousel, Icon, ValuePicker } from "@tecma/ui";

import InfoMessage from "components/StepsWizard/components/InfoMessage/InfoMessage";
import { PlanDetails } from "components/PlanDetails";
import { DefaultProps } from "utils/types/defaultProps";
import { getAvailabilityLabel } from "utils/functions/availability";
import { getCleanName } from "utils/functions/formatters";
import { StepsComponentProps } from "../../types/step";
import type { SpaceTypeStepModule } from "..";
import { isSpaceAvailable } from "../utils/isSpaceAvailable";

import "./space-type-step.scss";

// Optional Props
type SpaceTypeStepOptionalProps = DefaultProps;

export interface SpaceTypeStepProps
  extends SpaceTypeStepOptionalProps,
    StepsComponentProps<SpaceTypeStepModule> {}

const SpaceTypeStep: React.FC<SpaceTypeStepProps> = ({
  className,
  stepConfig,
  userSelection,
  onUserSelectionChange,
  complex,
}) => {
  const classList = classNames("space-type-step", className);
  const { t } = useTranslation();

  const options = stepConfig.options
    .map((space: SpaceType) => ({
      rooms: space.mainFeatures?.rooms,
      value: space.spaceTypeName,
      label: t([
        `${complex}.space-picker.spaces.${space.spaceTypeName}`,
        `space-picker.spaces.${space.spaceTypeName}`,
        getCleanName(space.spaceTypeName),
      ]),
    }))
    .sort((a, b) => {
      if (a.rooms !== b.rooms) {
        return a.rooms - b.rooms;
      }
      return a.label.localeCompare(b.label);
    });

  const spaceTypes = stepConfig.options
    .map((justClone) => justClone)
    .sort((a, b) => {
      const aValueIndex = options
        .map((option) => option.value)
        .indexOf(a.spaceTypeName);
      const bValueIndex = options
        .map((option) => option.value)
        .indexOf(b.spaceTypeName);
      return aValueIndex - bValueIndex;
    });

  const currentSpaceIndex = spaceTypes.findIndex(
    (type) => type.spaceTypeName === userSelection,
  );
  const selectedSpaceType = spaceTypes.find(
    (type) => type.spaceTypeName === userSelection,
  );

  const isSelectedSpaceSoldOut = !isSpaceAvailable(
    selectedSpaceType?.availability.count,
    selectedSpaceType?.availability?.firstEntryAvailable?.startDate,
  );

  const classListAvailability = classNames("availability", {
    hidden: !selectedSpaceType?.availability,
  });

  const classListValuePickerContainer = classNames("value-picker", {
    disabled: !isSelectedSpaceSoldOut,
  });

  const classListSpaceInfo = classNames("space-info", {
    disabled: !isSelectedSpaceSoldOut,
  });

  const availabilityLabel =
    selectedSpaceType?.availability &&
    getAvailabilityLabel(selectedSpaceType?.availability);

  return (
    <div className={classList}>
      <InfoMessage
        message={t("space-picker.info-message.message")}
        tooltipMessage={
          <Trans
            i18nKey='space-picker.info-message.tooltip-message'
            components={{ 1: <span /> }}
          />
        }
      />
      <div className='space-type-container'>
        <div className={classListAvailability}>
          <Icon iconName='warning' size='small' />
          <p>
            {availabilityLabel && (
              <Trans
                i18nKey={`space-picker.${availabilityLabel.label}`}
                values={availabilityLabel?.value}
                components={{
                  1: <span />,
                }}
              />
            )}
          </p>
        </div>
        {stepConfig && (
          <div className='space-type-content'>
            <ValuePicker
              className={classListValuePickerContainer}
              options={options}
              value={userSelection || null}
              onChange={onUserSelectionChange}
              placeholder={t("space-picker.space-type-name-placeholder", {
                numberOfTypes: options.length,
              })}
              loop
            />
            <Carousel
              selectedItemIndex={currentSpaceIndex}
              showPagination={false}
              loop={false}
            >
              {spaceTypes.map((space) => (
                <div key={space.spaceTypeName} className={classListSpaceInfo}>
                  {space.mainFeatures && (
                    <PlanDetails
                      mainFeatures={space.mainFeatures}
                      spaceName={space.spaceTypeName}
                      complex={complex}
                    />
                  )}
                  <div className='price'>
                    {isSelectedSpaceSoldOut ? (
                      <Trans
                        i18nKey='space-picker.starting-price'
                        values={{
                          price: Math.round(space.spaceTypeStartingPrice),
                        }}
                      />
                    ) : (
                      <Trans i18nKey='space-picker.sold-out' />
                    )}
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpaceTypeStep;
