import React, { useState } from "react";
import classNames from "classnames";
import { DefaultProps } from "utils/types/defaultProps";
import { Button, Icon } from "@tecma/ui";
import { getCleanName } from "utils/functions/formatters";
import { useTranslation } from "@tecma/i18n";
import { ZonesMap } from "../ComplexStep";

// styles
import "./zoneFilter.scss";

// Required Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ZoneFilterRequiredProps {
  zones: ZonesMap;
  handleZoneToggle: (zone: string) => void;
  handleSelectAllZones: () => void;
}

// Optional Props
type ZoneFilterOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface ZoneFilterProps
  extends ZoneFilterRequiredProps,
    ZoneFilterOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: ZoneFilterOptionalProps = {
  "data-testid": "space-configurator-zoneFilter",
};

const ZoneFilter: React.FC<ZoneFilterProps> = ({
  className,
  zones,
  handleZoneToggle,
  handleSelectAllZones,
  ...rest
}) => {
  const { t } = useTranslation();

  const [seeZones, setSeeZones] = useState(false);

  const allZonesSelected = Object.values(zones).every((value) => value);

  const classList = classNames("space-configurator-zoneFilter", className);
  return (
    <div className={classList} {...rest}>
      <div className={seeZones ? "see-zones-open" : ""}>
        <Button
          onClick={() => {
            setSeeZones(!seeZones);
          }}
          className='see-zones-btn'
          rounded
        >
          {t(`complex-picker.zones-filter.${seeZones ? "close" : "open"}`)}
          <Icon iconName={seeZones ? "arrow-up" : "filter"} size='small' />
        </Button>
      </div>
      {(seeZones || allZonesSelected) && (
        <Button
          onClick={handleSelectAllZones}
          className={classNames({ selected: allZonesSelected })}
          rounded
        >
          <Icon iconName='globe' size='small' />
          {t("complex-picker.zones-filter.all")}
        </Button>
      )}
      {Object.entries(zones).map(
        ([zone, selected]) =>
          (seeZones || (selected && !allZonesSelected)) && (
            <Button
              key={zone}
              onClick={() => handleZoneToggle(zone)}
              className={classNames({
                selected,
              })}
              rounded
            >
              {t(`zones.${zone}`, getCleanName(zone))}
              {selected && <Icon iconName='cross' size='small' />}
            </Button>
          ),
      )}
    </div>
  );
};

ZoneFilter.defaultProps = defaultProps as Partial<ZoneFilterOptionalProps>;

export default React.memo(ZoneFilter);
