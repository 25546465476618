import React from "react";
import classNames from "classnames";
import { DefaultProps } from "utils/types/defaultProps";
import { Trans, useTranslation } from "@tecma/i18n";
import { getApplicationState } from "store/slices/applicationState/slice";
import { DatePickerSection } from "components/DatePickerSection";
import { useSelector } from "react-redux";
import { useDevice } from "hooks/device/device";
import { getCleanName } from "utils/functions/formatters";

// styles
import "./space-info.scss";
import { applicationStateSelectors } from "../../store/slices/applicationState/selectors";

// Required Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SpaceInfoRequiredProps {}

// Optional Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SpaceInfoOptionalProps extends DefaultProps {}

// Combined required and optional props to build the full prop interface
export interface SpaceInfoProps
  extends SpaceInfoRequiredProps,
    SpaceInfoOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: SpaceInfoOptionalProps = {
  "data-testid": "space-configurator-date-picker",
};

const ENABLE_CHANGE_BUILDING = false;

const SpaceInfo: React.FC<SpaceInfoProps> = ({ className, ...rest }) => {
  const classList = classNames("space-configurator-space-info", className);
  const { t } = useTranslation();

  const applicationState = useSelector(getApplicationState);
  const complex = useSelector(applicationStateSelectors.selectComplex);
  const device = useDevice();

  return (
    <div className={classList} {...rest}>
      {ENABLE_CHANGE_BUILDING && (
        <div className='multi-building'>
          <h3 className='building-name'>
            {t([`building.${complex}`, getCleanName(complex || "")])}
          </h3>
          <a
            href={applicationState.projectInfo?.config.privacyLink}
            className='change-building'
          >
            {t("date-picker.change-building")}
          </a>
          <p className='current-configuration-cleared'>
            {t("date-picker.current-configuration-cleared")}
          </p>
        </div>
      )}
      <div className='container'>
        {device.type === "desktop" && (
          <>
            <h3 className='title'>
              <Trans
                i18nKey='date-picker.rent-online'
                components={{
                  1: <span />,
                }}
              />
            </h3>
            <p className='subtitle'>{t("date-picker.configure-rent")}</p>
          </>
        )}
        <DatePickerSection />
      </div>
    </div>
  );
};

SpaceInfo.defaultProps = defaultProps as Partial<SpaceInfoOptionalProps>;

export default React.memo(SpaceInfo);
