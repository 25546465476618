import React from "react";
import classNames from "classnames";
import { useTranslation, Trans } from "@tecma/i18n";

import { DefaultProps } from "utils/types/defaultProps";

// styles
import "./planDetailsLabel.scss";

// Required Props
interface PlanDetailsLabelRequiredProps {
  room: string;
  count: number;
  spaceName: string;
}

// Optional Props
interface PlanDetailsLabelOptionalProps extends DefaultProps {
  complex?: string;
}

// Combined required and optional props to build the full prop interface
export interface PlanDetailsLabelProps
  extends PlanDetailsLabelRequiredProps,
    PlanDetailsLabelOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: PlanDetailsLabelOptionalProps = {
  "data-testid": "space-configurator-planDetailsLabel",
};

const PlanDetailsLabel: React.FC<PlanDetailsLabelProps> = ({
  className,
  room,
  count,
  spaceName,
  complex,
  ...rest
}) => {
  const classList = classNames(
    "space-configurator-plan-details-label",
    className,
  );
  const { t } = useTranslation();

  return (
    <div className={classList} {...rest}>
      <Trans
        i18nKey={t(
          [
            `${complex}.spaces-details.${spaceName}.${room}`,
            `spaces-details.${spaceName}.${room}`,
            `spaces-details.${room}`,
            ``,
          ],
          {
            count,
          },
        )}
      />
    </div>
  );
};

PlanDetailsLabel.defaultProps =
  defaultProps as Partial<PlanDetailsLabelOptionalProps>;

export default React.memo(PlanDetailsLabel);
