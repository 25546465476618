import { createAction } from "@reduxjs/toolkit";
import type { UserSelectionsParams } from "steps/selectors";

const MOOD_STEP_TYPE = "moodStep";

export const moodStepActions = {
  initConfig: createAction<UserSelectionsParams>(
    `${MOOD_STEP_TYPE}/initConfigRequest`,
  ),
  updateMood: createAction<MoodName>(`${MOOD_STEP_TYPE}/updateMoodRequest`),
  updateUnconfirmedMood: createAction<MoodName>(
    `${MOOD_STEP_TYPE}/updateUnconfirmedMoodRequest`,
  ),
  setIsStepLoading: createAction<boolean>(
    `${MOOD_STEP_TYPE}/setIsStepLoadingRequest`,
  ),
};
