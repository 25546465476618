import React from "react";
import { useSelector } from "react-redux";
import { DefaultProps } from "utils/types/defaultProps";
import { useStoreRepositories } from "hooks/storeRepositories/useStoreRepositories";
import { useStepRegistry } from "steps/hooks/useStepRegistry";
import { StepsEntity } from "store/domain/entities/steps.entity";
import { Carousel } from "components/Carousel";
import { EntityId } from "@reduxjs/toolkit";
import { useDevice } from "hooks/device/device";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { useCarouselImages } from "steps/hooks/useCarouselImages";

// styles
import "./pageCentralContent.scss";
import { selectTotalSteps } from "store/domain/slices/steps.slice";

// Required Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PageContentBodyRequiredProps {
  // TODO: Non vorrei passare queste info, gestire su store applicazione
  isZoomed: boolean;
  isShrinked: boolean;
  setIsZoomed: (z: boolean) => void;
  stepId: EntityId;
  complex?: string;
  zone?: string;
}

// Optional Props
type PageContentBodyOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface PageContentBodyProps
  extends PageContentBodyRequiredProps,
    PageContentBodyOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: PageContentBodyOptionalProps = {
  "data-testid": "page-content-body",
};

const PageContentBody: React.FC<PageContentBodyProps> = ({
  stepId,
  isZoomed,
  isShrinked,
  setIsZoomed,
  complex,
  zone,
}) => {
  const { StepsEntityRepository } = useStoreRepositories();
  const device = useDevice();

  const step = StepsEntityRepository?.selectById(stepId as string);

  const {
    StepPageContentRenderer,
    stepConfig,
    userSelection,
    unconfirmedUserSelection,
    handleUserSelectionChange,
    handleUnconfirmedUserSelectionChange,
    mobileStepPageContentCarouselImages,
  } = useStepRegistry(step as unknown as StepsEntity);

  const carouselImages = useCarouselImages();

  const farthermostStepIndex = useSelector(
    applicationStateSelectors.selectFarthestStepIndex,
  );
  const isFarthestStepIndex = step?.getOrder() === farthermostStepIndex;
  const totalSteps = useSelector(selectTotalSteps);

  const currentUserSelection =
    step?.getOrder() === totalSteps || isFarthestStepIndex
      ? userSelection
      : unconfirmedUserSelection;

  const handleStepUserSelectionChange =
    step?.getOrder() === totalSteps || isFarthestStepIndex
      ? handleUserSelectionChange
      : handleUnconfirmedUserSelectionChange;

  if (device.type === "mobile" && mobileStepPageContentCarouselImages) {
    return (
      <Carousel
        isZoomed={isZoomed}
        isShrinked={isShrinked}
        setIsZoomed={() => setIsZoomed(!isZoomed)}
        images={mobileStepPageContentCarouselImages}
      />
    );
  }

  if (StepPageContentRenderer && stepConfig) {
    return (
      <StepPageContentRenderer
        stepConfig={stepConfig}
        userSelection={currentUserSelection}
        onUserSelectionChange={handleStepUserSelectionChange}
        setZoomed={setIsZoomed}
        isZoomed={isZoomed}
        complex={complex}
        zone={zone}
      />
    );
  }

  if (carouselImages?.length) {
    return (
      <Carousel
        isZoomed={isZoomed}
        isShrinked={isShrinked}
        setIsZoomed={() => setIsZoomed(!isZoomed)}
        images={carouselImages}
      />
    );
  }

  return null;
};

PageContentBody.defaultProps =
  defaultProps as Partial<PageContentBodyOptionalProps>;

export default React.memo(PageContentBody);
