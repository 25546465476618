import React from "react";
import { useTranslation } from "@tecma/i18n";

import type { DefaultProps } from "utils/types/defaultProps";
import type { PriceDetailsTransformed } from "steps/types/step";
import { currencyFormatter } from "utils/functions/formatters";
import "./price-detail-item.scss";

interface PriceDetailItemRequiredProps {
  priceDetail: PriceDetailsTransformed;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PriceDetailItemOptionalProps extends DefaultProps {}

export interface PriceDetailItemProps
  extends PriceDetailItemRequiredProps,
    PriceDetailItemOptionalProps {}

const defaultProps: PriceDetailItemOptionalProps = {
  "data-testid": "space-configurator-recapFooter",
};
const PriceDetailItem: React.FC<PriceDetailItemProps> = ({ priceDetail }) => {
  const { t } = useTranslation();

  return (
    <div key={priceDetail.name} className='price-detail-item'>
      <p className='price-detail-item__name'>
        {t(`recap.prices-details.${priceDetail.name}`, {
          defaultValue: priceDetail.name,
        })}
      </p>
      <p className='price-detail-item__price'>
        {priceDetail.excluded
          ? t("recap.prices-details.excluded")
          : currencyFormatter(Math.round(priceDetail.amount))}
      </p>
    </div>
  );
};

PriceDetailItem.defaultProps =
  defaultProps as Partial<PriceDetailItemOptionalProps>;

export default PriceDetailItem;
