const uppercaseFormatter = (sentence: string) => {
  const sentenceFormatted = sentence.split(" ").map((word) => (
    <div
      key={word}
      className={`${
        word === word.toUpperCase() ? `am-subtile-bold` : `am-subtile-normal`
      }`}
    >
      {`${word} `}
    </div>
  ));

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{sentenceFormatted}</>;
};

export default uppercaseFormatter;
