import { PriceDetails } from "steps/types/step";

export const getExtraSpaceOptions = (
  spacesAvailable: number,
  spacesIncluded: number,
): { value: number; label: string }[] => {
  if (spacesAvailable < 0) return [];

  return new Array(spacesAvailable + 1).fill(null).map((_, index) => {
    const optionValue = index + (spacesIncluded > 0 ? spacesIncluded : 0);
    return { value: optionValue, label: optionValue.toString() };
  });
};

export const calculateSpecificPriceDetails = (
  curPriceDetails: PriceDetails,
  extraPriceDetails: PriceDetails,
): PriceDetails => {
  if (Object.keys(extraPriceDetails).length) {
    const newPriceDetailsCombined: PriceDetails = curPriceDetails;
    Object.keys(extraPriceDetails)
      .filter((key) => key !== "__typename")
      .forEach((key) => {
        if (newPriceDetailsCombined[key]) {
          if (extraPriceDetails[key] && !extraPriceDetails[key].excluded) {
            newPriceDetailsCombined[key] = {
              ...newPriceDetailsCombined[key],
              amount:
                newPriceDetailsCombined[key].amount +
                  extraPriceDetails[key].amount || 0,
            };
          }
        } else {
          newPriceDetailsCombined[key] = extraPriceDetails[key];
        }
      });
    return newPriceDetailsCombined;
  }
  return curPriceDetails;
};
