import React from "react";
import { Icon } from "@tecma/ui";
import { Trans, useTranslation } from "@tecma/i18n";
import classNames from "classnames";

import { useDevice } from "hooks/device/device";
import { PriceDetails } from "components/RecapSection/components/PriceDetails";
import type { DefaultProps } from "utils/types/defaultProps";
import { getPriceDetailsViewMode } from "utils/functions/prices";
import type { PriceDetailsTransformed } from "steps/types/step";
import "./recap-header.scss";

interface RecapHeaderRequiredProps {
  isOpen: boolean;
  totalPrice: number;
  priceDetails: PriceDetailsTransformed[];
  onToggle: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RecapHeaderOptionalProps extends DefaultProps {}

export interface RecapHeaderProps
  extends RecapHeaderRequiredProps,
    RecapHeaderOptionalProps {}

const defaultProps: RecapHeaderOptionalProps = {
  "data-testid": "space-configurator-recapHeader",
};

const RecapHeader: React.FC<RecapHeaderProps> = ({
  isOpen,
  totalPrice,
  priceDetails,
  onToggle,
}) => {
  const { t, i18n } = useTranslation();
  const { type, typeDetailed } = useDevice();
  const recapHeaderClasses = classNames("recap-header", {
    isOpen,
    hasPriceDetails: !!priceDetails.length,
  });
  const recapHeaderButtonClasses = classNames("recap-header__button", {
    isOpen,
  });
  const priceFormatted = new Intl.NumberFormat(i18n.language).format(
    totalPrice,
  );

  const getRecapHeaderContent = () => {
    if (["mobile", "tablet"].includes(type) || typeDetailed === "desktop_s") {
      return (
        <Trans
          i18nKey='recap.open-text-tablet'
          components={{ b: <strong />, span: <span /> }}
          values={{
            price: priceFormatted,
            currency: "€",
            extraContent:
              getPriceDetailsViewMode(priceDetails) === "sentence" ? "*" : "",
          }}
        />
      );
    }
    return null;
  };

  return (
    <div className={recapHeaderClasses}>
      <button
        type='button'
        className={recapHeaderButtonClasses}
        onClick={() => onToggle()}
      >
        <p>
          {type === "desktop" &&
            typeDetailed !== "desktop_s" &&
            t("recap.open-text")}
          {getRecapHeaderContent()}
        </p>
        {(type === "desktop" || type === "mobile") && (
          <Icon iconName='arrow-down' size='medium' />
        )}
      </button>
      {!["desktop_m", "desktop_l"].includes(typeDetailed) &&
        !!priceDetails.length && <PriceDetails prices={priceDetails} />}
    </div>
  );
};
RecapHeader.defaultProps = defaultProps as Partial<RecapHeaderOptionalProps>;
export default React.memo(RecapHeader);
