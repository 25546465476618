/**
 * Generates a MongoDB like ID , mongoose.Types.ObjectId.isValid -> true
 * @returns Generated ID
 */
export const generateID = (
  m = Math,
  d = Date,
  h = 16,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  s = (s: number) => m.floor(s).toString(h),
) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));
