import type { PriceDetails, StepSelectors } from "steps/types/step";
import type { RootState } from "store";
import type { MoodStepModule } from "..";

const selectConfigState = (state: RootState) =>
  state.moodStep?.config || undefined;

const selectSelectedMoodState = (state: RootState) =>
  state.moodStep?.selectedMood || undefined;

const selectUnconfirmedMoodState = (state: RootState) =>
  state.moodStep?.unconfirmedMood || undefined;

const selectSelectedMoodDetailState = (state: RootState) => {
  if (state.moodStep?.selectedMood) {
    return state.moodStep.config?.options?.find(
      (mood) => mood.name === state.moodStep?.selectedMood,
    );
  }
  return undefined;
};

const selectSelectedMoodToDBState = (
  state: RootState,
): MoodSelection | undefined => {
  if (state.moodStep?.selectedMood) {
    return { mood: state.moodStep.selectedMood };
  }
  return undefined;
};

const selectSelectedMoodPriceState = (state: RootState): number => {
  if (state.moodStep?.selectedMood) {
    return (
      state.moodStep.config?.options?.find(
        (mood) => mood.name === state.moodStep?.selectedMood,
      )?.price || 0
    );
  }
  return 0;
};

const selectSelectedMoodPriceDetailsState = (
  state: RootState,
): PriceDetails => {
  if (state.moodStep?.selectedMood) {
    return (
      state.moodStep.config?.options?.find(
        (mood) => mood.name === state.moodStep?.selectedMood,
      )?.priceDetails || {}
    );
  }
  return {};
};

export const moodStepSelectors: StepSelectors<MoodStepModule> = {
  selectConfig: selectConfigState,
  selectUserSelection: selectSelectedMoodState,
  selectUnconfirmedUserSelection: selectUnconfirmedMoodState,
  selectUserSelectionDetail: selectSelectedMoodDetailState,
  selectUserSelectionPrice: selectSelectedMoodPriceState,
  selectUserSelectionPriceDetails: selectSelectedMoodPriceDetailsState,
  stepPageContentCarouselImagesSelector: (state) => {
    const selectedMood = selectSelectedMoodState(state);
    const unconfirmedMood = selectUnconfirmedMoodState(state);
    return state.moodStep.config?.options?.find(
      (mood) => mood.name === (unconfirmedMood || selectedMood),
    )?.carouselImages;
  },
  areOptionsAvailableSelector: (state) =>
    Number(state.moodStep.config?.options.length) > 0,
  isStepLoading: (state) => state.moodStep.isLoading,
};
