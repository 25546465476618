import { logger } from "@tecma/logs-fe";
import Client from "client/Client";
import { graphqlClient } from "client/GraphqlClient";
import { UserSelectionInput } from "client/modules/Step";

export const getPlansByUserSelection = async (
  projectId: string,
  input: UserSelectionInput,
  client = graphqlClient,
) => {
  try {
    const userInfo = await client.query({
      query: Client.GET_PLANS_BY_USER_SELECTION,
      ...Client.GET_SPACES_BY_USER_SELECTION(projectId, input),
    });
    return userInfo.data?.getPlansByUserSelection;
  } catch (err) {
    logger.error(err);
    throw Error();
  }
};
