import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Drawer, Portal } from "@tecma/ui";
import classNames from "classnames";
import Cookies from "js-cookie";

import { useDevice } from "hooks/device/device";
import { DefaultProps } from "utils/types/defaultProps";
import { authActions } from "store/sagas/auth/auth.actions";
import {
  JWT_RENT_COOKIE,
  selectCurrentUser,
  selectImpersonatedUser,
} from "store/slices/auth/slice";
import { HeaderMenu } from "./components/HeaderMenu";
import { DrawerFooter } from "./components/DrawerFooter";

// styles
import "./header.scss";

// Required Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HeaderRequiredProps {
  projectInfo: ProjectInfo;
}

// Optional Props
type HeaderOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface HeaderProps extends HeaderRequiredProps, HeaderOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: HeaderOptionalProps = {
  "data-testid": "space-configurator-header",
};

const Header: React.FC<HeaderProps> = ({ className, projectInfo, ...rest }) => {
  const classList = classNames("space-configurator-header", className);
  const device = useDevice();
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const isLogged = Boolean(user && Cookies.get(JWT_RENT_COOKIE));
  const impersonatedUser = useSelector(selectImpersonatedUser);

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState<boolean>(false);

  const headerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleMobileLogout = () => {
    setMobileMenuIsOpen(false);
    dispatch(authActions.doLogout());
  };

  return (
    <div className={classList} {...rest} ref={headerRef}>
      <div className='header-left-side'>
        <a
          href={projectInfo.config.homePageUrl}
          target='_blank'
          rel='noreferrer'
        >
          <img
            src={projectInfo.logo.full}
            alt={projectInfo.projectName}
            className='header-logo'
          />
        </a>
      </div>
      <div className='header-right-side'>
        {device.type === "mobile" ? (
          <>
            <Button
              iconName='hamburger'
              onClick={() => setMobileMenuIsOpen(true)}
            />
            <Portal id='drawer'>
              <Drawer
                width={16}
                isOpen={mobileMenuIsOpen}
                onClose={() => setMobileMenuIsOpen(false)}
                className='header-drawer'
              >
                <Button
                  iconName='cross'
                  onClick={() => setMobileMenuIsOpen(false)}
                  className='close-drawer'
                />
                <HeaderMenu
                  projectInfo={projectInfo}
                  headerRef={headerRef}
                  user={user}
                  isLogged={isLogged}
                  setMobileMenuIsOpen={setMobileMenuIsOpen}
                  isMobile
                />
                {isLogged && (
                  <DrawerFooter
                    handleMobileLogout={handleMobileLogout}
                    user={user}
                    impersonatedUser={impersonatedUser}
                  />
                )}
              </Drawer>
            </Portal>
          </>
        ) : (
          <HeaderMenu
            projectInfo={projectInfo}
            headerRef={headerRef}
            user={user}
            isLogged={isLogged}
          />
        )}
      </div>
    </div>
  );
};

Header.defaultProps = defaultProps as Partial<HeaderOptionalProps>;

export default React.memo(Header);
