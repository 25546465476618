import type { RootState } from "store";
import { calculateSpecificPriceDetails } from "utils/functions/extraSpace";
import type { PackStepModule } from "..";
import type { PriceDetails, StepSelectors } from "../../types/step";

const selectConfigState = (state: RootState) => {
  if (state.packStep?.config.options) {
    const orderedStepConfig = state.packStep?.config.options
      .map((el) => structuredClone(el))
      .sort((a, b) => Number(a.included) - Number(b.included));
    return orderedStepConfig;
  }
  return undefined;
};

const selectSelectedPacks = (state: RootState) => {
  return state.packStep?.selectedPacks;
};

const selectUnconfirmedPacks = (state: RootState) => {
  return state.packStep?.unconfirmedPacks;
};

const selectSelectedPacksDetails = (state: RootState) => {
  const config = selectConfigState(state);
  if (config) {
    return config.filter((pack) =>
      state.packStep?.selectedPacks?.includes(pack.name),
    );
  }
  return [];
};

const selectSelectedPacksPrice = (state: RootState) => {
  const totalPrice = selectConfigState(state)?.reduce((total, pack) => {
    if (!pack.included && state.packStep?.selectedPacks?.includes(pack.name)) {
      return total + pack.startingPrice;
    }
    return total;
  }, 0);
  return totalPrice || 0;
};

const isUserSelectionModified = (state: RootState) => {
  const oldSelection = state.packStep?.selectedPacks;
  const newSelection = state.packStep?.unconfirmedPacks;

  const sortedOldSelection = [...(oldSelection || [])].sort();
  const sortedNewSelection = [...(newSelection || [])].sort();

  if (sortedOldSelection.length !== sortedNewSelection.length) {
    return true;
  }
  return !sortedNewSelection.every(
    (item, index) => sortedOldSelection[index] === item,
  );
};

const selectSelectedPacksPriceDetails = (state: RootState): PriceDetails => {
  const totalPriceDetails = selectConfigState(state)?.reduce(
    (totalPriceDetailsPacks, currentPack) => {
      if (
        !currentPack.included &&
        state.packStep?.selectedPacks?.includes(currentPack.name)
      ) {
        return calculateSpecificPriceDetails(
          totalPriceDetailsPacks,
          currentPack.priceDetails,
        );
      }
      return totalPriceDetailsPacks;
    },
    {},
  );
  return totalPriceDetails || {};
};

export const packStepSelectors: StepSelectors<PackStepModule> = {
  selectConfig: selectConfigState,
  selectUserSelection: selectSelectedPacks,
  selectUnconfirmedUserSelection: selectUnconfirmedPacks,
  selectUserSelectionDetail: selectSelectedPacksDetails,
  selectUserSelectionPrice: selectSelectedPacksPrice,
  selectUserSelectionPriceDetails: selectSelectedPacksPriceDetails,
  isUserSelectionModified,
  stepPageContentCarouselImagesSelector: (state) =>
    state.packStep.config.carouselImages,
  areOptionsAvailableSelector: (state) =>
    Number(state.packStep.config?.options?.length) > 0,
  isStepLoading: (state) => state.packStep.isLoading,
};
