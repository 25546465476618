import type { PriceDetails, StepSelectors } from "steps/types/step";
import type { RootState } from "store";
import type { FloorStepModule } from "..";

const selectConfigState = (state: RootState) =>
  state.floorStep?.config || undefined;

const selectSelectedFloorState = (state: RootState) =>
  state.floorStep?.selectedFloor == null
    ? undefined
    : state.floorStep?.selectedFloor;

const selectUnconfirmedFloorState = (state: RootState) =>
  state.floorStep?.unconfirmedFloor == null
    ? undefined
    : state.floorStep?.unconfirmedFloor;

const selectSelectedFloorDetailState = (
  state: RootState,
): Floor | undefined => {
  if (state.floorStep?.selectedFloor != null) {
    return state.floorStep?.config?.options.find(
      (floor) => floor.number === state.floorStep?.selectedFloor,
    );
  }
  return undefined;
};

const selectSelectedFloorToDBState = (
  state: RootState,
): MoodSelection | undefined => {
  if (state.floorStep?.selectedFloor) {
    return { floor: state.floorStep.selectedFloor };
  }
  return undefined;
};

const selectSelectedFloorPriceState = (state: RootState): number => {
  if (state.floorStep?.selectedFloor != null) {
    return (
      state.floorStep.config?.options.find(
        (floor) => floor.number === state.floorStep?.selectedFloor,
      )?.price || 0
    );
  }
  return 0;
};

const selectSelectedFloorPriceDetailsState = (
  state: RootState,
): PriceDetails => {
  if (state.floorStep?.selectedFloor != null) {
    return (
      state.floorStep.config?.options.find(
        (floor) => floor.number === state.floorStep?.selectedFloor,
      )?.priceDetails || {}
    );
  }
  return {};
};

const selectSelectedFloorFirsAvailabilityState = (
  state: RootState,
): string | undefined => {
  const floorsConfig = state.floorStep.config;
  const floorSelected = floorsConfig?.options.find(
    (floor) => floor.number === state.floorStep.selectedFloor,
  );
  return floorSelected?.availability?.firstEntryAvailable?.startDate;
};

export const floorStepSelectors: StepSelectors<FloorStepModule> = {
  selectConfig: selectConfigState,
  selectUserSelection: selectSelectedFloorState,
  selectUnconfirmedUserSelection: selectUnconfirmedFloorState,
  selectUserSelectionDetail: selectSelectedFloorDetailState,
  selectUserSelectionPrice: selectSelectedFloorPriceState,
  selectUserSelectionPriceDetails: selectSelectedFloorPriceDetailsState,
  selectUserSelectionFirstDate: selectSelectedFloorFirsAvailabilityState,
  stepPageContentCarouselImagesSelector: (state) => {
    const selectedFloor = selectSelectedFloorState(state);
    const unconfirmedFloor = selectUnconfirmedFloorState(state);
    const floorToCheck =
      unconfirmedFloor != null ? unconfirmedFloor : selectedFloor;
    return state.floorStep?.config?.options.find(
      (floor) => floor.number === floorToCheck,
    )?.carouselImages;
  },
  areOptionsAvailableSelector: (state) =>
    Number(state.floorStep.config?.options.length) > 0,
  isStepLoading: (state) => state.floorStep.isLoading,
};
