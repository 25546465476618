import { createAction } from "@reduxjs/toolkit";
import {
  UserInteractionPayload,
  UserInteractionResetPayload,
} from "../../slices/userInteractions/slice";

export const userInteractionSagaActions = {
  appendedInteraction: createAction<UserInteractionPayload>(
    "userInteractions/appended",
  ),
  removedInteraction: createAction<UserInteractionResetPayload>(
    "userInteractions/removed",
  ),
  interactionsReset: createAction<UserInteractionResetPayload>(
    "userInteractions/reset",
  ),
};
