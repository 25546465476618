import { UserSelectionsFilters } from "client/modules/Step";
import type { UserSelections } from "./selectors";

export interface UserSelectionsDTO extends UserSelectionsFilters {
  complex: string;
  checkInDate: string;
  checkOutDate: string;
  clientId?: string;
  price?: number;
}
export interface UserSelectionsData {
  userSelections: UserSelections;
  checkInDate: string;
  checkOutDate: string;
  complex: string;
  clientId?: string;
}

export interface CreateRequestInput {
  variables: {
    project_id: string;
    input: Omit<UserSelectionsDTO, "complex">;
  };
  fetchPolicy: "no-cache";
}

export const userSelectionsToDTO = ({
  userSelections,
  checkInDate,
  checkOutDate,
  complex,
  clientId,
}: UserSelectionsData): UserSelectionsDTO => ({
  ...(userSelections.MOOD_STEP && { mood: userSelections.MOOD_STEP }),
  ...(userSelections.PLANIMETRY_STEP && {
    plan: userSelections.PLANIMETRY_STEP.name,
    apartment: userSelections.PLANIMETRY_STEP.apartment.id,
  }),
  ...(userSelections.FLOOR_STEP !== undefined && {
    floor: userSelections.FLOOR_STEP,
  }),
  ...(userSelections.EXTRA_SPACE_STEP && {
    extraSpaces: Object.entries(userSelections.EXTRA_SPACE_STEP).map(
      ([extraSpaceId, extraSpaceNumber]) => ({
        type: extraSpaceId,
        quantity: extraSpaceNumber,
      }),
    ),
  }),
  ...(userSelections.PACK_STEP && {
    packs: userSelections.PACK_STEP,
  }),
  ...(userSelections.SPACE_TYPE_STEP && {
    spaceType: userSelections.SPACE_TYPE_STEP,
  }),
  complex: userSelections.COMPLEX_STEP || complex,
  checkInDate,
  checkOutDate,
  clientId,
});
