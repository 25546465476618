import ComplexStep from "./component/ComplexStep";
import { ComplexRecap } from "./component/ComplexRecap";
import { complexStepActions } from "./sagas/actions";
import { complexStepRootInitSaga } from "./sagas/saga";
import { complexStepSelectors } from "./state/selectors";
import { complexStepSlice } from "./state/slice";
import type { StepModule } from "../types/step";
import { ComplexModal } from "./component/ComplexModal";

export type ComplexStepModule = StepModule<
  ComplexStepSlice,
  ComplexStepConfig,
  ComplexName,
  Complex
>;

export const module: ComplexStepModule = {
  type: "COMPLEX_STEP",
  name: "complex-picker",
  icon: "buildings",
  priceCalculationMode: "REPLACEMENT",
  stepRenderer: ComplexStep,
  recapStepRender: ComplexRecap,
  firstDateAvailableSelector: complexStepSelectors.selectUserSelectionFirstDate,
  configSelector: complexStepSelectors.selectConfig,
  userSelectionSelector: complexStepSelectors.selectUserSelection,
  unconfirmedUserSelectionSelector:
    complexStepSelectors.selectUnconfirmedUserSelection,
  userSelectionDetailSelector: complexStepSelectors.selectUserSelectionDetail,
  userSelectionPriceSelector: complexStepSelectors.selectUserSelectionPrice,
  userSelectionPriceDetailsSelector:
    complexStepSelectors.selectUserSelectionPriceDetails,
  updateUserSelectionAction:
    complexStepSlice.stepConfigActions.setSelectedComplex,
  updateUnconfirmedUserSelectionAction:
    complexStepSlice.stepConfigActions.setUnconfirmedComplex,
  resetUserSelectionAction:
    complexStepSlice.stepConfigActions.resetSelectedComplex,
  stepReducer: complexStepSlice.reducer,
  initConfigAction: complexStepActions.initConfig,
  updateConfigAction: complexStepActions.updateConfig,
  checkDataChangesAction: complexStepActions.checkDataChanges,
  initSaga: complexStepRootInitSaga,
  stepPageContentCarouselImagesSelector:
    complexStepSelectors.stepPageContentCarouselImagesSelector,
  areOptionsAvailableSelector: complexStepSelectors.areOptionsAvailableSelector,
  setStepLoading: complexStepSlice.stepConfigActions.setIsStepLoading,
  isStepLoading: complexStepSelectors.isStepLoading,
  mobileModalRenderer: ComplexModal,
  mobileModalKey: "complex",
};
