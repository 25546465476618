import { useTranslation, Trans } from "@tecma/i18n";
import React, { FC } from "react";
import { getCleanName } from "utils/functions/formatters";

import "./single-pack.scss";

export interface SinglePackProps {
  name: string;
  startingPrice?: number;
  included?: boolean;
  buildingName?: string;
}

const SinglePack: FC<SinglePackProps> = ({
  name,
  startingPrice,
  included,
  buildingName,
}) => {
  const { t } = useTranslation();

  return (
    <div className='single-pack'>
      <p className='single-pack-name'>
        <Trans
          i18nKey={t([
            `${buildingName}.pack-picker.pack.${name}.label`,
            `pack-picker.pack.${name}.label`,
            `${getCleanName(name)} pack`,
          ])}
          components={{ 1: <span /> }}
          l
        />
      </p>
      {included && (
        <p className='single-pack-included'>{t("pack-picker.included")}</p>
      )}
      {startingPrice !== null && startingPrice !== undefined && !included && (
        <p className='single-pack-starting-price'>
          <Trans
            i18nKey='pack-picker.starting-price'
            components={{ 1: <span /> }}
            values={{ price: Math.round(startingPrice), currency: "€" }}
          />
        </p>
      )}
    </div>
  );
};

export default SinglePack;
