import { gql } from "@apollo/client";

export type UserSelectionsFilters = {
  spaceTypology?: string;
  floorNumber?: number;
  mood?: string;
  plan?: string;
  apartment?: string;
  packs?: string[];
  extraSpaces?: { type: string; quantity: number }[];
};

export type UserSelectionInput = {
  checkInDate: string;
  checkOutDate: string;
  complex: string;
} & UserSelectionsFilters;

const MOODS_BY_USER_SELECTION = `
  options {
    name
    image
    price
    priceDetails {
      building{
        show
        excluded
        amount
      }
      taxesVAT{
        show
        excluded
        amount
      }
    }
    default
    available
    carouselImages
  }
`;

const SPACE_TYPES_BY_USER_SELECTION = `
  options {
    spaceTypeName
    spaceTypeStartingPrice
    priceDetails {
      building{
        show
        excluded
        amount
      }
      taxesVAT{
        show
        excluded
        amount
      }
    }
    availability {
      count
      firstEntryAvailable {
        startDate
        endDate
      }
    }
    carouselImages
    mainFeatures {
      rooms
      bedroom
      bathroom
      openPlanKitchen
    }
  }
`;

const FLOORS_BY_USER_SELECTION = `
  buildingFloorsNumber
  options {
    number
    price
    priceDetails {
      building{
        show
        excluded
        amount
      }
      taxesVAT{
        show
        excluded
        amount
      }
    }
    default
    availability {
      count
      firstEntryAvailable {
        startDate
        endDate
      }
    }
    carouselImages
  }
`;

const PACKS_BY_USER_SELECTION = `
  options {
    name
    description
    startingPrice
    priceDetails {
      building{
        show
        excluded
        amount
      }
      taxesVAT{
        show
        excluded
        amount
      }
    }
    image
    included
    details
  }
  carouselImages
`;

const PLANS_BY_USER_SELECTION = `
  options {
    name
    type
    price
    priceDetails {
      building{
        show
        excluded
        amount
      }
      taxesVAT{
        show
        excluded
        amount
      }
    }
    default
    available
    image
    address
    apartment {
      id
      code
      name
    }
    mainFeatures {
      rooms
      bedroom
      bathroom
      openPlanKitchen
    }
  }
`;

const EXTRA_SPACES_BY_USER_SELECTION = `
  options{
    type
    spaces {
      id
      name
      prices {
        price
        priceDetails {
          building{
            show
            excluded
            amount
          }
          taxesVAT{
            show
            excluded
            amount
          }
        }
      } 
      default
      availability
      included
    }
  }
  carouselImages
`;

const COMPLEX_BY_USER_SELECTION = `
  options{
    name
    zone
    price
    priceDetails {
      building {
        amount
        show
        excluded
      }
      taxesVAT{
        show
        excluded
        amount
      }
    }
    default
    availability {
      count
      firstEntryAvailable {
        startDate
        endDate
      }
    }
    image
    carouselImages
  }
`;

// TODO: Better name
export const GET_SPACES_BY_USER_SELECTION = (
  project_id: string,
  input: UserSelectionInput,
) => {
  return {
    variables: {
      project_id,
      input,
    },
  };
};

export const GET_MOODS_BY_USER_SELECTION = gql`
  query getMoodsByUserSelection($project_id: ID!, $input: UserSelectionInput!) {
    getMoodsByUserSelection(project_id: $project_id, input: $input) {
      ${MOODS_BY_USER_SELECTION}
      }
  }
`;

export const GET_SPACE_TYPES_BY_USER_SELECTION = gql`
  query getSpaceTypesByUserSelection($project_id :ID!, $input: UserSelectionInput!) {
    getSpaceTypesByUserSelection(project_id: $project_id, input: $input) {
      ${SPACE_TYPES_BY_USER_SELECTION}
      }
  }
`;

export const GET_PACKS_BY_USER_SELECTION = gql`
  query getPacksByUserSelection($project_id :ID!, $input: UserSelectionInput!) {
    getPacksByUserSelection(project_id: $project_id, input: $input) {
      ${PACKS_BY_USER_SELECTION}
      }
  }
`;

export const GET_FLOORS_BY_USER_SELECTION = gql`
  query getFloorsByUserSelection($project_id :ID!, $input: UserSelectionInput!) {
    getFloorsByUserSelection(project_id: $project_id, input: $input) {
      ${FLOORS_BY_USER_SELECTION}
      }
  }
`;

export const GET_PLANS_BY_USER_SELECTION = gql`
  query getPlansByUserSelection($project_id :ID!, $input: UserSelectionInput!) {
    getPlansByUserSelection(project_id: $project_id, input: $input) {
      ${PLANS_BY_USER_SELECTION}
      }
  }
`;

export const GET_EXTRA_SPACES_BY_USER_SELECTION = gql`
  query getExtraSpacesByUserSelection($project_id :ID!, $input: UserSelectionInput!) {
    getExtraSpacesByUserSelection(project_id: $project_id, input: $input) {
      ${EXTRA_SPACES_BY_USER_SELECTION}
    }
  }
`;

export const GET_COMPLEX_BY_USER_SELECTION = gql`
  query getComplexesByUserSelection($project_id :ID!, $input: UserSelectionInput!) {
    getComplexesByUserSelection(project_id: $project_id, input: $input) {
      ${COMPLEX_BY_USER_SELECTION}
    }
  }
`;
