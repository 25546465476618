import { useSelector } from "react-redux";
import { getDeviceState } from "store/slices/deviceState/slice";

type Device = "mobile" | "tablet" | "desktop";
type DeviceDetailed =
  | "mobile"
  | "tablet"
  | "desktop_s"
  | "desktop_m"
  | "desktop_l";

type Breakpoints = {
  [key in Device]?: number;
};

type BreakpointsDetailed = {
  [key in DeviceDetailed]?: number;
};

const BREAKPOINTS: Breakpoints = {
  mobile: 768,
  tablet: 1024,
};

const BREAKPOINTS_DETAILED: BreakpointsDetailed = {
  mobile: 768,
  tablet: 1024,
  desktop_s: 1440,
  desktop_m: 1920,
};

export const useDevice = () => {
  const deviceState = useSelector(getDeviceState);
  const deviceWidth = deviceState.width;

  const deviceDetailed =
    Object.keys(BREAKPOINTS_DETAILED).find(
      (key) =>
        deviceWidth <=
        (BREAKPOINTS_DETAILED
          ? (BREAKPOINTS_DETAILED[
              key as unknown as DeviceDetailed
            ] as unknown as number)
          : 0),
    ) || "desktop_l";

  const device =
    Object.keys(BREAKPOINTS).find(
      (key) =>
        deviceWidth <=
        (BREAKPOINTS
          ? (BREAKPOINTS[key as unknown as Device] as unknown as number)
          : 0),
    ) || "desktop";

  return {
    type: device as Device,
    typeDetailed: deviceDetailed as DeviceDetailed,
    width: deviceWidth,
  };
};
