import i18n, { initI18n, loadResources } from "@tecma/i18n";
import { all, call, put, select, take } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { getCorrectDatesForConstraints } from "@tecma/date-picker-utils";

import { loadScssFromDB } from "utils/functions/loadScssFromDB";
import { getDatesFromUrl } from "utils/functions/calculateDates";
import { DateTime } from "luxon";
import { getLanguageFromUrl } from "utils/functions/calculateLanguage";
import { selectCurrentUserAndToken } from "store/slices/auth/slice";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { authActions } from "store/sagas/auth/auth.actions";
import { applicationStateActions } from "../../../slices/applicationState/slice";
import { stepsAdded } from "../../../domain/slices/steps.slice";
import {
  dateSelectorActionTypes,
  dateSelectorActions,
} from "../../availabilitySelector/availabilitySelector.actions";
import translation from "../../../../locales/en-GB/translation.json";
import {
  getInitialInfo,
  getSpaceConfiguratorApplicationInfo,
  getTokenValidation,
} from "./init.api";
import { applicationActions } from "../application.actions";
import { wait } from "../../utils/waiter";
import { applicationInitActions } from "./init.actions";

function* getSpaceConfiguratorApplicationInfoSaga() {
  const projectInfo: ProjectInfo = yield getSpaceConfiguratorApplicationInfo();
  return projectInfo;
}

function* getInitialInfoSaga(
  project_id: string,
  input: {
    checkInDate: string;
    checkOutDate: string;
    complex: string | null;
    zone: string | null;
  },
) {
  const initialInfo: InitialInfo = yield getInitialInfo(project_id, input);
  return initialInfo;
}

function* getTokenInfo(project_id: string, token: string) {
  const tokenInfo: TokenInfo = yield getTokenValidation(project_id, token);
  return tokenInfo;
}

function* initTecmaI18NSaga() {
  yield initI18n(
    translation,
    {
      bucketBase: `${process.env.REACT_APP_BUCKET_BASEURL}`,
      productName: `${process.env.REACT_APP_PRODUCT_NAME}`,
      apiBase: `${process.env.REACT_APP_API_URI}`,
      debug: false,
    },
    "en-GB",
  );
}

const removeParamFromUrl = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete(param);
  window.history.pushState(
    null,
    "",
    `${window.location.href.split("?")[0]}?${urlParams.toString()}`,
  );
};

const updateParamInUrl = (param: string, newValue: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  const oldParam = `${param}=${urlParams.get(param)}`;
  const newParam = `${param}=${newValue}`;
  const updatedParams = urlParams.toString().replace(oldParam, newParam);
  window.history.pushState(
    null,
    "",
    `${window.location.href.split("?")[0]}?${updatedParams}`,
  );
};

const getInitialDates = (rentConstraints: RentConstraints) => {
  const { startDate, endDate } = getDatesFromUrl();
  const currentDate = DateTime.now().toISODate();
  const [validStartDate, validEndDate] = getCorrectDatesForConstraints(
    [startDate || currentDate, endDate || currentDate],
    rentConstraints,
    currentDate,
  );
  return {
    startDate: validStartDate,
    endDate: validEndDate,
    startDateWasValidated: Boolean(startDate) && validStartDate !== startDate,
    endDateWasValidated: Boolean(endDate) && validEndDate !== endDate,
  };
};

function* loadI18NResources(projectInfo: ProjectInfo) {
  yield loadResources({
    id: projectInfo.projectID,
    displayName: projectInfo.projectName,
    languages: projectInfo.languages,
  });
}

function* loadStyle(projectInfo: ProjectInfo) {
  yield loadScssFromDB(projectInfo);
}

export function* showAlertSoldOut(
  action: PayloadAction<{ isComplexSoldOut: boolean; isZoneSoldOut: boolean }>,
) {
  const { isZoneSoldOut } = action.payload;
  if (isZoneSoldOut) {
    removeParamFromUrl("zone");
  }
  yield put(
    applicationStateActions.openAlert({
      type: "warning",
      props: {
        confirmButtonText: "alert.reset.confirm-data-reset",
        content: "alert.reset.content-building-sold-out",
      },
    }),
  );
}

export function* showInvalidLinkAlert(
  action: PayloadAction<{ isComplexSoldOut: boolean; isZoneSoldOut: boolean }>,
): any {
  const { isZoneSoldOut } = action.payload;
  if (isZoneSoldOut) {
    removeParamFromUrl("zone");
  }
  yield put(
    applicationStateActions.openAlert({
      type: "warning",
      props: {
        confirmButtonText: "alert.reset.confirm-data-reset",
        content: "alert.reset.content-building-sold-out",
      },
    }),
  );
}

const invalidAlertActions = (
  isComplexSoldOut: boolean,
  isZoneSoldOut: boolean,
) => {
  return isComplexSoldOut || isZoneSoldOut
    ? {
        customCloseAlertAction:
          applicationInitActions.showAlertSoldOutRequested({
            isComplexSoldOut,
            isZoneSoldOut,
          }),
        confirmButtonAction: applicationInitActions.showAlertSoldOutRequested({
          isComplexSoldOut,
          isZoneSoldOut,
        }),
      }
    : {
        customCloseAlertAction: applicationActions.closeAlertRequested(),
        confirmButtonAction: applicationActions.closeAlertRequested(),
      };
};

function* showAlertIfDataNotValid(
  warnings: InitialInfoWarnings,
  startDate: string,
  endDate: string,
  startDateWasValidated: boolean,
  endDateWasValidated: boolean,
) {
  const { complexNotFound, zoneNotFound, complexSoldOut, zoneSoldOut } =
    warnings;
  if (
    startDateWasValidated ||
    endDateWasValidated ||
    complexNotFound ||
    zoneNotFound
  ) {
    if (startDateWasValidated) {
      updateParamInUrl("start", startDate);
    }
    if (endDateWasValidated) {
      updateParamInUrl("end", endDate);
    }
    if (zoneSoldOut) {
      removeParamFromUrl("zone");
    }
    yield put(
      applicationStateActions.openAlert({
        type: "warning",
        props: {
          confirmButtonText: "alert.reset.confirm-data-reset",
          content: "alert.reset.content-data-error",
          ...invalidAlertActions(complexSoldOut, zoneSoldOut),
        },
      }),
    );
  } else if (complexSoldOut || zoneSoldOut) {
    yield put(
      applicationInitActions.showAlertSoldOutRequested({
        isComplexSoldOut: complexSoldOut,
        isZoneSoldOut: zoneSoldOut,
      }),
    );
  }
}

export function* applicationInitSaga() {
  const initRet: [void, ProjectInfo] = yield all([
    put(applicationStateActions.startInit()),
    call(getSpaceConfiguratorApplicationInfoSaga),
  ]);
  const projectInfo: ProjectInfo = initRet[1];
  yield call(initTecmaI18NSaga);
  const urlLanguage = getLanguageFromUrl();
  if (urlLanguage) {
    i18n.changeLanguage(urlLanguage);
  }
  const steps: Step[] = cloneDeep(projectInfo.config.steps);
  const projectInfoSteps = steps
    .sort((stepA, stepB) => {
      if (stepA.order > stepB.order) return 1;
      if (stepA.order < stepB.order) return -1;
      return 0;
    })
    // emergency fix for missing steps order (to be removed)
    .map((step, index) => ({ ...step, order: index + 1 }));
  const { user, token } = yield select(selectCurrentUserAndToken);
  yield all([
    call(loadI18NResources, projectInfo),
    call(loadStyle, projectInfo),
    put(stepsAdded(projectInfoSteps)),
    put(
      applicationStateActions.setProjectInfo({
        ...projectInfo,
        config: { ...projectInfo.config },
      }),
    ),
    put(
      authActions.initAuth({
        projectId: projectInfo.projectID,
        token: token!,
        user: user!,
      }),
    ),
  ]);
  yield put(applicationStateActions.stopInit());
}

export function* bootstrapSpaceConfiguratorSaga() {
  yield put(applicationStateActions.startLoading());
  const projectInfo: ProjectInfo = yield select(
    applicationStateSelectors.selectProjectInfo,
  );
  const { startDate, endDate, startDateWasValidated, endDateWasValidated } =
    getInitialDates(projectInfo.config.rentConstraints);
  const urlParams = new URLSearchParams(window.location.search);
  const tokenParam: string | null = urlParams.get("token");
  const initialInfoSaga: InitialInfo = yield getInitialInfoSaga(
    projectInfo.projectID,
    {
      checkInDate: startDate,
      checkOutDate: endDate,
      complex: urlParams.get("complex"),
      zone: urlParams.get("zone"),
    },
  );
  const tokenInfo: TokenInfo = tokenParam
    ? yield getTokenInfo(projectInfo.projectID, tokenParam)
    : undefined;
  const { warnings, ...initialInfo } = initialInfoSaga;

  yield call(
    showAlertIfDataNotValid,
    warnings,
    startDate,
    endDate,
    startDateWasValidated,
    endDateWasValidated,
  );

  if (initialInfo.complex) {
    updateParamInUrl("complex", initialInfo.complex);
  }

  yield put(
    applicationStateActions.setProjectInfo({
      ...projectInfo,
      config: { ...projectInfo.config, ...initialInfo },
    }),
  );

  yield put(applicationStateActions.setToken(tokenInfo));

  yield put({
    type: dateSelectorActionTypes.datesInitRequested,
    payload: {
      startDate,
      endDate,
    },
  });

  yield take(dateSelectorActions.datesInitCompleted);

  yield put(applicationStateActions.stopLoading());
  yield wait(500);
  yield put(applicationActions.openNextStepRequested());
}
