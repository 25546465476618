import React from "react";
import classNames from "classnames";
import { DefaultProps } from "utils/types/defaultProps";
import { ErrorPage } from "@tecma/ui";
import { useTranslation } from "@tecma/i18n";
import { useSelector } from "react-redux";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";

// styles
import "./genericErrorPage.scss";

// Required Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GenericErrorPageRequiredProps {}

// Optional Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GenericErrorPageOptionalProps extends DefaultProps {}

// Combined required and optional props to build the full prop interface
// eslint-disable-next-line prettier/prettier
export interface GenericErrorPageProps
  extends GenericErrorPageRequiredProps,
    GenericErrorPageOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: GenericErrorPageOptionalProps = {
  "data-testid": "space-configurator-genericErrorPage",
};

const GenericErrorPage: React.FC<GenericErrorPageProps> = ({
  className,
  ...rest
}) => {
  const { t } = useTranslation();
  const projectInfo = useSelector(applicationStateSelectors.selectProjectInfo);
  const classList = classNames(
    "space-configurator-genericErrorPage",
    className,
  );
  return (
    <ErrorPage
      title={t(["error-page.title", "Something went wrong"])}
      message={t([
        "error-page.message",
        "We couldn't find the page you were looking for or there was an unexpected error.",
      ])}
      buttonLabel={t(["error-page.buttonLabel", "Back to Homepage"])}
      logo={projectInfo?.logo.base}
    />
  );
};

GenericErrorPage.defaultProps =
  defaultProps as Partial<GenericErrorPageOptionalProps>;

export default React.memo(GenericErrorPage);
