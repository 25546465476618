import React, { createRef, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Button, Icon, Carousel as TecmaCarousel } from "@tecma/ui";
import { Trans } from "@tecma/i18n";

import { useDevice } from "hooks/device/device";
import { DefaultProps } from "utils/types/defaultProps";
import CardItem, { CardItemProps } from "components/CardItem/CardItem";

// styles
import "./cardList.scss";

export interface CardsListOptions extends CardItemProps {
  availability?: string | JSX.Element;
}

// Required Props
interface CardListRequiredProps {
  usePagination: boolean;
  cardsOptions: CardsListOptions[];
}

// Optional Props
interface CardListOptionalProps extends DefaultProps {
  mode?: "scroll" | "wrap";
}

// Combined required and optional props to build the full prop interface
export interface CardListProps
  extends CardListRequiredProps,
    CardListOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: CardListOptionalProps = {
  "data-testid": "spaceConfigurator-cardList",
};

const CardList: React.FC<CardListProps> = ({
  className,
  usePagination,
  cardsOptions,
  mode,
  ...rest
}) => {
  const device = useDevice();
  const classList = classNames("space-configurator-card-list", className, {
    "use-pagination": usePagination && device.type === "desktop",
  });
  const classListMode = classNames("card-list-mode", {
    "horizontal-scroll": mode === "scroll",
  });

  const [paginationViews, setPaginationViews] = useState(1);
  const [selectedItemIndex, setSelectedItemIndex] = useState(1);
  const [cardsRef, setCardsRef] = useState<{ [key: string]: HTMLDivElement }[]>(
    [],
  );
  const cardsContainer = useRef<null | HTMLDivElement>(null);
  const slidesPerView = 2;

  const next = () => {
    setSelectedItemIndex((selectedItemIndex + 1) % cardsOptions.length);
  };

  const prev = () => {
    if (selectedItemIndex === 0) {
      setSelectedItemIndex(selectedItemIndex - 1);
    } else {
      setSelectedItemIndex((selectedItemIndex - 1) % cardsOptions.length);
    }
  };

  const getPaginationViews = () => {
    const cards = cardsOptions.map((card) => card);
    const views = [];
    for (
      let i = 0;
      i < Math.ceil(cardsOptions.length / slidesPerView);
      i += 1
    ) {
      views.push(cards.splice(0, slidesPerView));
    }
    return views;
  };

  const scrollToSelectedCard = () => {
    setCardsRef((el) => {
      return cardsOptions.map((_, index) => el[index] || createRef());
    });
    const selectedCardIndex = cardsOptions.findIndex((card) => card.active);
    if (
      cardsContainer?.current &&
      selectedCardIndex > -1 &&
      cardsRef[selectedCardIndex]?.current
    ) {
      const cardWidth = cardsRef[selectedCardIndex].current.offsetWidth;
      const cardsGapDistance = selectedCardIndex * 16;
      const scrollAmount = cardWidth * selectedCardIndex + cardsGapDistance;
      cardsContainer.current.scrollLeft = scrollAmount;
    }
  };

  useEffect(() => {
    if (usePagination && device.type !== "mobile") {
      const views = getPaginationViews();
      setPaginationViews(views.length);
      const selectedCardIndex = views.findIndex((view) =>
        view.find((card) => card.active),
      );
      setSelectedItemIndex(selectedCardIndex);
    } else if (mode === "scroll" || device.type === "mobile") {
      scrollToSelectedCard();
    }
  }, [cardsOptions]);

  return (
    <div className={classList} {...rest}>
      {cardsOptions?.length &&
        (usePagination && device.type === "desktop" ? (
          <>
            <TecmaCarousel
              selectedItemIndex={selectedItemIndex < 0 ? 0 : selectedItemIndex}
              loop={false}
              showPagination={false}
              slidesPerView={slidesPerView}
            >
              {cardsOptions.map((cardOptions) => (
                <div className='card-wrapper' key={cardOptions.title}>
                  <CardItem {...cardOptions} />
                  {cardOptions.availability && (
                    <p
                      className={`availability-description ${
                        cardOptions.confirmed ? "confirmed" : ""
                      }`}
                    >
                      <Icon iconName='warning' />
                      <span>{cardOptions.availability}</span>
                    </p>
                  )}
                </div>
              ))}
            </TecmaCarousel>
            <div className='cards-list-pagination'>
              <Button
                iconName='arrow-left'
                className='prev'
                onClick={prev}
                size='extra-small'
                disabled={selectedItemIndex === 0}
              />
              <div className='pagination-counter'>
                <Trans
                  i18nKey='card-list.pagination'
                  values={{
                    currentPage: selectedItemIndex + 1,
                    totalPages: paginationViews,
                  }}
                />
              </div>
              <Button
                iconName='arrow-right'
                className='next'
                onClick={next}
                size='extra-small'
                disabled={selectedItemIndex + 1 === paginationViews}
              />
            </div>
          </>
        ) : (
          <div className={classListMode} ref={cardsContainer}>
            {cardsOptions.map((cardOptions, index) => (
              <div
                className={`card-wrapper ${index}`}
                key={cardOptions.title}
                ref={
                  cardsRef[index] as unknown as React.LegacyRef<HTMLDivElement>
                }
              >
                <CardItem {...cardOptions} />
                {cardOptions.availability && (
                  <p
                    className={`availability-description ${
                      cardOptions.confirmed ? "confirmed" : ""
                    }`}
                  >
                    <Icon iconName='warning' />
                    <span>{cardOptions.availability}</span>
                  </p>
                )}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

CardList.defaultProps = defaultProps as Partial<CardListOptionalProps>;

export default React.memo(CardList);
