import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";

import { AlertPopup } from "components/AlertPopupFactory/components/AlertPopup";
import { FirstDatePopup } from "components/AlertPopupFactory/components/FirstDatePopup";
import AuthModal from "components/AuthModal";

import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { applicationActions } from "store/sagas/application/application.actions";
import { StepsEntity } from "store/domain/entities/steps.entity";
import type { DefaultProps } from "utils/types/defaultProps";
import { useStepRegistry } from "steps/hooks/useStepRegistry";
import { useStoreRepositories } from "hooks/storeRepositories/useStoreRepositories";
import { useDevice } from "hooks/device/device";
import { applicationStateActions } from "store/slices/applicationState/slice";
import { selectTotalSteps } from "store/domain/slices/steps.slice";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AlertPopupFactoryRequiredProps {}

type AlertPopupFactoryOptionalProps = DefaultProps;

export interface AlertPopupFactoryProps
  extends AlertPopupFactoryRequiredProps,
    AlertPopupFactoryOptionalProps {}

const AlertPopupFactory: React.FC<AlertPopupFactoryProps> = () => {
  const device = useDevice();
  const dispatch = useDispatch();
  const alert = useSelector(applicationStateSelectors.selectCurrentAlert);

  const closeAlert = applicationActions.closeAlertRequested();

  const { StepsEntityRepository } = useStoreRepositories();
  const stepIds = useSelector(
    () => StepsEntityRepository?.selectIds(),
    isEqual,
  );

  const currentStepIndex =
    useSelector(applicationStateSelectors.selectCurrentStepIndex) || 1;
  const farthestStepIndex =
    useSelector(applicationStateSelectors.selectFarthestStepIndex) || 1;
  const totalSteps = useSelector(selectTotalSteps);

  const isFarthestStepIndex = currentStepIndex === farthestStepIndex;

  const limitedStepIndex = Math.min(
    (stepIds?.length || 1) - 1,
    currentStepIndex - 1,
  );

  const curStepId = stepIds?.[limitedStepIndex];

  const step = StepsEntityRepository?.selectById(curStepId as string);

  const {
    stepConfig,
    userSelection,
    unconfirmedUserSelection,
    handleUserSelectionChange,
    handleUnconfirmedUserSelectionChange,
    mobileModalKey,
    MobileModalRenderer,
  } = useStepRegistry(step as unknown as StepsEntity);

  const setIsZoomed = (zoomed: boolean) => {
    if (zoomed) {
      dispatch(applicationStateActions.setZoomedStepIndex(currentStepIndex));
    } else {
      dispatch(applicationStateActions.closeZoom());
    }
  };

  const handleUserSelection =
    step?.getOrder() === totalSteps || isFarthestStepIndex
      ? userSelection
      : unconfirmedUserSelection;

  const handleStepUserSelectionChange =
    step?.getOrder() === totalSteps || isFarthestStepIndex
      ? handleUserSelectionChange
      : handleUnconfirmedUserSelectionChange;

  if (alert) {
    switch (alert.type) {
      case "warning":
        return (
          <AlertPopup
            closeAlertAction={closeAlert}
            type='warning'
            {...alert.props}
          />
        );
      case "success":
        return (
          <AlertPopup
            closeAlertAction={closeAlert}
            type='success'
            {...alert.props}
          />
        );
      case "login":
        return (
          <AuthModal
            mode='login'
            isConfigurationComplete={alert.props.isConfigurationComplete}
          />
        );
      case "first-date":
        return (
          <FirstDatePopup
            closeAlertAction={closeAlert}
            firstDateContent={alert.props.firstDateContent}
          />
        );
      case mobileModalKey:
        return device.type === "mobile" && MobileModalRenderer && stepConfig ? (
          <MobileModalRenderer
            stepConfig={stepConfig}
            onUserSelectionChange={handleStepUserSelectionChange}
            userSelection={handleUserSelection}
            setZoomed={setIsZoomed}
            confirmedUserSelection={
              !isFarthestStepIndex ? userSelection : undefined
            }
          />
        ) : null;
      default:
        return null;
    }
  }
  return null;
};

export default AlertPopupFactory;
