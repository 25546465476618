import React from "react";
import { Trans, useTranslation } from "@tecma/i18n";
import { getCleanName } from "utils/functions/formatters";
import type { PackStepModule } from "steps/PackStep";
import type { DefaultProps } from "../../../../utils/types/defaultProps";
import type { RecapStepsComponentProps } from "../../../types/step";
import "./packs-recap.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PacksRecapRequiredProps {}

// Optional Props
type PacksRecapOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface PacksRecapProps
  extends PacksRecapRequiredProps,
    PacksRecapOptionalProps,
    RecapStepsComponentProps<PackStepModule> {}

const PacksRecap: React.FC<PacksRecapProps> = ({
  userSelectionDetails,
  buildingName,
}) => {
  const { t } = useTranslation();
  return (
    <div className='packs-recap'>
      {userSelectionDetails && userSelectionDetails?.length === 0 && (
        <p>{t("pack-picker.recap.no-extras")}</p>
      )}
      {userSelectionDetails && userSelectionDetails?.length > 0 && (
        <ul>
          {userSelectionDetails?.map((pack) => (
            <li className='packs-recap-item' key={pack.name}>
              <Trans
                i18nKey={t([
                  `${buildingName}.pack-picker.pack.${pack.name}.label`,
                  `pack-picker.pack.${pack.name}.label`,
                  `${getCleanName(pack.name)} pack`,
                ])}
                components={{ 1: <span /> }}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PacksRecap;
