import React from "react";
import classNames from "classnames";
import { Icon, Button } from "@tecma/ui";
import { useTranslation } from "@tecma/i18n";

import { DefaultProps } from "utils/types/defaultProps";
import { IUser } from "utils/types/auth";

// styles
import "./drawerFooter.scss";

// Required Props
interface DrawerFooterRequiredProps {
  handleMobileLogout: () => void;
}

// Optional Props
interface DrawerFooterOptionalProps extends DefaultProps {
  user?: IUser | null;
  impersonatedUser?: IUser | null;
}

// Combined required and optional props to build the full prop interface
export interface DrawerFooterProps
  extends DrawerFooterRequiredProps,
    DrawerFooterOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: DrawerFooterOptionalProps = {
  "data-testid": "space-configurator-drawerFooter",
};

const DrawerFooter: React.FC<DrawerFooterProps> = ({
  className,
  handleMobileLogout,
  user,
  impersonatedUser,
  ...rest
}) => {
  const { t } = useTranslation();
  const classList = classNames("space-configurator-drawerFooter", className);
  const getUserRole = () => {
    if (user?.role) {
      return t([`header.user-role.${user.role}`, user.role]);
    }
    return t("header.user");
  };

  return (
    <div className={classList} {...rest}>
      {impersonatedUser && (
        <>
          <div className='drawer-footer-item'>
            <Icon iconName='user' />
            <p>
              <span>{getUserRole()}: </span>
              <br />
              {user?.firstName} {user?.lastName}
            </p>
          </div>
          <div className='drawer-footer-item'>
            <Icon iconName='user-circle' />
            <p>
              <span>{t("header.customer")}: </span>
              <br />
              {impersonatedUser?.firstName} {impersonatedUser?.lastName}
            </p>
          </div>
        </>
      )}
      <Button
        onClick={handleMobileLogout}
        iconName='sign-out'
        className='mobile-logout'
        fluid
      >
        {t("header.logout")}
      </Button>
    </div>
  );
};

DrawerFooter.defaultProps = defaultProps as Partial<DrawerFooterOptionalProps>;

export default React.memo(DrawerFooter);
