import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { useStepRegistry } from "steps/hooks/useStepRegistry";
import { useStoreRepositories } from "hooks/storeRepositories/useStoreRepositories";
import { StepsEntity } from "store/domain/entities/steps.entity";
import { selectTotalSteps } from "store/domain/slices/steps.slice";
import { applicationActions } from "store/sagas/application/application.actions";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";

const useConfirmButtonMobile = () => {
  const dispatch = useDispatch();
  const currentStepIndex = useSelector(
    applicationStateSelectors.selectCurrentStepIndex,
  );
  const totalSteps = useSelector(selectTotalSteps);
  const isSavingConfiguration = useSelector(
    applicationStateSelectors.selectIsSavingConfiguration,
  );
  const { StepsEntityRepository } = useStoreRepositories();
  const stepIds = useSelector(
    () => StepsEntityRepository?.selectIds(),
    isEqual,
  );
  const getCurrStepIndex = () => {
    if (currentStepIndex === 0) {
      return currentStepIndex;
    }
    if (currentStepIndex > totalSteps) {
      return currentStepIndex - 2;
    }
    return currentStepIndex - 1;
  };
  const curStepId = stepIds?.[getCurrStepIndex()];

  const step = StepsEntityRepository?.selectById(curStepId as string);

  const isCompleted = currentStepIndex > totalSteps;
  const { isUserSelectionComplete, stepModule, isStepLoading } =
    useStepRegistry(step as StepsEntity);

  const getButtonText = () => {
    if (currentStepIndex === 0) {
      return "recap.start";
    }
    if (isCompleted) {
      return "recap.save-proceed";
    }
    return `recap.confirm-step.${stepModule.name}`;
  };

  const handleGoToNextStep = () => {
    if (isCompleted) {
      dispatch(applicationActions.saveSelectionsRequested());
    } else if (step?.isOpen() && isUserSelectionComplete) {
      dispatch(applicationActions.stepClickRequested(currentStepIndex));
    }
  };

  return {
    buttonText: getButtonText(),
    isDisabled: currentStepIndex !== 0 && !isUserSelectionComplete,
    isUserSelectionComplete,
    isCompleted,
    isLoading: isStepLoading,
    isSavingConfiguration,
    isStart: currentStepIndex === 0,
    onMobileConfirmClick: handleGoToNextStep,
  };
};

export default useConfirmButtonMobile;
