import React, { MouseEventHandler } from "react";
import classNames from "classnames";
import { Icon } from "@tecma/ui";

import type { DefaultProps } from "utils/types/defaultProps";

// styles
import "./planimetry-item.scss";
import { useTranslation } from "@tecma/i18n";

// Required Props
interface PlanimetryItemRequiredProps {
  active: boolean;
  disabled: boolean;
  name: string;
  confirmed: boolean;
  onSelect: (value: Planimetry) => void;
}

// Optional Props
type PlanimetryItemOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface PlanimetryItemProps
  extends PlanimetryItemRequiredProps,
    PlanimetryItemOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: PlanimetryItemOptionalProps = {
  "data-testid": "space-configurator-planimetryItem",
};

const PlanimetryItem: React.FC<PlanimetryItemProps> = ({
  className,
  active,
  disabled,
  name,
  confirmed,
  onSelect,
  ...rest
}) => {
  const classList = classNames("planimetry-item", className, {
    disabled,
    active,
    confirmed,
  });
  const { t } = useTranslation();

  return (
    <button
      type='button'
      className={classList}
      onClick={
        onSelect as unknown as MouseEventHandler<HTMLButtonElement> | undefined
      }
      {...rest}
    >
      <Icon className='planimetry-item-icon' iconName='planimetry' />
      <div className='planimetry-item-divider' />
      <div className='planimetry-item-name'>
        {`${t("planimetry-item.floor-plan")} ${name}`}
      </div>
    </button>
  );
};

PlanimetryItem.defaultProps =
  defaultProps as Partial<PlanimetryItemOptionalProps>;

export default React.memo(PlanimetryItem);
