import { put } from "redux-saga/effects";
import { STEP_COMPONENTS_REGISTRY } from ".";
import type { AllStepModules } from "./types/step";

function getStepModuleResetSaga(stepModule: AllStepModules) {
  return function* resetUserSelectionSaga() {
    yield put(stepModule.resetUserSelectionAction());
  };
}

export const getStepModulesResetSagas = () =>
  Object.values(STEP_COMPONENTS_REGISTRY).map((module) =>
    getStepModuleResetSaga(module),
  );
