import React from "react";
import classNames from "classnames";
import type { DefaultProps } from "utils/types/defaultProps";

// styles
import "./planimetry-picker.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PlanimetryPickerRequiredProps {
  children: React.ReactNode;
}

// Optional Props
type PlanimetryPickerOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface PlanimetryPickerProps
  extends PlanimetryPickerRequiredProps,
    PlanimetryPickerOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: PlanimetryPickerOptionalProps = {
  "data-testid": "space-configurator-planimetryPicker",
};

const PlanimetryPicker: React.FC<PlanimetryPickerProps> = ({
  className,
  children,
  ...rest
}) => {
  const classList = classNames(
    "space-configurator-planimetry-picker",
    className,
  );

  return (
    <div className={classList} {...rest}>
      {children}
    </div>
  );
};

PlanimetryPicker.defaultProps =
  defaultProps as Partial<PlanimetryPickerOptionalProps>;

export default React.memo(PlanimetryPicker);
