import React from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "@tecma/i18n";
import { Button, Icon, Modal } from "@tecma/ui";

import type { DefaultProps } from "utils/types/defaultProps";

import "./alert-popup.scss";
import { useDispatch } from "react-redux";

const TRANSLATIONS_COMPONENTS = {
  b: <strong />,
  s: <span />,
  br: <br />,
  span: <span />,
};

type AlertPopupRequiredProps = AlertProps;

type AlertPopupOptionalProps = DefaultProps;

export interface AlertPopupProps
  extends AlertPopupRequiredProps,
    AlertPopupOptionalProps {
  type: "success" | "warning";
}

const defaultProps: AlertPopupOptionalProps = {
  "data-testid": "space-configurator-header",
};

const AlertPopup: React.FC<AlertPopupProps> = ({
  content,
  confirmButtonText,
  cancelButtonText,
  confirmButtonAction,
  cancelButtonAction,
  closeAlertAction,
  title,
  type,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alertPopupClasses = classNames("alert-popup");

  const handleOnAbort = () => {
    if (cancelButtonAction) {
      dispatch(cancelButtonAction);
    }
  };

  const handleOnConfirm = () => {
    if (confirmButtonAction) {
      dispatch(confirmButtonAction);
    } else if (closeAlertAction) {
      dispatch(closeAlertAction);
    }
  };

  const handleClose = () => {
    if (closeAlertAction) {
      dispatch(closeAlertAction);
    }
  };

  const titleContent = title ? t(title) : t(`alert.${type}`);

  return (
    <Modal
      className={alertPopupClasses}
      size='medium'
      isOpen
      verticalPosition='center'
      onClose={handleClose}
      closeOnBackDropClick
    >
      <Modal.Header
        className='alert-popup-header'
        closeIcon
        onClose={handleClose}
      />
      <Modal.Content>
        <div className='alert-popup-icon'>
          <Icon
            size='large'
            iconName={type === "warning" ? "error" : "success"}
          />
          <p className='alert-popup-icon-text'>{titleContent}</p>
        </div>
        <p className='alert-popup-content'>
          {content && (
            <Trans i18nKey={content} components={TRANSLATIONS_COMPONENTS} />
          )}
        </p>
      </Modal.Content>
      <Modal.Footer noWrapAndCenter className='alert-popup-footer'>
        {cancelButtonText && (
          <Button
            className='alert-popup-button abort'
            outlined
            onClick={() => handleOnAbort()}
          >
            <Trans
              i18nKey={cancelButtonText}
              components={TRANSLATIONS_COMPONENTS}
            />
          </Button>
        )}
        {confirmButtonText && (
          <Button
            className='alert-popup-button confirm'
            onClick={() => handleOnConfirm()}
          >
            <Trans
              i18nKey={confirmButtonText}
              components={TRANSLATIONS_COMPONENTS}
            />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

AlertPopup.defaultProps = defaultProps as Partial<AlertPopupOptionalProps>;

export default AlertPopup;
