/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: MoodStepSlice = {
  config: null,
  selectedMood: null,
  unconfirmedMood: null,
  isLoading: false,
};

const slice = createSlice({
  name: "moodStep",
  initialState,
  reducers: {
    putConfig: (state, action: PayloadAction<MoodStepConfig>) => {
      state.config = action.payload;
    },
    selectedMood: (state, action: PayloadAction<MoodName>) => {
      state.selectedMood = action.payload;
    },
    selectedUnconfirmedMood: (state, action: PayloadAction<MoodName>) => {
      state.unconfirmedMood = action.payload;
    },
    resetMood: (state) => {
      state.selectedMood = null;
      state.unconfirmedMood = null;
    },
    setIsStepLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

const stepConfigActions = slice.actions;

const { reducer } = slice;

export const moodStepSlice = {
  stepConfigActions,
  reducer,
};
