/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";

export interface Dimensions {
  height: number;
  width: number;
}

interface DeviceState {
  height: number;
  width: number;
}

const initialState: DeviceState = {
  height: window.innerHeight,
  width: window.innerWidth,
};

const slice = createSlice({
  name: "deviceState",
  initialState,
  reducers: {
    setDeviceDimensions: (state, action: PayloadAction<Dimensions>) => {
      state.height = action.payload.height;
      state.width = action.payload.width;
    },
  },
});

export const deviceStateActions = slice.actions;

export const getDeviceState = createSelector(
  (state: RootState) => state.deviceState,
  (state) => state,
);

export const { reducer } = slice;
