import React, { Fragment, useState } from "react";
import classNames from "classnames";
import { DefaultProps } from "utils/types/defaultProps";
import i18n, { useTranslation } from "@tecma/i18n";
import { DropDown, Button, Accordion } from "@tecma/ui";
import { useDevice } from "hooks/device/device";

// styles
import "./headerLanguageSelector.scss";

// Required Props
interface HeaderLanguageSelectorRequiredProps {
  headerRef: React.MutableRefObject<HTMLDivElement>;
  availableLanguages: string[];
}

// Optional Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HeaderLanguageSelectorOptionalProps extends DefaultProps {}

// Combined required and optional props to build the full prop interface
export interface HeaderLanguageSelectorProps
  extends HeaderLanguageSelectorRequiredProps,
    HeaderLanguageSelectorOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: HeaderLanguageSelectorOptionalProps = {
  "data-testid": "space-configurator-headerLanguageSelector",
};

const HeaderLanguageSelector: React.FC<HeaderLanguageSelectorProps> = ({
  headerRef,
  availableLanguages,
  className,
  ...rest
}) => {
  const device = useDevice();
  const { t } = useTranslation();
  const [langOpen, setLangOpen] = useState(false);

  const selectLanguageButtonClassList = classNames("select-language-button", {
    open: langOpen,
  });

  const onClickChangeLanguage = (language: string) => {
    i18n.changeLanguage(language, () => {
      setLangOpen(false);
    });
  };

  return device.type !== "mobile" ? (
    <DropDown
      className='language-selector'
      isOpen={langOpen}
      parentElement={headerRef.current}
      trigger={
        <Button
          className={selectLanguageButtonClassList}
          onClick={() => {}}
          transparent
          iconName='arrow-down'
          iconSize='small'
          rightIcon
        >
          {t(`language.${i18n.resolvedLanguage}.abbreviation`)}
        </Button>
      }
      onToggle={() => setLangOpen(!langOpen)}
      {...rest}
    >
      {availableLanguages.map((code) => (
        <Fragment key={code}>
          <DropDown.Element
            key={code}
            onClick={() => onClickChangeLanguage(code)}
          >
            {t(`language.${code}.label`)}
          </DropDown.Element>
          <DropDown.Divider />
        </Fragment>
      ))}
    </DropDown>
  ) : (
    <Accordion
      opens={langOpen ? 0 : undefined}
      onClick={() => setLangOpen(!langOpen)}
      className='language-selector-mobile'
    >
      <Accordion.Content
        titleElement={
          <p>{t(`language.${i18n.resolvedLanguage}.abbreviation`)}</p>
        }
      >
        {availableLanguages.map((code) => (
          <Button key={code} onClick={() => onClickChangeLanguage(code)}>
            {t(`language.${code}.label`)}
          </Button>
        ))}
      </Accordion.Content>
    </Accordion>
  );
};

HeaderLanguageSelector.defaultProps =
  defaultProps as Partial<HeaderLanguageSelectorOptionalProps>;

export default React.memo(HeaderLanguageSelector);
