import { gql } from "@apollo/client";

/**
 * @deprecated replaced by application info
 */
const PROJECT_DATA_TO_RETRIEVE = `
  projectID
  hostname
  pageTitle
  logo {
    base
    monogram
    full
    favicon
  },
  projectName
  languages
  defaultLang
  style
  config {
    complex
    carouselImages
    homePageUrl
    reservedAreaUrl
    privacyLink
    steps
      {
        name
        icon
        order
        type
      }
    rentConstraints {
      minMonthsToStay
      checkInDays
    }
  }
`;

/**
 * @deprecated replaced by application info
 */
export const GET_PROJECT_INFO = gql`
query getSpaceConfiguratorApplicationInfo(
  $complex : String
) {
  getSpaceConfiguratorApplicationInfo(complex : $complex) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }
`;

/**
 * @deprecated replaced by application info
 */
export const GET_PROJECT_INFO_DEFAULT_OPTIONS = (hostname: string) => {
  return {
    variables: {
      host: hostname,
    },
  };
};
