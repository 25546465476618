import React, { FC } from "react";
import { Repositories } from "../../store/domain";
import { StoreRepositoriesContext } from "./context";

interface AdaptersProps {
  children?: React.ReactNode;
  repos: Repositories;
}

export const AdaptersProvider: FC<AdaptersProps> = ({ children, repos }) => (
  <StoreRepositoriesContext.Provider value={repos}>
    {children}
  </StoreRepositoriesContext.Provider>
);
