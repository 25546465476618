import { PriceDetails, StepSelectors } from "steps/types/step";
import type { RootState } from "store";
import { calculateSpecificPriceDetails } from "utils/functions/extraSpace";
import type { ExtraSpaceStepModule } from "..";

const selectConfigState = (state: RootState) =>
  state.extraSpaceStep?.config || undefined;

const selectSelectedExtraSpaceState = (state: RootState) =>
  state.extraSpaceStep?.selectedExtraSpace || undefined;

const selectUnconfirmedExtraSpaceState = (state: RootState) =>
  state.extraSpaceStep?.unconfirmedExtraSpace || undefined;

const selectSelectedExtraSpaceDetails = (state: RootState) => {
  const config = selectConfigState(state);
  const selectedExtraSpaces = state.extraSpaceStep?.selectedExtraSpace;
  if (selectedExtraSpaces && config) {
    const selectedExtraSpaceIDs = Object.keys(selectedExtraSpaces);
    let userSelectedExtraSpaces = {};
    const configSpaces = config.options.map((el) => el.spaces).flat();
    selectedExtraSpaceIDs.forEach((space) => {
      const configSpacesIDs = configSpaces.map((configSpace) => configSpace.id);
      if (configSpacesIDs.includes(space)) {
        const foundedSpace = configSpaces.find((el) => el.id === space);
        if (foundedSpace)
          userSelectedExtraSpaces = {
            ...userSelectedExtraSpaces,
            [foundedSpace.id]: {
              selected:
                state.extraSpaceStep.selectedExtraSpace?.[foundedSpace?.id],
              included: foundedSpace.included,
              name: foundedSpace.name,
              type: config.options[
                config.options.findIndex((el) =>
                  el.spaces
                    .map((spacec) => spacec.id)
                    .includes(foundedSpace.id),
                )
              ].type,
            },
          };
      }
    });
    return userSelectedExtraSpaces;
  }
  return undefined;
};

const selectSelectedExtraSpacesPrice = (state: RootState): number => {
  const config = selectConfigState(state);
  const selectedExtraSpaces = state.extraSpaceStep?.selectedExtraSpace;
  if (selectedExtraSpaces && config) {
    const selectedExtraSpaceIDs = Object.keys(selectedExtraSpaces);
    return config.options.reduce((accExtraSpace, currExtraSpace) => {
      const totalPriceSpaces = currExtraSpace.spaces.reduce(
        (totalPrice, space) => {
          if (selectedExtraSpaceIDs.includes(space.id)) {
            const selectedSpacesCount =
              selectedExtraSpaces[space.id] - space.included;
            let spacesPrice = 0;
            for (let i = 0; i < selectedSpacesCount; i += 1) {
              spacesPrice += space.prices[i].price;
            }
            return totalPrice + spacesPrice;
          }
          return totalPrice;
        },
        0,
      );
      return accExtraSpace + totalPriceSpaces;
    }, 0);
  }
  return 0;
};

const selectSelectedExtraSpacesPriceDetails = (
  state: RootState,
): PriceDetails => {
  const config = selectConfigState(state);
  const selectedExtraSpaces = state.extraSpaceStep?.selectedExtraSpace;
  if (selectedExtraSpaces && config) {
    const selectedExtraSpaceIDs = Object.keys(selectedExtraSpaces);
    return config.options.reduce<PriceDetails>(
      (accExtraSpace, currExtraSpace) => {
        const totalPriceSpaces = currExtraSpace.spaces.reduce<PriceDetails>(
          (totalPriceDetails, space) => {
            if (selectedExtraSpaceIDs.includes(space.id)) {
              const selectedSpacesCount =
                selectedExtraSpaces[space.id] - space.included;
              const spacesPriceDetails: PriceDetails = {};
              for (let i = 0; i < selectedSpacesCount; i += 1) {
                Object.keys(space.prices[i].priceDetails)
                  .filter((key) => key !== "__typename")
                  .forEach((priceName) => {
                    if (spacesPriceDetails[priceName]) {
                      spacesPriceDetails[priceName] = {
                        ...spacesPriceDetails[priceName],
                        amount:
                          spacesPriceDetails[priceName].amount +
                          space.prices[i].priceDetails[priceName].amount,
                      };
                    } else {
                      spacesPriceDetails[priceName] =
                        space.prices[i].priceDetails[priceName];
                    }
                  });
              }
              return calculateSpecificPriceDetails(
                totalPriceDetails,
                spacesPriceDetails,
              );
            }
            return totalPriceDetails;
          },
          {},
        );
        return calculateSpecificPriceDetails(totalPriceSpaces, accExtraSpace);
      },
      {},
    );
  }
  return {};
};

const isUserSelectionModified = (state: RootState) => {
  const oldSelection = state.extraSpaceStep?.selectedExtraSpace;
  const newSelection = state.extraSpaceStep?.unconfirmedExtraSpace;

  return Boolean(
    oldSelection &&
      newSelection &&
      !(
        Object.keys(oldSelection).length === Object.keys(newSelection).length &&
        Object.keys(oldSelection).every(
          (key) => oldSelection[key] === newSelection[key],
        )
      ),
  );
};

export const extraSpaceStepSelectors: StepSelectors<ExtraSpaceStepModule> = {
  selectConfig: selectConfigState,
  selectUserSelection: selectSelectedExtraSpaceState,
  selectUnconfirmedUserSelection: selectUnconfirmedExtraSpaceState,
  selectUserSelectionDetail: selectSelectedExtraSpaceDetails,
  selectUserSelectionPrice: selectSelectedExtraSpacesPrice,
  selectUserSelectionPriceDetails: selectSelectedExtraSpacesPriceDetails,
  isUserSelectionModified,
  stepPageContentCarouselImagesSelector: (state) =>
    state.extraSpaceStep?.config?.carouselImages,
  areOptionsAvailableSelector: (state) =>
    Number(state.extraSpaceStep.config?.options?.length) > 0,
  isStepLoading: (state) => state.extraSpaceStep.isLoading,
};
