import {
  ActionCreatorWithPayload,
  Dictionary,
  EntityAdapter,
  EntityId,
  Store,
} from "@reduxjs/toolkit";
import { IStoreEntity, IEntity } from "./IEntity";

/*
export interface EntitySelectors<T, V> {
    selectIds: (state: V) => EntityId[];
    selectEntities: (state: V) => Dictionary<T>;
    selectAll: (state: V) => T[];
    selectTotal: (state: V) => number;
    selectById: (state: V, id: EntityId) => T | undefined;
}
*/
/**
 * T : Adapter destination
 *
 * S : Adapter source
 */
// FIXME: fix this
// eslint-disable-next-line no-use-before-define
export abstract class IRepository<
  T extends IEntity<S>,
  S extends IStoreEntity,
> {
  protected store: Store<any>;

  protected abstract readonly stateRetrieveFunction: (state: any) => any;

  protected abstract readonly storeAdapter: EntityAdapter<any>;

  public constructor(store: Store<any>) {
    this.store = store;
  }

  protected getState(): any {
    return this.store.getState();
  }

  public setStore(store: Store<any>) {
    this.store = store;
  }

  protected abstract createAction: ActionCreatorWithPayload<S> | undefined;

  protected abstract updateAction: ActionCreatorWithPayload<S> | undefined;

  protected abstract deleteAction: ActionCreatorWithPayload<S> | undefined;

  protected abstract Factory: new (storeEntity: S, store: Store<any>) => T;

  public mapFromSource(obj: S): T {
    const entity = new this.Factory(obj, this.store);
    entity.mapFromSource(obj);
    return entity;
  }

  // eslint-disable-next-line class-methods-use-this
  public mapToSource = (obj: T): S => obj.mapToSource();

  // TODO: May be better to create from storeEntity?
  // Or use this.mapFromSource inside the method
  public create(obj: T): void {
    if (!this.createAction) {
      throw new Error("Create method is not implemented");
    }
    this.store.dispatch(this.createAction(obj.mapToSource()));
  }

  public update(obj: T): void {
    if (!this.updateAction) {
      throw new Error("Update method is not implemented");
    }
    this.store.dispatch(this.updateAction(obj.mapToSource()));
  }

  public delete(obj: T): void {
    if (!this.deleteAction) {
      throw new Error("Delete method is not implemented");
    }
    this.store.dispatch(this.deleteAction(obj.mapToSource()));
  }

  public selectIds(): EntityId[] {
    const state = this.stateRetrieveFunction(this.getState());
    return this.storeAdapter.getSelectors().selectIds(state);
  }

  public selectEntities(): Dictionary<S> {
    const state = this.stateRetrieveFunction(this.getState());
    return this.storeAdapter.getSelectors().selectEntities(state);
  }

  public selectAll(): Array<T> | undefined {
    const state = this.stateRetrieveFunction(this.getState());
    const res = this.storeAdapter.getSelectors().selectAll(state);
    const retArray: Array<T> = [];
    res.forEach((storeEntity) => {
      retArray.push(this.mapFromSource(storeEntity));
    }, this);
    if (retArray.length > 0) {
      return retArray;
    }
    return undefined;
  }

  public selectById(id: string): T | undefined {
    const state = this.stateRetrieveFunction(this.getState());
    const res = this.storeAdapter.getSelectors().selectById(state, id);
    if (res) return this.mapFromSource(res);
    return undefined;
  }

  /**
   * Decorates a store selector in order to return mapped entities from store entities selected
   * @param selector Any store selector that returns at least one store entity
   * @returns decorated entities (one or more)
   */
  public decorateSelector =
    (selector: (state: any, ...rest: Array<any>) => S | Array<S>) =>
    (state: any, ...rest: Array<any>): T | Array<T> => {
      const res = selector(state, ...rest);
      if (Array.isArray(res)) {
        return res.map((storeEntity) => this.mapFromSource(storeEntity));
      }
      return this.mapFromSource(res);
    };
}
