import { module as PlanimetryStepModule } from "./PlanimetryStep";
import { module as SpaceTypeStepModule } from "./SpaceTypeStep";
import { module as MoodStepModule } from "./MoodStep";
import { module as FloorStepModule } from "./FloorStep";
// FIXME
// eslint-disable-next-line import/no-cycle
import { module as ComplexStepModule } from "./ComplexStep";
import { module as PackStepModule } from "./PackStep";
import { module as ExtraSpaceStepModule } from "./ExtraSpaceStep";
import type { StepRegistry } from "./types/step";

export const STEP_COMPONENTS_REGISTRY: StepRegistry = {
  COMPLEX_STEP: ComplexStepModule,
  PLANIMETRY_STEP: PlanimetryStepModule,
  MOOD_STEP: MoodStepModule,
  FLOOR_STEP: FloorStepModule,
  SPACE_TYPE_STEP: SpaceTypeStepModule,
  PACK_STEP: PackStepModule,
  EXTRA_SPACE_STEP: ExtraSpaceStepModule,
};

export const STEP_COMPONENTS_REDUCERS = {
  complexStep: ComplexStepModule.stepReducer,
  planimetryStep: PlanimetryStepModule.stepReducer,
  moodStep: MoodStepModule.stepReducer,
  floorStep: FloorStepModule.stepReducer,
  spaceTypeStep: SpaceTypeStepModule.stepReducer,
  packStep: PackStepModule.stepReducer,
  extraSpaceStep: ExtraSpaceStepModule.stepReducer,
};
