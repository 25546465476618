import React from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "@tecma/i18n";
import { Button, Icon, Modal } from "@tecma/ui";

import type { DefaultProps } from "utils/types/defaultProps";
import "./expired-token-modal.scss";

type ExpiredTokenModalRequiredProps = ExpiredTokenProps;

// Optional Props
type ExpiredTokenModalOptionalProps = DefaultProps;

export interface ExpiredTokenModalProps
  extends ExpiredTokenModalRequiredProps,
    ExpiredTokenModalOptionalProps {}

const defaultProps: ExpiredTokenModalOptionalProps = {
  "data-testid": "space-configurator-expired-token-modal",
};

const ExpiredTokenModal: React.FC<ExpiredTokenModalProps> = ({
  email,
  handleRefreshToken,
}) => {
  const { t } = useTranslation();
  const expiredTokenModalClasses = classNames("expired-token-modal");

  const hideEmail = (emailToHide: string) => {
    const dividedEmail = emailToHide.split("@");
    const firstPart = dividedEmail[0];
    const censoredFirstPart =
      firstPart[0] + "*".repeat(firstPart.length - 2) + firstPart.slice(-1);
    return `${censoredFirstPart}@${dividedEmail[1]}`;
  };

  return (
    <Modal
      className={expiredTokenModalClasses}
      size='medium'
      isOpen
      verticalPosition='center'
      onClose={() => {}}
    >
      <Modal.Header
        className='expired-token-modal-header'
        // closeIcon
        onClose={() => {}}
      />
      <Modal.Content>
        <div className='expired-token-modal-icon'>
          <Icon size='large' iconName='error' />
          <p className='expired-token-modal-icon-text'>
            {t("expired-token.warning")}
          </p>
        </div>
        <p className='expired-token-modal-content'>
          <Trans
            i18nKey='expired-token.description'
            components={{ 1: <strong /> }}
          />
        </p>
        <br />
        <p className='expired-token-modal-content'>
          <Trans i18nKey='expired-token.info' components={{ 1: <strong /> }} />
        </p>
        <p className='expired-token-modal-content'>
          <strong>{email && hideEmail(email)}</strong>
        </p>
      </Modal.Content>
      <Modal.Footer noWrapAndCenter className='expired-token-modal-footer'>
        <Button
          className='expired-token-modal-button confirm'
          onClick={() => handleRefreshToken()}
        >
          {t("expired-token.start")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ExpiredTokenModal.defaultProps =
  defaultProps as Partial<ExpiredTokenModalOptionalProps>;

export default ExpiredTokenModal;
