import { Store } from "@reduxjs/toolkit";
import type { RootState } from "..";
import { StepsEntityRepository } from "./repositories/steps.repo";

export interface Repositories {
  StepsEntityRepository: StepsEntityRepository | undefined;
}

export const EntitiesRepositories: Repositories = {
  StepsEntityRepository: undefined,
};

export const instantiateRepositories = (
  store: Store<RootState>,
): Repositories => {
  EntitiesRepositories.StepsEntityRepository = new StepsEntityRepository(store);
  return EntitiesRepositories;
};
