import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
  DefaultOptions,
} from "@apollo/client";
import Cookies from "js-cookie";
import { JWT_RENT_COOKIE } from "store/slices/auth/slice";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_APOLLO_URI });
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
  },
  query: {
    fetchPolicy: "no-cache",
  },
};
export const graphqlClientFactory = () => {
  const getToken = (): string => {
    const jwt = Cookies.get(JWT_RENT_COOKIE);
    // const jwt = Cookies.get("jwtBE");
    const token = process.env.REACT_APP_JWT_TOKEN;
    return jwt ? `Bearer ${jwt}` : token || "";
  };

  const authLink = new ApolloLink((operation, forward) => {
    const omitTypename = (key: string, value: string) =>
      key === "__typename" ? undefined : value;

    if (operation.variables && !operation.getContext().hasUpload) {
      // eslint-disable-next-line no-param-reassign
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename,
      );
    }
    operation.setContext({
      headers: {
        authorization: getToken(),
        referrerPolicy: "no-referrer-when-downgrade",
      },
    });
    return forward(operation);
  });

  return new ApolloClient<NormalizedCacheObject>({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions,
  });
};

export const graphqlClient = graphqlClientFactory();
