import { combineReducers } from "@reduxjs/toolkit";
import { STEP_COMPONENTS_REDUCERS } from "steps";
import { reducer as applicationStateReducer } from "../slices/applicationState/slice";
import { reducer as userInteractionsReducer } from "../slices/userInteractions/slice";
import { reducer as stepsEntityReducer } from "../domain/slices/steps.slice";
import { reducer as dateSelectorStateReducer } from "../slices/dateSelectorState/slice";
import { reducer as authReducer } from "../slices/auth/slice";
import { reducer as deviceStateReducer } from "../slices/deviceState/slice";

const reducers = {
  applicationState: applicationStateReducer,
  userInteractions: userInteractionsReducer,
  steps: stepsEntityReducer,
  dateSelectorState: dateSelectorStateReducer,
  deviceState: deviceStateReducer,
  auth: authReducer,
};

export const combinedReducers = combineReducers({
  ...reducers,
  ...STEP_COMPONENTS_REDUCERS,
});
