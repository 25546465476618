import { STEP_COMPONENTS_REGISTRY } from "steps";
import { all, fork } from "redux-saga/effects";
import { applicationMux } from "./application";
import dateSelectorSaga from "./availabilitySelector/availabilitySelector.saga";
import userInteractionsSagas from "./userInteractions/userInteractions.saga";
import authSaga from "./auth/auth.saga";

const forkedStepsSagas = Object.values(STEP_COMPONENTS_REGISTRY).map(
  ({ initSaga }) => fork(initSaga),
);

export default function* rootSaga() {
  yield all([fork(userInteractionsSagas)]);
  yield all([fork(applicationMux)]);
  yield all(forkedStepsSagas);
  yield all([fork(dateSelectorSaga)]);
  yield all([fork(authSaga)]);
}
