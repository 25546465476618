import { StepModule } from "../types/step";
import { ExtraSpaceRecap } from "./components/ExtraSpaceRecap";
import ExtraSpaceStep from "./components/ExtraSpaceStep";
import { extraSpaceStepActions } from "./sagas/actions";
import { extraSpaceStepRootInitSaga } from "./sagas/saga";
import { extraSpaceStepSelectors } from "./state/selectors";
import { extraSpaceStepSlice } from "./state/slice";

export type ExtraSpaceStepModule = StepModule<
  ExtraSpaceStepSlice,
  ExtraSpaceConfig,
  ExtraSpacesUserSelection,
  SpaceDetail
>;

export const module: ExtraSpaceStepModule = {
  type: "EXTRA_SPACE_STEP",
  name: "extra-space",
  icon: "square",
  priceCalculationMode: "ADDITION",
  stepRenderer: ExtraSpaceStep,
  recapStepRender: ExtraSpaceRecap,
  configSelector: extraSpaceStepSelectors.selectConfig,
  userSelectionSelector: extraSpaceStepSelectors.selectUserSelection,
  unconfirmedUserSelectionSelector:
    extraSpaceStepSelectors.selectUnconfirmedUserSelection,
  userSelectionDetailSelector:
    extraSpaceStepSelectors.selectUserSelectionDetail,
  userSelectionPriceSelector: extraSpaceStepSelectors.selectUserSelectionPrice,
  userSelectionPriceDetailsSelector:
    extraSpaceStepSelectors.selectUserSelectionPriceDetails,
  updateUserSelectionAction:
    extraSpaceStepSlice.stepConfigActions.setSelectedExtraSpaces,
  updateUnconfirmedUserSelectionAction:
    extraSpaceStepSlice.stepConfigActions.setUnconfirmedExtraSpaces,
  resetUserSelectionAction:
    extraSpaceStepSlice.stepConfigActions.resetExtraSpace,
  stepReducer: extraSpaceStepSlice.reducer,
  initConfigAction: extraSpaceStepActions.initConfig,
  initSaga: extraSpaceStepRootInitSaga,
  stepPageContentCarouselImagesSelector:
    extraSpaceStepSelectors.stepPageContentCarouselImagesSelector,
  isUserSelectionModified: extraSpaceStepSelectors.isUserSelectionModified,
  areOptionsAvailableSelector:
    extraSpaceStepSelectors.areOptionsAvailableSelector,
  setStepLoading: extraSpaceStepSlice.stepConfigActions.setIsStepLoading,
  isStepLoading: extraSpaceStepSelectors.isStepLoading,
};
