import Cookies from "js-cookie";
import { UserSelectionsDTO } from "steps/api";
import {
  EXPIRES_IN_RENT_COOKIE,
  JWT_RENT_COOKIE,
  REFRESH_TOKEN_RENT_COOKIE,
  USER_SELECTION_LOCAL_STORAGE_KEY,
} from "store/slices/auth/slice";
import getDomain from "utils/functions/getDomain";
import { IToken } from "utils/types/auth";

export const getCookieOptions = () => {
  return {
    expires: 1, // durata di 1 giorno, se non viene messa la durata diventa un session cookie che viene eliminato dopo aver chiuso il browser
    domain: getDomain(),
  };
};

export const getBackUrl = (): string => {
  const backToURL = new URL(window.location.href);
  const params = new URLSearchParams(backToURL.search);
  let backTo = `${window.location.origin}${window.location.pathname}?${params}`;
  backTo = backTo.replace("/login?", "");
  return backTo.endsWith("?") ? backTo.replace("?", "") : backTo;
};

export const setTokenCookies = (token: IToken) => {
  const cookieOptions = getCookieOptions();

  Cookies.set(JWT_RENT_COOKIE, token.accessToken, cookieOptions);
  Cookies.set(REFRESH_TOKEN_RENT_COOKIE, token.refreshToken, cookieOptions);
  Cookies.set(EXPIRES_IN_RENT_COOKIE, token.expiresIn, cookieOptions);
};

export const saveUserSelectionsByProjectIDToLocalStorage = (
  projectId: string,
  userSelections: UserSelectionsDTO,
) => {
  localStorage.setItem(
    USER_SELECTION_LOCAL_STORAGE_KEY,
    JSON.stringify({ [projectId]: userSelections }),
  );
};

export const getSavedConfigurations = (
  projectID: string,
): UserSelectionsDTO | null => {
  const data = localStorage.getItem(USER_SELECTION_LOCAL_STORAGE_KEY);
  if (data) {
    return (JSON.parse(data)?.[projectID] as UserSelectionsDTO) ?? null;
  }
  return null;
};

export const deleteSavedConfigurationsByProjectID = (
  projectID: string,
): void => {
  const data = localStorage.getItem(USER_SELECTION_LOCAL_STORAGE_KEY);
  if (data) {
    const savedSelections = JSON.parse(data);
    delete savedSelections[projectID];
    if (Object.keys(savedSelections).length) {
      localStorage.setItem(
        USER_SELECTION_LOCAL_STORAGE_KEY,
        JSON.stringify(savedSelections),
      );
    } else {
      localStorage.removeItem(USER_SELECTION_LOCAL_STORAGE_KEY);
    }
  }
};
