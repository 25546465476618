import React from "react";
import i18n, { useTranslation } from "@tecma/i18n";
import { Picker } from "components/Picker";
import { DefaultProps } from "utils/types/defaultProps";

// styles
import "./date-picker.scss";

interface DatePickerRequiredProps {
  dates: string[];
  handleChange: (datesUpdateEventPayload: DatesUpdateEventPayload) => void;
  disabledArrows: { start: DisabledArrows; end: DisabledArrows };
}

// Optional Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DatePickerOptionalProps extends DefaultProps {}

// Combined required and optional props to build the full prop interface
export interface DatePickerProps
  extends DatePickerRequiredProps,
    DatePickerOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: DatePickerOptionalProps = {
  "data-testid": "spaceConfigurator-datePicker",
};

const DatePicker: React.FC<DatePickerProps> = ({
  dates,
  handleChange,
  disabledArrows,
}) => {
  const { t } = useTranslation();
  const [startDate, endDate] = dates;

  const selectedDate = (date: string) => {
    return new Date(date).toLocaleString(i18n.language, {
      day: "numeric",
      month: "long",
    });
  };

  const selectedYear = (date: string) => {
    return new Date(date).toLocaleString("default", {
      year: "numeric",
    });
  };

  return (
    <div className='space-configurator-datePicker'>
      <div className='datePicker-container'>
        <p className='datePicker-label'>{t("date-picker.start-date-label")}</p>
        <div className='datePicker-content'>
          <Picker
            selectedValue={selectedDate(startDate)}
            onPrevClick={() =>
              handleChange({
                startOrEnd: "start",
                dayOrYear: "day",
                direction: "down",
              })
            }
            onNextClick={() =>
              handleChange({
                startOrEnd: "start",
                dayOrYear: "day",
                direction: "up",
              })
            }
            disabledArrows={disabledArrows?.start?.day}
          />
          <Picker
            selectedValue={selectedYear(startDate)}
            onPrevClick={() =>
              handleChange({
                startOrEnd: "start",
                dayOrYear: "year",
                direction: "down",
              })
            }
            onNextClick={() =>
              handleChange({
                startOrEnd: "start",
                dayOrYear: "year",
                direction: "up",
              })
            }
            disabledArrows={disabledArrows?.start?.year}
          />
        </div>
      </div>
      <div className='datePicker-container'>
        <p className='datePicker-label'>{t("date-picker.end-date-label")}</p>
        <div className='datePicker-content'>
          <Picker
            selectedValue={selectedDate(endDate)}
            onPrevClick={() =>
              handleChange({
                startOrEnd: "end",
                dayOrYear: "day",
                direction: "down",
              })
            }
            onNextClick={() =>
              handleChange({
                startOrEnd: "end",
                dayOrYear: "day",
                direction: "up",
              })
            }
            disabledArrows={disabledArrows?.end?.day}
          />
          <Picker
            selectedValue={selectedYear(endDate)}
            onPrevClick={() =>
              handleChange({
                startOrEnd: "end",
                dayOrYear: "year",
                direction: "down",
              })
            }
            onNextClick={() =>
              handleChange({
                startOrEnd: "end",
                dayOrYear: "year",
                direction: "up",
              })
            }
            disabledArrows={disabledArrows?.end?.year}
          />
        </div>
      </div>
    </div>
  );
};

DatePicker.defaultProps = defaultProps as Partial<DatePickerOptionalProps>;

export default React.memo(DatePicker);
