import uppercaseFormatter from "../../../../utils/functions/uppercaseFormatter";
import "./left-part-modal.scss";

interface LeftPartModalProps {
  title: string;
  subTitle: string;
  footerTitle?: string;
  footerActionText?: string;
  switchAuthMode?: () => void;
}

const LeftPartModal: React.FC<LeftPartModalProps> = ({
  title,
  subTitle,
  footerTitle,
  footerActionText,
  switchAuthMode,
}) => (
  <div className='am-left-container'>
    <div className='am-left-header'>
      <div className='am-left-title'>{title}</div>
      <div className='am-left-subtitle'>{uppercaseFormatter(subTitle)}</div>
    </div>
    {footerTitle && (
      <div className='am-left-footer'>
        <div className='am-left-footer-title'>{footerTitle}</div>
        <button
          onClick={switchAuthMode}
          className='am-left-footer-action'
          type='button'
        >
          {footerActionText}
        </button>
      </div>
    )}
  </div>
);

export default LeftPartModal;
