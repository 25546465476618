import React from "react";
import { DefaultProps } from "utils/types/defaultProps";
import "./recap-section-header.scss";

interface RecapSectionHeaderRequiredProps {
  title: string;
}

// Optional Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RecapSectionHeaderOptionalProps extends DefaultProps {}

// Combined required and optional props to build the full prop interface
export interface RecapSectionHeaderProps
  extends RecapSectionHeaderRequiredProps,
    RecapSectionHeaderOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: RecapSectionHeaderOptionalProps = {
  "data-testid": "space-configurator-recapSection",
};

const RecapSectionHeader: React.FC<RecapSectionHeaderProps> = ({ title }) => {
  return <p className='recap-section-header'>{title}</p>;
};
RecapSectionHeader.defaultProps =
  defaultProps as Partial<RecapSectionHeaderOptionalProps>;
export default React.memo(RecapSectionHeader);
