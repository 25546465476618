import type { RootState } from "store";
import type { PriceDetails, StepSelectors } from "steps/types/step";

import type { PlanimetryStepModule } from "..";

const selectConfigState = (state: RootState) =>
  state.planimetryStep?.config || undefined;

const selectSelectedPlanimetryState = (state: RootState) =>
  state.planimetryStep?.selectedPlanimetry || undefined;

const selectUnconfirmedPlanimetryState = (state: RootState) =>
  state.planimetryStep?.unconfirmedPlanimetry || undefined;

const selectSelectedPlanimetryDetailState = (
  state: RootState,
): Planimetry | undefined => {
  if (state.planimetryStep?.selectedPlanimetry) {
    return state.planimetryStep.config?.options.find(
      (plan) => plan.name === state.planimetryStep?.selectedPlanimetry?.name,
    );
  }
  return undefined;
};

const selectSelectedPlanimetryToDBState = (
  state: RootState,
): PlanimetrySelection | undefined => {
  if (state.planimetryStep?.selectedPlanimetry) {
    return { planimetry: state.planimetryStep.selectedPlanimetry };
  }
  return undefined;
};

const selectSelectedPlanimetryPriceState = (state: RootState): number => {
  if (state.planimetryStep?.selectedPlanimetry) {
    return (
      state.planimetryStep.config?.options.find(
        (plan) => plan.name === state.planimetryStep?.selectedPlanimetry?.name,
      )?.price || 0
    );
  }
  return 0;
};

const selectSelectedPlanimetryPriceDetailsState = (
  state: RootState,
): PriceDetails => {
  if (state.planimetryStep?.selectedPlanimetry) {
    return (
      state.planimetryStep.config?.options.find(
        (plan) => plan.name === state.planimetryStep?.selectedPlanimetry?.name,
      )?.priceDetails || {}
    );
  }
  return {};
};

const isUserSelectionModified = (state: RootState) =>
  Boolean(
    state.planimetryStep?.selectedPlanimetry &&
      state.planimetryStep?.unconfirmedPlanimetry &&
      state.planimetryStep?.selectedPlanimetry?.name !==
        state.planimetryStep?.unconfirmedPlanimetry?.name,
  );

export const planimetryStepSelectors: StepSelectors<PlanimetryStepModule> = {
  selectConfig: selectConfigState,
  selectUserSelection: selectSelectedPlanimetryState,
  selectUnconfirmedUserSelection: selectUnconfirmedPlanimetryState,
  selectUserSelectionDetail: selectSelectedPlanimetryDetailState,
  selectUserSelectionPrice: selectSelectedPlanimetryPriceState,
  selectUserSelectionPriceDetails: selectSelectedPlanimetryPriceDetailsState,
  isUserSelectionModified,
  areOptionsAvailableSelector: (state) =>
    Number(state.planimetryStep.config?.options.length) > 0,
  isStepLoading: (state) => state.planimetryStep.isLoading,
};
