/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, ReactNode, useEffect } from "react";
import { FieldValues, useForm, Validate } from "react-hook-form";
import { useTranslation } from "@tecma/i18n";

import classNames from "classnames";
import {
  CheckboxField,
  CreatePasswordData,
  EditPasswordData,
  FooterLink,
  FormField,
  LoginData,
  LoginNewClientData,
  RecoveryData,
  RecoveryPasswordData,
  RegistrationData,
} from "../../../../utils/types/form";
import TelInput from "../PhoneInput";
import "./right-part-modal.scss";

export type ErrorMessage = {
  inputField: string;
  message: string;
};

export interface RightPartModalProps {
  formType: string;
  inputFields: FormField[];
  submitText: string;
  footerLinks?: FooterLink[];
  checkboxFields?: CheckboxField[];
  footerInfo?: ReactNode;
  handleFormAction: (
    data: RegistrationData &
      LoginData &
      RecoveryData &
      EditPasswordData &
      CreatePasswordData &
      RecoveryPasswordData &
      LoginNewClientData,
  ) => void;
  apiError?: ErrorMessage | null;
}

const RightPartModal: React.FC<RightPartModalProps> = ({
  formType,
  inputFields,
  checkboxFields,
  submitText,
  footerLinks,
  footerInfo,
  handleFormAction,
  apiError,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    unregister,
    reset,
    setError,
    control,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });
  const { t } = useTranslation();

  useEffect(() => {
    if (apiError) {
      setError(apiError.inputField, { message: apiError.message });
    }
  }, [apiError]);

  useEffect(() => {
    reset();
    unregister();
  }, [formType]);

  const submitData = (data: FieldValues) => {
    const formData = data as RegistrationData &
      LoginData &
      RecoveryData &
      EditPasswordData &
      CreatePasswordData &
      RecoveryPasswordData &
      LoginNewClientData;
    handleFormAction(formData);
  };

  return (
    <form
      className='am-right-container'
      autoComplete='off'
      onSubmit={handleSubmit((data: FieldValues) => submitData(data))}
    >
      <div className={`am-right-form am-right-form-${formType}`}>
        <div className='am-register-required'>{t("auth.required-fields")}</div>
        {inputFields.map((input, key) => {
          const matchesPreviousPassword: Validate<string> = (value: string) => {
            const { newPassword } = getValues();
            const errorText = t("auth.error.passwords-mismatch");
            return newPassword === value || errorText;
          };
          return input.fieldName === "telefono" ? (
            <div className='am-register-tel-container' key={input.fieldName}>
              <div className='am-errors-container'>
                {errors?.[input.fieldName] ? (
                  <label className='am-errors-label' htmlFor={input.fieldName}>
                    {errors?.[input.fieldName]?.message as unknown as string}
                  </label>
                ) : null}
              </div>
              <TelInput
                key={`${input.fieldName + key}`}
                control={control}
                className={`am-register-phoneNumber ${
                  errors[input.fieldName] ? "am-input-error" : ""
                }`}
                placeholder={input.placeholder}
                trigger={trigger}
                name={input.fieldName}
                required={input?.options?.required || false}
              />
            </div>
          ) : (
            <Fragment key={input.fieldName}>
              {input.className === "am-register-input" ? (
                <div
                  className={`${
                    input.containerClass ? input.containerClass : ""
                  }`}
                >
                  <div className='am-errors-container'>
                    {errors?.[input.fieldName] ? (
                      <label
                        className='am-errors-label'
                        htmlFor={input.fieldName}
                      >
                        {
                          errors?.[input.fieldName]
                            ?.message as unknown as string
                        }
                      </label>
                    ) : null}
                  </div>
                  <input
                    key={`${input.fieldName + key}`}
                    type={input.type}
                    className={`${
                      input.className ? input.className : "am-input"
                    } ${errors[input.fieldName] ? "am-input-error" : ""}`}
                    autoComplete='new-password'
                    placeholder={input.placeholder}
                    id={input.fieldName}
                    {...register(`${input.fieldName}`, {
                      ...input.options,
                      validate:
                        input.fieldName === "confirmPassword"
                          ? matchesPreviousPassword
                          : {},
                    })}
                    name={input.fieldName}
                    onChange={(e) => {
                      if (input.fieldName === "newcode") {
                        e.target.value = e.target.value
                          .replace(/\s/g, "")
                          .trim()
                          .slice(0, 8);
                      }
                      register(input.fieldName).onChange(e);
                    }}
                  />
                  <label htmlFor={input.fieldName} className='am-label'>
                    {input.placeholder}
                  </label>
                </div>
              ) : (
                <div className='am-input-container'>
                  <div className='am-errors-container'>
                    {errors?.[input.fieldName] ? (
                      <label
                        className='am-errors-label'
                        htmlFor={input.fieldName}
                      >
                        {
                          errors?.[input.fieldName]
                            ?.message as unknown as string
                        }
                      </label>
                    ) : null}
                  </div>
                  <input
                    key={`${input.fieldName + key}`}
                    type={input.type}
                    className={`${
                      input.className ? input.className : "am-input"
                    } ${errors[input.fieldName] ? "am-input-error" : ""}`}
                    autoComplete='new-password'
                    placeholder={input.placeholder}
                    id={input.fieldName}
                    {...register(`${input.fieldName}`, {
                      ...input.options,
                      validate:
                        input.fieldName === "confirmPassword"
                          ? matchesPreviousPassword
                          : {},
                    })}
                    name={input.fieldName}
                    onChange={(e) => {
                      if (input.fieldName === "newcode") {
                        e.target.value = e.target.value
                          .replace(/\s/g, "")
                          .trim()
                          .slice(0, 8);
                      }
                      register(input.fieldName).onChange(e);
                    }}
                    onBlur={(e) => {
                      register(input.fieldName).onChange(e);
                    }}
                  />
                  <label htmlFor={input.fieldName} className='am-label'>
                    {input.placeholder}
                  </label>
                </div>
              )}
            </Fragment>
          );
        })}
        {checkboxFields &&
          checkboxFields.map((checkbox, key) => (
            <label
              key={`${checkbox.fieldName + key}`}
              className='am-checkbox'
              htmlFor={checkbox.fieldName}
            >
              <input
                type='checkbox'
                id={checkbox.fieldName}
                {...register(`${checkbox.fieldName}`, checkbox.options)}
                name={checkbox.fieldName}
                className='am-checkbox-input'
              />
              <p
                className={`am-custom-checkbox ${
                  errors[checkbox.fieldName] ? "am-checkbox-error" : ""
                }`}
              />
              <label htmlFor={checkbox.fieldName} className='am-checkbox-label'>
                {checkbox.content}
              </label>
            </label>
          ))}
        {footerLinks && (
          <div className='am-passwords-link'>
            {footerLinks.map((link) => (
              <button
                key={link.label}
                onClick={link.callBack}
                className='am-password-link'
                type='button'
              >
                {link.label}
              </button>
            ))}
          </div>
        )}
        {footerInfo && footerInfo}
      </div>
      <div className='am-submit-button-container'>
        <input
          className={classNames({
            "am-submit-button": true,
          })}
          id='login-button'
          type='submit'
          value={submitText}
        />
      </div>
    </form>
  );
};

export default RightPartModal;
