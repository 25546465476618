import { PriceDetails, PriceDetailsTransformed } from "steps/types/step";

export const priceDetailsToArray = (priceDetails: PriceDetails) => {
  return Object.entries(priceDetails ?? {})
    .map(([key, value]) => ({
      name: key,
      ...value,
    }))
    .filter((fee) => fee.show);
};

export const getPriceDetailsViewMode = (
  fees: PriceDetailsTransformed[],
): "table" | "sentence" => {
  return fees.length === 1 && fees[0].excluded === true ? "sentence" : "table";
};
