import { DateTime } from "luxon";

const getDateFromIso = (date: string) => DateTime.fromISO(date).toISODate();
const getDateFromSeconds = (date: string) =>
  DateTime.fromMillis(Number(date)).toISODate();

export const getDatesFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const startDateParam: string | null = urlParams.get("start")
    ? urlParams.get("start")
    : null;
  const endDateParam: string | null = urlParams.get("start")
    ? urlParams.get("end")
    : null;

  const startDate = startDateParam
    ? getDateFromIso(startDateParam) ||
      getDateFromSeconds(startDateParam) ||
      "1970-01-01"
    : null;
  const endDate = endDateParam
    ? getDateFromIso(endDateParam) ||
      getDateFromSeconds(endDateParam) ||
      "1970-01-01"
    : null;

  return {
    startDate,
    endDate,
  };
};
