/* eslint-disable @typescript-eslint/no-empty-interface */
import React from "react";
import classNames from "classnames";
import { DefaultProps } from "utils/types/defaultProps";

// styles
import "./packsModal.scss";
import { Trans, useTranslation } from "@tecma/i18n";
import { PackCard } from "steps/PackStep/component/PacksPageContent/components/CardPack";
import { PackStepModule } from "steps/PackStep";
import { StepsComponentProps } from "steps/types/step";
import { MobileModal } from "components/MobileModal";

// Required Props
interface PacksModalRequiredProps {}

// Optional Props
interface PacksModalOptionalProps extends DefaultProps {}

// Combined required and optional props to build the full prop interface
export interface PacksModalProps
  extends PacksModalRequiredProps,
    PacksModalOptionalProps,
    StepsComponentProps<PackStepModule> {}

// use the optional prop interface to define the default props
const defaultProps: PacksModalOptionalProps = {
  "data-testid": "spaceConfigurator-packsModal",
};

const PacksModal: React.FC<PacksModalProps> = ({
  className,
  stepConfig,
  onUserSelectionChange,
  userSelection,
  ...rest
}) => {
  const classList = classNames("space-configurator-packs-modal", className);
  const { t } = useTranslation();

  const handleChange = (pack: PackName, added: boolean) => {
    if (added) {
      onUserSelectionChange([...(userSelection || []), pack]);
    } else {
      onUserSelectionChange([
        ...(userSelection ? userSelection.filter((name) => name !== pack) : []),
      ]);
    }
  };

  return (
    <MobileModal
      className={classList}
      step='packs'
      title={
        <Trans
          i18nKey='pack-picker.page-content-title'
          components={{ b: <strong /> }}
        />
      }
      description={t("pack-picker.page-content-description")}
    >
      {stepConfig.map((pack) => (
        <PackCard
          key={pack.name}
          packName={pack.name}
          packImage={pack.image}
          packDescription={pack.description}
          packPrice={pack.startingPrice}
          packDetails={pack.details}
          included={pack.included}
          added={Boolean(
            userSelection?.includes // TODO: why do we need this?! I've no idea
              ? userSelection.includes(pack.name)
              : false,
          )}
          onChange={(newAdded: boolean) => handleChange(pack.name, newAdded)}
        />
      ))}
    </MobileModal>
  );
};

PacksModal.defaultProps = defaultProps as Partial<PacksModalOptionalProps>;

export default React.memo(PacksModal);
