import { Trans, useTranslation } from "@tecma/i18n";
import React from "react";
import { ExtraSpaceStepModule } from "steps/ExtraSpaceStep";
import { getCleanName } from "utils/functions/formatters";
import type { DefaultProps } from "../../../../utils/types/defaultProps";
import type { RecapStepsComponentProps } from "../../../types/step";
import "./extra-space-recap.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExtraSpaceRecapRequiredProps {}

// Optional Props
type ExtraSpaceRecapOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface ExtraSpaceRecapProps
  extends ExtraSpaceRecapRequiredProps,
    ExtraSpaceRecapOptionalProps,
    RecapStepsComponentProps<ExtraSpaceStepModule> {}

const ExtraSpaceRecap: React.FC<ExtraSpaceRecapProps> = ({
  userSelectionDetails,
  buildingName,
}) => {
  const { t } = useTranslation();

  const getSpaceRecapDescription = (spaceID: string) => {
    if (!userSelectionDetails) {
      return null;
    }
    const { included, name, selected, type } = userSelectionDetails[spaceID];

    const getIncludedFallback = (count: number) => {
      const typeName = t(
        [
          `extra-space.${type}.${name}`,
          `${count > 1 ? count : ""} ${getCleanName(name)}`,
        ],
        {
          selected: count,
        },
      );
      const includedLabel = t("extra-space.recap.included", { count });
      return `${typeName} ${includedLabel}`;
    };

    if (included === selected) {
      return (
        <Trans
          i18nKey={t(
            [
              `${buildingName}.extra-space.recap.included.${type}.${name}`,
              `extra-space.recap.included.${type}.${name}`,
              getIncludedFallback(included),
            ],
            {
              count: included,
            },
          )}
          values={{ selected: included }}
        />
      );
    }

    return (
      <Trans
        i18nKey={t(
          [
            `${buildingName}.extra-space.${type}.${name}`,
            `extra-space.${type}.${name}`,
            `${selected > 1 ? selected : ""} ${getCleanName(name)}`,
          ],
          {
            count: selected,
          },
        )}
        values={{ selected }}
      />
    );
  };

  return (
    <ul className='extra-space-recap'>
      {userSelectionDetails && Object.keys(userSelectionDetails).length ? (
        Object.keys(userSelectionDetails).map((spaceID) => (
          <li
            className='extra-space-recap-item'
            key={userSelectionDetails[spaceID].name}
          >
            {getSpaceRecapDescription(spaceID)}
          </li>
        ))
      ) : (
        <li>{t("extra-space.recap.no-storage")}</li>
      )}
    </ul>
  );
};

export default ExtraSpaceRecap;
