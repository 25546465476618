import type { UserSelectionsParams } from "steps/selectors";
import { createAction } from "@reduxjs/toolkit";

const FLOOR_STEP_TYPE = "floorStep";

export const floorStepActions = {
  initConfig: createAction<UserSelectionsParams>(
    `${FLOOR_STEP_TYPE}/initConfigRequest`,
  ),
  updateFloor: createAction<FloorNumber>(
    `${FLOOR_STEP_TYPE}/updateFloorRequest`,
  ),
  updateUnconfirmedFloor: createAction<FloorNumber>(
    `${FLOOR_STEP_TYPE}/updateUnconfirmedFloorRequest`,
  ),
  setIsStepLoading: createAction<boolean>(
    `${FLOOR_STEP_TYPE}/setIsStepLoadingRequest`,
  ),
};
