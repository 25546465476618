import { logger } from "@tecma/logs-fe";
import Client from "../../../client/Client";
import { graphqlClient } from "../../../client/GraphqlClient";

export type GetAvailableSpaceCount = {
  count: number;
  firstEntryAvailable?: {
    startDate: string;
    endDate: string;
  };
  loading?: boolean;
  error?: boolean;
};

export const getAvailableSpacesCount = async (
  projectId: string,
  checkInDate: string,
  checkOutDate: string,
  client = graphqlClient,
) => {
  try {
    const availableSpacesCount = await client.query<{
      getAvailableSpacesCount: GetAvailableSpaceCount;
    }>({
      query: Client.GET_AVAILABLE_SPACE_COUNT,
      ...Client.GET_AVAILABLE_SPACE_COUNT_DEFAULT_OPTIONS(
        projectId,
        checkInDate,
        checkOutDate,
      ),
    });
    return availableSpacesCount.data?.getAvailableSpacesCount;
  } catch (err) {
    logger.error(err);
    throw Error();
  }
};
