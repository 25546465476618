import { createAction } from "@reduxjs/toolkit";
import { LoginByProjectInput } from "client/modules/Auth";
import { CreateRequestInput } from "steps/api";
import { LoginByProjectData, LoginByProjectDataAndId } from "utils/types/auth";
// import { StepsStoreEntity } from "store/domain/entities/steps.entity";

const AUTH_TYPE = "auth";

export const authActions = {
  initAuth: createAction<LoginByProjectDataAndId>(`${AUTH_TYPE}/initAuth`),
  doLogin: createAction<LoginByProjectInput | null>(`${AUTH_TYPE}/doLogin`),
  doLogout: createAction(`${AUTH_TYPE}/doLogout`),
  loggedInStuff: createAction<LoginByProjectDataAndId>(
    `${AUTH_TYPE}/loggedInStuff`,
  ),
  getUserByJWT: createAction<string>(`${AUTH_TYPE}/getUserByJWT`),
  sendNewConfiguration: createAction<CreateRequestInput>(
    `${AUTH_TYPE}/sendNewConfiguration`,
  ),
  startCountDown: createAction<LoginByProjectData>(
    `${AUTH_TYPE}/startCountDown`,
  ),
};
