/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: PlanimetryStepSlice = {
  config: null,
  selectedPlanimetry: null,
  unconfirmedPlanimetry: null,
  isLoading: false,
};

const slice = createSlice({
  name: "planimetryStep",
  initialState,
  reducers: {
    putConfig: (state, action: PayloadAction<PlanimetryStepConfig>) => {
      state.config = action.payload;
    },
    selectedPlanimetry: (state, action: PayloadAction<Planimetry>) => {
      state.selectedPlanimetry = action.payload;
    },
    setUnconfirmedPlanimetry: (state, action: PayloadAction<Planimetry>) => {
      state.unconfirmedPlanimetry = action.payload;
    },
    resetPlanimetry: (state) => {
      state.selectedPlanimetry = null;
      state.unconfirmedPlanimetry = null;
    },
    setIsStepLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

const stepConfigActions = slice.actions;

const { reducer } = slice;

export const planimetryStepSlice = {
  stepConfigActions,
  reducer,
};
