import type { PayloadAction } from "@reduxjs/toolkit";
import { put, takeEvery } from "redux-saga/effects";
import { extraSpaceStepSlice } from "../state/slice";
import { extraSpaceStepActions } from "./actions";
import { genericStepActions } from "../../actions/generic.actions";
import type { UserSelectionsParams } from "../../selectors";
import { getExtraSpacesByUserSelection } from "./api";
import { userSelectionsToDTO } from "../../api";

function* initConfigSaga(
  userSelectionsParams: PayloadAction<UserSelectionsParams>,
): any {
  yield put(extraSpaceStepSlice.stepConfigActions.setIsStepLoading(true));
  const userSelectionsDTO = userSelectionsToDTO({
    userSelections: userSelectionsParams.payload.userSelections,
    checkInDate: userSelectionsParams.payload.checkInDate,
    checkOutDate: userSelectionsParams.payload.checkOutDate,
    complex: userSelectionsParams.payload.complex,
  });

  const extraSpacesConfig: ExtraSpaceConfig =
    yield getExtraSpacesByUserSelection(
      userSelectionsParams.payload.projectId,
      userSelectionsDTO,
    );

  const extraSpaces = extraSpacesConfig.options.reduce(
    (accExtraSpaces, { spaces }) => {
      return [...accExtraSpaces, ...spaces];
    },
    [] as Space[],
  );

  const includedExtraSpace = extraSpaces.reduce<ExtraSpacesUserSelection>(
    (accIncludedSpace, currSpace) => {
      if (currSpace.included) {
        return {
          ...accIncludedSpace,
          [currSpace.id]: currSpace.included,
        };
      }
      return accIncludedSpace;
    },
    {},
  );

  yield put(extraSpaceStepSlice.stepConfigActions.putConfig(extraSpacesConfig));
  yield put(
    extraSpaceStepSlice.stepConfigActions.setSelectedExtraSpaces(
      includedExtraSpace,
    ),
  );
  yield put(genericStepActions.initConfigCompleted());
  yield put(extraSpaceStepSlice.stepConfigActions.setIsStepLoading(false));
}

export function* extraSpaceStepRootInitSaga() {
  yield takeEvery(extraSpaceStepActions.initConfig, initConfigSaga);
}
