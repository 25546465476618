import React from "react";
import { Accordion } from "@tecma/ui";
import { DefaultProps } from "utils/types/defaultProps";

import { useDevice } from "hooks/device/device";
import classNames from "classnames";
import "./steps-wizard.scss";

interface StepsWizardRequiredProps {
  children?: React.ReactElement[];
  title?: string;
}
// Optional Props
type StepsWizardOptionalProps = DefaultProps;

export interface StepsWizardProps
  extends StepsWizardRequiredProps,
    StepsWizardOptionalProps {}

const StepsWizard = React.forwardRef<HTMLDivElement, StepsWizardProps>(
  ({ title, children }, ref) => {
    const device = useDevice();
    const stepsWizardClassList = classNames("space-configurator-steps-wizard", {
      "is-mobile": device.type === "mobile",
    });

    return (
      <div className={stepsWizardClassList}>
        {device.type === "desktop" && (
          <p className='steps-wizard-title-desktop'>{title}</p>
        )}

        {children && (
          <Accordion
            className='space-configurator-steps-wizard-accordion'
            ref={ref}
          >
            {children}
          </Accordion>
        )}
      </div>
    );
  },
);

export default StepsWizard;
