import PlanimetryStep from "./component/PlanimetryStep";
import { PlanimetryRecap } from "./component/PlanimetryRecap";
import { planimetryStepActions } from "./sagas/actions";
import { planimetryStepRootInitSaga } from "./sagas/saga";
import { planimetryStepSlice } from "./state/slice";
import { planimetryStepSelectors } from "./state/selectors";

import type { StepModule } from "../types/step";
import { PlanimetryPageContent } from "./component/PlanimetryPageContent";
import PlanimetryRecapApartment from "./component/PlanimetryRecap/PlanimetryRecapApartment";

export type PlanimetryStepModule = StepModule<
  PlanimetryStepSlice,
  PlanimetryStepConfig,
  Planimetry,
  Planimetry
>;

export const module: PlanimetryStepModule = {
  type: "PLANIMETRY_STEP",
  name: "planimetry-picker",
  icon: "layout",
  priceCalculationMode: "REPLACEMENT",
  stepRenderer: PlanimetryStep,
  recapStepRender: PlanimetryRecap,
  recapStepAdditionalRender: PlanimetryRecapApartment,
  configSelector: planimetryStepSelectors.selectConfig,
  userSelectionSelector: planimetryStepSelectors.selectUserSelection,
  unconfirmedUserSelectionSelector:
    planimetryStepSelectors.selectUnconfirmedUserSelection,
  userSelectionDetailSelector:
    planimetryStepSelectors.selectUserSelectionDetail,
  resetUserSelectionAction:
    planimetryStepSlice.stepConfigActions.resetPlanimetry,
  userSelectionPriceSelector: planimetryStepSelectors.selectUserSelectionPrice,
  userSelectionPriceDetailsSelector:
    planimetryStepSelectors.selectUserSelectionPriceDetails,
  updateUserSelectionAction: planimetryStepActions.updatePlanimetry,
  updateUnconfirmedUserSelectionAction:
    planimetryStepActions.updateUnconfirmedPlanimetry,
  initConfigAction: planimetryStepActions.initConfig,
  stepReducer: planimetryStepSlice.reducer,
  initSaga: planimetryStepRootInitSaga,
  stepPageContentRenderer: PlanimetryPageContent,
  isUserSelectionModified: planimetryStepSelectors.isUserSelectionModified,
  areOptionsAvailableSelector:
    planimetryStepSelectors.areOptionsAvailableSelector,
  setStepLoading: planimetryStepSlice.stepConfigActions.setIsStepLoading,
  isStepLoading: planimetryStepSelectors.isStepLoading,
};
