import { packStepSelectors } from "steps/PackStep/state/selectors";
import { StepModule } from "../types/step";
import PacksPageContent from "./component/PacksPageContent/PacksPageContent";
import PackStep from "./component/PackStep";
import { PacksRecap } from "./component/PacksRecap";
import { packStepActions } from "./sagas/actions";
// eslint-disable-next-line import/no-cycle
import { packStepRootInitSaga } from "./sagas/saga";
import { packStepSlice } from "./state/slice";
import { PacksModal } from "./component/PacksModal";

export type PackStepModule = StepModule<
  PackStepSlice,
  Pack[],
  PackName[],
  Pack[]
>;

export const module: PackStepModule = {
  type: "PACK_STEP",
  name: "pack-picker",
  icon: "options",
  priceCalculationMode: "ADDITION",
  stepRenderer: PackStep,
  updateUserSelectionAction: packStepSlice.stepConfigActions.setSelectedPacks,
  updateUnconfirmedUserSelectionAction:
    packStepSlice.stepConfigActions.setUnconfirmedPacks,
  stepPageContentRenderer: PacksPageContent,
  configSelector: packStepSelectors.selectConfig,
  userSelectionSelector: packStepSelectors.selectUserSelection,
  unconfirmedUserSelectionSelector:
    packStepSelectors.selectUnconfirmedUserSelection,
  userSelectionDetailSelector: packStepSelectors.selectUserSelectionDetail,
  resetUserSelectionAction: packStepSlice.stepConfigActions.resetSelectedPacks,
  userSelectionPriceSelector: packStepSelectors.selectUserSelectionPrice,
  userSelectionPriceDetailsSelector:
    packStepSelectors.selectUserSelectionPriceDetails,
  stepReducer: packStepSlice.reducer,
  initConfigAction: packStepActions.initConfig,
  initSaga: packStepRootInitSaga,
  recapStepRender: PacksRecap,
  mobileStepPageContentCarouselImagesSelector:
    packStepSelectors.stepPageContentCarouselImagesSelector,
  mobileModalRenderer: PacksModal,
  mobileModalKey: "packs",
  isUserSelectionModified: packStepSelectors.isUserSelectionModified,
  areOptionsAvailableSelector: packStepSelectors.areOptionsAvailableSelector,
  setStepLoading: packStepSlice.stepConfigActions.setIsStepLoading,
  isStepLoading: packStepSelectors.isStepLoading,
};
