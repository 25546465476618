import React from "react";
import { DefaultProps } from "utils/types/defaultProps";
import { RecapStepsComponentProps } from "steps/types/step";
import { ComplexStepModule } from "steps/ComplexStep";
import { useTranslation } from "@tecma/i18n";
import { getCleanName } from "utils/functions/formatters";

// styles
import "./complexRecap.scss";

// Required Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ComplexRecapRequiredProps {}

// Optional Props
type ComplexRecapOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface ComplexRecapProps
  extends ComplexRecapRequiredProps,
    ComplexRecapOptionalProps,
    RecapStepsComponentProps<ComplexStepModule> {}

// use the optional prop interface to define the default props
const defaultProps: ComplexRecapOptionalProps = {
  "data-testid": "space-configurator-complexRecap",
};

const ComplexRecap: React.FC<ComplexRecapProps> = ({
  userSelectionDetails,
}) => {
  const { t } = useTranslation();

  return (
    <p className='complex-recap'>
      {userSelectionDetails &&
        t([
          `buildings.${userSelectionDetails.name}`,
          getCleanName(userSelectionDetails.name),
        ])}
    </p>
  );
};

ComplexRecap.defaultProps = defaultProps as Partial<ComplexRecapOptionalProps>;

export default React.memo(ComplexRecap);
