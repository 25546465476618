import React, { useRef } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "@tecma/i18n";
import { Accordion } from "@tecma/ui";
import classNames from "classnames";
import { useDevice } from "hooks/device/device";

import type { DefaultProps } from "utils/types/defaultProps";
import { STEP_CLOSING_TIME, STEP_OPENING_TIME } from "utils/settings";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { useStoreRepositories } from "../../../../hooks/storeRepositories/useStoreRepositories";
import { useStepRegistry } from "../../../../steps/hooks/useStepRegistry";
import { StepWizardHeader } from "../StepWizardHeader";
import { applicationActions } from "../../../../store/sagas/application/application.actions";
import { applicationStateActions } from "../../../../store/slices/applicationState/slice";

import "./step-wizard-section.scss";

interface StepWizardSectionRequiredProps {
  stepId: EntityId;
  stepIndex: number;
  isCompleted: boolean;
  isActive: boolean;
}
// Optional Props
interface StepWizardSectionOptionalProps extends DefaultProps {
  stepsCount?: number;
}

export interface StepWizardSectionProps
  extends StepWizardSectionRequiredProps,
    StepWizardSectionOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: StepWizardSectionOptionalProps = {
  "data-testid": "space-configurator-stepWizardSection",
};

const StepWizardSection: React.FC<StepWizardSectionProps> = ({
  stepId,
  stepIndex,
  isCompleted,
  isActive,
  stepsCount,
}) => {
  const { StepsEntityRepository } = useStoreRepositories();
  const contentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { type } = useDevice();

  const step = StepsEntityRepository?.selectById(stepId as string);
  const isOpen = useSelector(() => step?.isOpen());
  const complex = useSelector(applicationStateSelectors.selectComplex);
  const zone = useSelector(applicationStateSelectors.selectZone);

  const farthermostStepIndex = useSelector(
    applicationStateSelectors.selectFarthestStepIndex,
  );
  const currentStepIndex = useSelector(
    applicationStateSelectors.selectCurrentStepIndex,
  );

  const classList = classNames("step-wizard-section", {
    "is-active": isActive,
    "is-completed": isCompleted,
  });

  if (!step) {
    throw new Error("Step is missing");
  }

  const {
    stepModule,
    StepRenderer,
    stepConfig,
    userSelection,
    handleUserSelectionChange,
    isUserSelectionComplete,
    unconfirmedUserSelection,
    isUserSelectionModified,
    handleUnconfirmedUserSelectionChange,
    isStepLoading,
  } = useStepRegistry(step);

  const isFarthestStepIndex = stepIndex === farthermostStepIndex;
  const isCurrentStepIndex = stepIndex === currentStepIndex;

  const handleOnClick = () => {
    if (type === "mobile") {
      if (unconfirmedUserSelection) {
        dispatch(applicationActions.stepClickRequested(stepIndex));
      } else {
        dispatch(
          applicationActions.openSpecificCompletedStepRequested(stepIndex),
        );
      }
    } else {
      dispatch(applicationActions.stepClickRequested(stepIndex));
    }
  };

  const setIsZoomed = (zoomed: boolean) => {
    if (zoomed) {
      dispatch(applicationStateActions.setZoomedStepIndex(stepIndex));
    } else {
      dispatch(applicationStateActions.closeZoom());
    }
  };

  const handleUserSelection =
    stepIndex === stepsCount || isFarthestStepIndex
      ? userSelection
      : unconfirmedUserSelection;

  const handleStepUserSelectionChange =
    stepIndex === stepsCount || isFarthestStepIndex
      ? handleUserSelectionChange
      : handleUnconfirmedUserSelectionChange;

  return (
    <Accordion.Content
      className={classList}
      headerComponent={
        <StepWizardHeader
          icon={stepModule.icon}
          stepNumber={step.getOrder()}
          title={
            <Trans
              i18nKey={`${stepModule.name}.title`}
              components={{ b: <strong /> }}
            />
          }
          isCompleted={isCompleted}
          isLoading={isStepLoading}
          onClick={handleOnClick}
          isOpen={isOpen}
          isActive={isActive}
          isFarthestStepIndex={isFarthestStepIndex}
          isCurrentStepIndex={isCurrentStepIndex}
          isUserSelectionModified={isUserSelectionModified}
          disabled={!isUserSelectionComplete}
        />
      }
      open={isOpen}
      ref={contentRef}
      animationTimeMillis={STEP_OPENING_TIME}
      animationExitTimeMillis={STEP_CLOSING_TIME}
    >
      {stepConfig && (
        <StepRenderer
          stepConfig={stepConfig}
          onUserSelectionChange={handleStepUserSelectionChange}
          userSelection={handleUserSelection}
          confirmedUserSelection={
            !isFarthestStepIndex ? userSelection : undefined
          }
          complex={complex}
          zone={zone}
          setZoomed={setIsZoomed}
        />
      )}
    </Accordion.Content>
  );
};

StepWizardSection.defaultProps =
  defaultProps as Partial<StepWizardSectionOptionalProps>;

export default StepWizardSection;
