import { selectUserInteractionById } from "../../slices/userInteractions/selectors";
import { EntityGenericUserInteractionRecord } from "../../slices/userInteractions/slice";
import { UserInteractEntitiesType } from "../../enums/entities";
import { IStoreEntity, IEntity } from "./IEntity";
import { userInteractionSagaActions } from "../../sagas/userInteractions/userInteractions.actions";

export abstract class IUserInteractibleEntity<
  T extends IStoreEntity,
> extends IEntity<T> {
  public abstract readonly entityType: UserInteractEntitiesType;

  public dispatchInteraction(interaction: EntityGenericUserInteractionRecord) {
    this.store.dispatch(
      userInteractionSagaActions.appendedInteraction({
        interaction: {
          ...interaction,
          id: this.id,
        },
        interactionType: this.entityType,
      }),
    );
  }

  public getInteractions(): EntityGenericUserInteractionRecord {
    return selectUserInteractionById(
      this.store.getState(),
      this.id,
      this.entityType,
    );
  }

  public resetInteractions() {
    this.store.dispatch(
      userInteractionSagaActions.removedInteraction({
        interactionId: this.id,
        interactionType: this.entityType,
      }),
    );
  }
}
