import { logger } from "@tecma/logs-fe";
import Client from "../../../../client/Client";
import { detectUrl } from "../../../../utils/functions/detectUrl";
import { graphqlClient } from "../../../../client/GraphqlClient";

export const getSpaceConfiguratorApplicationInfo = async (
  client = graphqlClient,
) => {
  try {
    const applicationInfo = await client.query({
      query: Client.GET_APPLICATION_CONFIGURATION_DATA_TO_RETRIEVE,
      ...Client.GET_APPLICATION_CONFIGURATION_OPTIONS(),
    });
    return applicationInfo.data?.getSpaceConfiguratorApplicationInfo;
  } catch (err) {
    logger.error(err);
    throw Error();
  }
};

export const getInitialInfo = async (
  project_id: string,
  input: {
    checkInDate: string;
    checkOutDate: string;
    complex: string | null;
    zone: string | null;
  },
  client = graphqlClient,
) => {
  try {
    const initialInfo = await client.query({
      query: Client.GET_INITIAL_INFO_DATA_TO_RETRIEVE,
      ...Client.GET_INITIAL_INFO_OPTIONS(project_id, input),
    });
    return initialInfo.data?.getInitialInfo;
  } catch (err) {
    logger.error(err);
    throw Error();
  }
};

export const getTokenValidation = async (
  projectId: string,
  token: string,
  client = graphqlClient,
) => {
  const options = {
    projectId,
    token,
  };
  try {
    const tokenValidation = await client.query({
      query: Client.GET_USER_CONFIRMATION_BY_TOKEN,
      ...Client.GET_USER_CONFIRMATION_BY_TOKEN_OPTIONS(options),
    });
    return tokenValidation.data?.getUserConfirmationByToken;
  } catch (err) {
    logger.error(err);
    throw Error();
  }
};

/**
 * @deprecated use getSpaceConfiguratorApplicationInfo instead
 */
export const getProjectInfo = async (client = graphqlClient) => {
  try {
    const userInfo = await client.query({
      query: Client.GET_PROJECT_INFO,
      ...Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(detectUrl()),
    });
    return userInfo.data?.getSpaceConfiguratorApplicationInfo;
  } catch (err) {
    logger.error(err);
    throw Error();
  }
};
