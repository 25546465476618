import { createAction } from "@reduxjs/toolkit";
import type { UserSelectionsParams } from "../../selectors";

const EXTRA_SPACE_TYPE_SELECTOR_TYPE = "extraSpaceStep";

export const extraSpaceStepActions = {
  initConfig: createAction<UserSelectionsParams>(
    `${EXTRA_SPACE_TYPE_SELECTOR_TYPE}/initConfig`,
  ),
  setIsStepLoading: createAction<boolean>(
    `${EXTRA_SPACE_TYPE_SELECTOR_TYPE}/setIsStepLoadingRequest`,
  ),
};
