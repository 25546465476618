import { gql } from "@apollo/client";

export interface UserInput {
  firstName?: string;
  lastName?: string;
  password?: string;
  email: string;
  client?: string;
  project_ids?: string[];
  language?: string;
  role?: string;
  hasGoogleToken?: boolean;
  tel?: string;
  myhome_version?: string;
  vendorColor?: string;
}

const USER_DATA_TO_RETRIEVE = `
    id
    firstName
    lastName
    email
    client
    role
`;

const CLIENT_DATA_TO_RETRIEVE = `
    id
    firstName
    lastName
    email
    user
    interested_appartments {
      appartment
    }
    selected_appartments {
      appartment
      status
    }
`;

export const GET_ALL_USERS = gql`
    query getAllUsers($project_id :ID!) {
      getAllUsers(project_id: $project_id) {
        ${USER_DATA_TO_RETRIEVE}
        }
    }
`;

export const GET_USER_BY_CLIENT_ID = gql`
    query getUserByClientId($project_id :ID!, $clientId: ID!) {
      getUserByClientId(project_id: $project_id, clientId: $clientId) {
        ${USER_DATA_TO_RETRIEVE}
        }
    }
`;

export const ACTIVATE_MYHOME_MUTATION = gql`
  mutation createClientUser($project_id :ID,$input: UserInput!, $id: ID!) {
    createClientUser(project_id: $project_id, input: $input, id: $id) {
      ${USER_DATA_TO_RETRIEVE}
        }
      }
`;

export const GET_CLIENT = gql`
    query getClient($project_id :ID!, $id: ID!) {
        getClient(project_id: $project_id, id: $id) {
        ${CLIENT_DATA_TO_RETRIEVE}
        }
    }
`;

export const GET_USER_INFO = gql`
  query getUserByJWT($project_id: ID!) {
    getUserByJWT(project_id: $project_id) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

export const GET_USER_BY_CLIENT_ID_OPTIONS = (
  project_id: string,
  clientId: string,
) => {
  return {
    variables: {
      project_id,
      clientId,
    },
    fetchPolicy: "no-cache",
  };
};

export const ACTIVATE_MYHOME_OPTIONS = (
  project_id: string,
  id: string,
  input: UserInput,
) => {
  return {
    variables: {
      project_id,
      id,
      input,
    },
    fetchPolicy: "no-cache",
  };
};

export const GET_CLIENT_DEFAULT_OPTIONS = (project_id: string, id: string) => {
  return {
    variables: {
      project_id,
      id,
    },
  };
};

export const GET_USER_INFO_DEFAULT_OPTIONS = (
  pId: string,
  skipCondition?: boolean,
) => {
  return {
    variables: {
      project_id: pId,
    },
    skip: skipCondition,
  };
};
