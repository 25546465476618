import React from "react";
import { DefaultProps } from "utils/types/defaultProps";
import classNames from "classnames";
import { Trans, useTranslation } from "@tecma/i18n";
import InfoMessage from "components/StepsWizard/components/InfoMessage/InfoMessage";
import { ExtraSpaceItem } from "../ExtraSpaceItem";

// styles
import "./extra-space-type.scss";

// Required Props
interface ExtraSpaceTypeRequiredProps {
  extraSpace: ExtraSpace;
  onChange: (id: string, value: number) => void;
  userSelection: { [key: string]: number };
}

// Optional Props
interface ExtraSpaceTypeOptionalProps extends DefaultProps {
  complex?: string;
}

// Combined required and optional props to build the full prop interface
interface ExtraSpaceTypeProps
  extends ExtraSpaceTypeRequiredProps,
    ExtraSpaceTypeOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: ExtraSpaceTypeOptionalProps = {
  "data-testid": "space-configurator-extraSpaceType",
};

const ExtraSpaceType: React.FC<ExtraSpaceTypeProps> = ({
  className,
  extraSpace,
  userSelection,
  onChange,
  complex,
}) => {
  const { t } = useTranslation();

  const classList = classNames("extra-space-type", className);

  return (
    <div className={classList}>
      {extraSpace.type === "boxes" && (
        <InfoMessage
          message={t("extra-space.info-message.message")}
          tooltipMessage={
            <Trans
              i18nKey='extra-space.info-message.tooltip-message'
              components={{ 1: <span /> }}
            />
          }
        />
      )}

      {extraSpace.spaces.map((space) => {
        const value = userSelection?.[space.id] || 0;
        return (
          <ExtraSpaceItem
            key={space.id}
            space={space}
            type={extraSpace.type}
            onChange={(extraSpaceCount: number) =>
              onChange(space.id, extraSpaceCount)
            }
            value={value}
            complex={complex}
          />
        );
      })}
    </div>
  );
};

ExtraSpaceType.defaultProps =
  defaultProps as Partial<ExtraSpaceTypeOptionalProps>;

export default React.memo(ExtraSpaceType);
