import React, { useState } from "react";
import classNames from "classnames";
import { DateTime } from "luxon";
import { getUpdatedDates, getDisabledArrows } from "@tecma/date-picker-utils";
import { Button, Icon, Spinner } from "@tecma/ui";
import i18n, { Trans, useTranslation } from "@tecma/i18n";

import { DefaultProps } from "utils/types/defaultProps";
import DatePicker from "components/DatePicker/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { getDateSelectorState } from "store/slices/dateSelectorState/slice";
import { stepsStateSelectors } from "steps/selectors";
import { dateSelectorActions } from "store/sagas/availabilitySelector/availabilitySelector.actions";
import {
  applicationStateActions,
  getApplicationState,
} from "store/slices/applicationState/slice";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { applicationActions } from "store/sagas/application/application.actions";
import { logger } from "@tecma/logs-fe";
import { useDevice } from "hooks/device/device";
import {
  getAvailabilityLabel,
  isLastFewApartments,
} from "utils/functions/availability";

// styles
import "./datePickerSection.scss";

// Required Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DatePickerSectionRequiredProps {}

// Optional Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DatePickerSectionOptionalProps extends DefaultProps {}

// Combined required and optional props to build the full prop interface
export interface DatePickerSectionProps
  extends DatePickerSectionRequiredProps,
    DatePickerSectionOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: DatePickerSectionOptionalProps = {
  "data-testid": "space-configurator-datePickerSection",
};

const now = DateTime.fromJSDate(new Date()).toISODate();

const DatePickerSection: React.FC<DatePickerSectionProps> = ({
  className,
  ...rest
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Known bug, keep this for allow update on language change
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const device = useDevice();
  const [isOpen, setIsOpen] = useState(false);
  const datesState = useSelector(getDateSelectorState);
  const userSelections: any = useSelector(
    stepsStateSelectors.selectUserSelections,
  );
  const farthermostStepIndex = useSelector(
    applicationStateSelectors.selectFarthestStepIndex,
  );

  const { startDate, endDate, availableSpaces } = datesState;

  const isUserSelectionsEmpty = () => {
    return !Object.keys(userSelections).some((step: string) => {
      return userSelections[step] && userSelections[step].length;
    });
  };

  const onDatesChange = (newDates: [string, string]) => {
    if (isUserSelectionsEmpty() || farthermostStepIndex === 1) {
      dispatch(
        dateSelectorActions.datesUpdateRequested({
          startDate: newDates[0],
          endDate: newDates[1],
        }),
      );
    } else {
      setIsOpen(false);
      dispatch(
        applicationStateActions.openAlert({
          type: "warning",
          props: {
            confirmButtonText: "alert.reset.confirm",
            cancelButtonText: "alert.reset.abort",
            content: "alert.reset.content-dates-reset",
            confirmButtonAction: dateSelectorActions.datesUpdateRequested({
              startDate: newDates[0],
              endDate: newDates[1],
            }),
            cancelButtonAction: applicationActions.closeAlertRequested(),
          },
        }),
      );
    }
  };

  const applicationState = useSelector(getApplicationState);
  const rentConstraints = applicationState.projectInfo?.config?.rentConstraints;

  const availabilityLabel = getAvailabilityLabel(availableSpaces);

  const handleDatesChange = (
    datesUpdateEventPayload: DatesUpdateEventPayload,
  ) => {
    if (!rentConstraints) {
      logger.error("Missing rent constraints");
      return;
    }

    const updatedDates = getUpdatedDates(
      [startDate, endDate],
      datesUpdateEventPayload,
      rentConstraints,
    );

    const [newStart, newEnd] = updatedDates;

    onDatesChange([newStart, newEnd]);
  };

  const handleToggle = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const classList = classNames(
    "space-configurator-datePickerSection",
    className,
    { isOpen },
  );
  const datePickerClassList = classNames("date-picker-section-container", {
    isOpen,
  });

  const notLastApartments = !isLastFewApartments(
    Number(availabilityLabel?.value?.apartments),
  );

  const availabilityInfoClassList = classNames("availability-info", {
    manyApartments: notLastApartments,
  });

  const recapStartDate = DateTime.fromISO(startDate).toFormat("d MMM, yyyy", {
    locale: i18n.language,
  });
  const recapEndDate = DateTime.fromISO(endDate).toFormat("d MMM, yyyy", {
    locale: i18n.language,
  });

  const isFloatingPicker =
    device.typeDetailed === "desktop_s" ||
    device.typeDetailed === "tablet" ||
    device.typeDetailed === "mobile";

  return (
    <>
      {device.typeDetailed === "mobile" && isOpen && (
        <div className='date-picker-floating-open-background' />
      )}
      <div className={classList} {...rest}>
        {isFloatingPicker && (
          <Button
            onClick={() => handleToggle()}
            iconName='calendar'
            className='date-picker-section-recap'
          >
            <p>
              <Trans
                i18nKey='date-picker.recap-date'
                values={{ start: recapStartDate, end: recapEndDate }}
                components={{ 1: <span /> }}
              />
            </p>
            <Icon
              iconName={device.type === "tablet" ? "arrow-right" : "arrow-down"}
              className='arrow'
            />
          </Button>
        )}
        <div className={datePickerClassList}>
          {rentConstraints && (
            <DatePicker
              dates={[startDate, endDate]}
              handleChange={handleDatesChange}
              disabledArrows={getDisabledArrows(
                [startDate, endDate],
                rentConstraints,
                now,
              )}
            />
          )}
          {!availableSpaces.error && (
            <p className={availabilityInfoClassList}>
              {!notLastApartments && <Icon iconName='warning' size='small' />}
              <Trans
                t={t}
                i18nKey={`date-picker.${availabilityLabel.label}`}
                values={availabilityLabel?.value}
                components={{
                  1: <span />,
                }}
              />
              {availableSpaces.loading && (
                <Spinner
                  className='availability-loader'
                  size='small'
                  type='dotted-circle'
                />
              )}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

DatePickerSection.defaultProps =
  defaultProps as Partial<DatePickerSectionOptionalProps>;

export default React.memo(DatePickerSection);
