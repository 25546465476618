import type { UserSelectionsParams } from "steps/selectors";
import { createAction } from "@reduxjs/toolkit";

const PLANIMETRY_STEP_TYPE = "planimetryStep";

export const planimetryStepActions = {
  initConfig: createAction<UserSelectionsParams>(
    `${PLANIMETRY_STEP_TYPE}/initConfigRequest`,
  ),
  updatePlanimetry: createAction<Planimetry>(
    `${PLANIMETRY_STEP_TYPE}/updatePlanimetryRequest`,
  ),
  updateUnconfirmedPlanimetry: createAction<Planimetry>(
    `${PLANIMETRY_STEP_TYPE}/updateUnconfirmedPlanimetryRequest`,
  ),
  setIsStepLoading: createAction<boolean>(
    `${PLANIMETRY_STEP_TYPE}/setIsStepLoadingRequest`,
  ),
};
