/**
 * This file will export an object containing the project route path
 */
const ROUTES = ["root"] as const;

type RouteType = { [K in typeof ROUTES[number]]: string };

const paths: RouteType = Object.freeze({
  root: "/",
});

export default paths;
