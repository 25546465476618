/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { DefaultProps } from "utils/types/defaultProps";
import { Icon, Tooltip, ValuePicker } from "@tecma/ui";
import { Trans, useTranslation } from "@tecma/i18n";
import { getCleanName } from "utils/functions/formatters";
import { getExtraSpaceOptions } from "utils/functions/extraSpace";

// styles
import "./extra-space-item.scss";

// Required Props
interface ExtraSpaceItemRequiredProps {
  space: Space;
  type: string;
  value: number;
  onChange: (value: number) => void;
}

// Optional Props
interface ExtraSpaceItemOptionalProps extends DefaultProps {
  complex?: string;
}

// Combined required and optional props to build the full prop interface
export interface ExtraSpaceItemProps
  extends ExtraSpaceItemRequiredProps,
    ExtraSpaceItemOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: ExtraSpaceItemOptionalProps = {
  "data-testid": "space-configurator-extraSpaceItem",
};

const ExtraSpaceItem: React.FC<ExtraSpaceItemProps> = ({
  space,
  type,
  value,
  onChange,
  complex,
  ...rest
}) => {
  const { t } = useTranslation();
  const { availability, included, name } = space;

  return (
    <div className='extra-space-item extra-space-item-box' {...rest}>
      <div className='extra-space-item-label'>
        {!!included && (
          <Tooltip
            className='extra-space-item-tooltip'
            arrow
            position='bottom-left'
            trigger={<Icon iconName='warning' size='small' />}
            triggerEvent='hover'
          >
            <Trans
              i18nKey={`extra-space.${type}.tooltip-message`}
              components={{ 1: <span /> }}
            />
          </Tooltip>
        )}
        <label className='extra-space-item-name' htmlFor='extraSpacesSelection'>
          <Trans
            i18nKey={t([
              `${complex}.extra-space.${type}.${name}`,
              `extra-space.${type}.${name}`,
              getCleanName(name),
            ])}
            values={{ selected: null }}
          />
        </label>
      </div>
      <div className='extra-space-item-availability' id='extraSpacesSelection'>
        {availability !== 0 && (
          <ValuePicker
            leftIcon='minus'
            rightIcon='plus'
            onChange={onChange}
            value={value}
            options={getExtraSpaceOptions(availability, included)}
          />
        )}
        {availability === 0 && <p>{t("extra-space.item.sold-out")}</p>}
      </div>
    </div>
  );
};

ExtraSpaceItem.defaultProps =
  defaultProps as Partial<ExtraSpaceItemOptionalProps>;

export default React.memo(ExtraSpaceItem);
