import SpaceTypeStep from "./component/SpaceTypeStep";
import { SpaceTypeRecap } from "./component/SpaceTypeRecap";
import { spaceTypeSelectorActions } from "./sagas/actions";
import { spaceTypeStepRootInitSaga } from "./sagas/saga";
import { spaceTypeStepSlice } from "./state/slice";
import { spaceTypeStepSelectors } from "./state/selectors";

import type { StepModule } from "../types/step";
import type { SpaceTypeStepSlice } from "./state/slice";

export type SpaceTypeStepModule = StepModule<
  SpaceTypeStepSlice,
  SpaceTypeStepConfig,
  SpaceTypeName,
  SpaceType
>;

export const module: SpaceTypeStepModule = {
  type: "SPACE_TYPE_STEP",
  name: "space-picker",
  icon: "home",
  priceCalculationMode: "REPLACEMENT",
  stepRenderer: SpaceTypeStep,
  recapStepRender: SpaceTypeRecap,
  firstDateAvailableSelector:
    spaceTypeStepSelectors.selectUserSelectionFirstDate,
  updateUserSelectionAction: spaceTypeSelectorActions.updateSpaceType,
  updateUnconfirmedUserSelectionAction:
    spaceTypeSelectorActions.updateUnconfirmedSpaceType,
  resetUserSelectionAction: spaceTypeStepSlice.stepConfigActions.resetSpaceType,
  userSelectionSelector: spaceTypeStepSelectors.selectUserSelection,
  unconfirmedUserSelectionSelector:
    spaceTypeStepSelectors.selectUnconfirmedUserSelection,
  userSelectionDetailSelector: spaceTypeStepSelectors.selectUserSelectionDetail,
  configSelector: spaceTypeStepSelectors.selectConfig,
  stepReducer: spaceTypeStepSlice.reducer,
  initConfigAction: spaceTypeSelectorActions.initConfig,
  updateConfigAction: spaceTypeSelectorActions.updateConfig,
  checkDataChangesAction: spaceTypeSelectorActions.checkDataChanges,
  userSelectionPriceSelector: spaceTypeStepSelectors.selectUserSelectionPrice,
  userSelectionPriceDetailsSelector:
    spaceTypeStepSelectors.selectUserSelectionPriceDetails,
  initSaga: spaceTypeStepRootInitSaga,
  stepPageContentCarouselImagesSelector:
    spaceTypeStepSelectors.stepPageContentCarouselImagesSelector,
  areOptionsAvailableSelector:
    spaceTypeStepSelectors.areOptionsAvailableSelector,
  isUserSelectionCompleteSelector:
    spaceTypeStepSelectors.isUserSelectionSoldOut,
  setStepLoading: spaceTypeStepSlice.stepConfigActions.setIsStepLoading,
  isStepLoading: spaceTypeStepSelectors.isStepLoading,
};
