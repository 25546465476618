import React from "react";
import classNames from "classnames";
import { DefaultProps } from "utils/types/defaultProps";
import { Button } from "@tecma/ui";

// styles
import "./picker.scss";

// Required Props
interface PickerRequiredProps {
  selectedValue: string;
  onPrevClick: () => void;
  onNextClick: () => void;
}

// Optional Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PickerOptionalProps extends DefaultProps {
  disabledArrows?: DateChangeDirection[];
}

// Combined required and optional props to build the full prop interface
export interface PickerProps extends PickerRequiredProps, PickerOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: PickerOptionalProps = {
  "data-testid": "spaceConfigurator-picker",
};

const Picker: React.FC<PickerProps> = ({
  className,
  selectedValue,
  onPrevClick,
  onNextClick,
  disabledArrows,
  ...rest
}) => {
  const classList = classNames("spaceConfigurator-picker", className);

  return (
    <div className={classList} {...rest}>
      <div className='picker-value-container'>{selectedValue}</div>
      <div className='picker-buttons-container'>
        <Button
          size='small'
          iconName='arrow-up'
          onClick={onNextClick}
          disabled={disabledArrows?.includes("up")}
        />
        <Button
          size='small'
          iconName='arrow-down'
          onClick={onPrevClick}
          disabled={disabledArrows?.includes("down")}
        />
      </div>
    </div>
  );
};

Picker.defaultProps = defaultProps as Partial<PickerOptionalProps>;

export default React.memo(Picker);
