import { Button, Icon } from "@tecma/ui";
import classNames from "classnames";
import { isEqual } from "lodash";
import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Trans, useTranslation } from "@tecma/i18n";
import { Carousel } from "components/Carousel";
import { DatePickerSection } from "components/DatePickerSection";
import PageCentralContent from "components/PageCentralContent/PageCentralContent";
import { RecapSection } from "components/RecapSection";
import { SpaceInfo } from "components/SpaceInfo";
import { StepsWizard } from "components/StepsWizard";
import { StepWizardSection } from "components/StepsWizard/components/StepWizardSection";
import { useDevice } from "hooks/device/device";
import { useStoreRepositories } from "hooks/storeRepositories/useStoreRepositories";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { applicationStateActions } from "store/slices/applicationState/slice";
import { DefaultProps } from "utils/types/defaultProps";
import { useCarouselImages } from "steps/hooks/useCarouselImages";
import { PageContentBodyWrapper } from "./component/PageContentBodyWrapper";

// styles
import "./pageContent.scss";

// Required Props
// FIXME
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PageContentRequiredProps {}

// Optional Props
type PageContentOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface PageContentProps
  extends PageContentRequiredProps,
    PageContentOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: PageContentOptionalProps = {
  "data-testid": "space-configurator-pageContent",
};

// FIXME: retrieve by calculation
const STEP_HEADING_HEIGHT = 65;

const COMPLETED_STEPS_OFFSET = 1;

const PageContent: React.FC<PageContentProps> = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isShrinked, setIsShrinked] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState(true);

  const complex = useSelector(applicationStateSelectors.selectComplex);
  const zone = useSelector(applicationStateSelectors.selectZone);

  const zoomedStepIndex = useSelector(
    applicationStateSelectors.selectZoomedStepIndex,
  );
  const isOpen = useSelector(applicationStateSelectors.selectIsOpenRecap);

  const carouselImages = useCarouselImages();

  const { StepsEntityRepository } = useStoreRepositories();

  const stepIds = useSelector(
    () => StepsEntityRepository?.selectIds(),
    isEqual,
  );

  const currentStepIndex = useSelector(
    applicationStateSelectors.selectCurrentStepIndex,
  );

  const farthestStepIndex = useSelector(
    applicationStateSelectors.selectFarthestStepIndex,
  );

  const device = useDevice();

  const isZoomed = zoomedStepIndex != null;

  const curStepId = stepIds?.[currentStepIndex - 1];
  const zoomedStepId =
    zoomedStepIndex != null ? stepIds?.[zoomedStepIndex - 1] : undefined;

  const contentStepId = zoomedStepId || curStepId;

  const classList = classNames(
    "space-configurator-pageContent",
    { isZoomed },
    className,
  );

  const pageContentClassList = classNames("pageContent-configurator", {
    "is-mobile": device.type === "mobile",
    "is-open": device.type === "mobile" && isOpen,
    "is-shrinked": device.type === "mobile" && isShrinked,
  });

  const pageContentBodyClassList = classNames("page-content-body", {
    "is-mobile": device.type === "mobile",
    "is-open": device.type === "mobile" && isOpen,
    "is-shrinked": device.type === "mobile" && isShrinked,
  });

  const pageContentOverlayClassList = classNames("page-content-overlay", {
    "show-overlay": showOverlay,
    "is-shrinked": isShrinked,
  });

  const accordionRef = useRef<HTMLDivElement>(null);

  const currStepIndex = useSelector(
    applicationStateSelectors.selectCurrentStepIndex,
  );

  const setIsZoomed = (zoomed: boolean) => {
    if (zoomed) {
      dispatch(applicationStateActions.setZoomedStepIndex(currentStepIndex));
    } else {
      dispatch(applicationStateActions.closeZoom());
    }
  };

  const stepsLength = stepIds?.length || 0;

  const shrinkPageContent = (): void => {
    if (showOverlay) {
      setShowOverlay(false);
    }
    setIsShrinked(true);
  };

  const buttonWrapperAction: MouseEventHandler<HTMLButtonElement> = (e) => {
    if ((e?.target as Element).classList.contains("tecma-lazyImage")) {
      if (isShrinked) {
        setIsShrinked(false);
        return;
      }
      if (showOverlay) {
        setShowOverlay(false);
      }
      setIsZoomed(true);
    }
  };

  useEffect(() => {
    if (currStepIndex <= stepsLength) {
      accordionRef.current?.scrollTo({
        behavior: "smooth",
        left: 0,
        top:
          STEP_HEADING_HEIGHT *
          Math.max(0, currStepIndex - (1 + COMPLETED_STEPS_OFFSET)),
      });
    } else {
      accordionRef.current?.scrollTo({
        behavior: "smooth",
        left: 0,
        top: 0,
      });
    }
  }, [currStepIndex, stepsLength]);

  useEffect(() => {
    setTimeout(() => {
      setShowOverlay(false);
    }, 4000);
  }, []);

  return (
    <div className={classList} {...rest}>
      {!isZoomed && (
        <div className={pageContentClassList}>
          {device.type === "desktop" && <SpaceInfo />}
          <StepsWizard title={t("steps.title")} ref={accordionRef}>
            {stepIds &&
              stepIds.map((stepId, index) => (
                <StepWizardSection
                  key={stepId}
                  stepId={stepId}
                  stepIndex={index + 1}
                  isCompleted={index < farthestStepIndex - 1}
                  isActive={index < farthestStepIndex}
                  stepsCount={stepIds.length}
                />
              ))}
          </StepsWizard>
          <RecapSection />
        </div>
      )}
      <div className={pageContentBodyClassList}>
        <PageContentBodyWrapper
          condition={device.type === "mobile" && !isZoomed}
          action={buttonWrapperAction}
        >
          {contentStepId ? (
            <PageCentralContent
              stepId={contentStepId}
              isZoomed={isZoomed}
              isShrinked={isShrinked}
              setIsZoomed={setIsZoomed}
              complex={complex}
              zone={zone}
            />
          ) : (
            <Carousel
              isZoomed={isZoomed}
              isShrinked={isShrinked}
              setIsZoomed={() => setIsZoomed(!isZoomed)}
              images={carouselImages || []}
            />
          )}
          {device.type === "mobile" && !isZoomed && !isShrinked && (
            <Button
              className='page-content-body-shrink-btn'
              onClick={shrinkPageContent}
              iconName='close'
              rounded
            />
          )}
        </PageContentBodyWrapper>
        {device.type === "mobile" && (
          <div className={pageContentOverlayClassList}>
            <div className='overlay-text'>
              <p>
                <Trans
                  i18nKey='overlay-wizard.zoom'
                  components={{ 1: <span /> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey='overlay-wizard.shrink'
                  components={{ 1: <span />, 2: <Icon iconName='close' /> }}
                />
              </p>
            </div>

            <p className='shrinked-overlay-text'>
              <Trans
                i18nKey='overlay-wizard.open'
                components={{ 2: <Icon iconName='open' /> }}
              />
            </p>
          </div>
        )}
      </div>

      {(device.type === "tablet" || device.type === "mobile") && !isZoomed && (
        <DatePickerSection />
      )}
    </div>
  );
};

PageContent.defaultProps = defaultProps as Partial<PageContentOptionalProps>;

export default React.memo(PageContent);
