import React from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "@tecma/i18n";

import {
  getCleanName,
  getMoodNameTranslation,
} from "utils/functions/formatters";

import { useDevice } from "hooks/device/device";
import { CardList } from "components/CardList";
import type { CardsListOptions } from "components/CardList/CardList";
import InfoMessage from "../../../components/StepsWizard/components/InfoMessage/InfoMessage";
import { StepsComponentProps } from "../../types/step";
import type { DefaultProps } from "../../../utils/types/defaultProps";
import type { MoodStepModule } from "..";

// styles
import "./mood-step.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MoodStepRequiredProps {}

// Optional Props
type MoodStepOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface MoodStepProps
  extends MoodStepRequiredProps,
    MoodStepOptionalProps,
    StepsComponentProps<MoodStepModule> {}

// use the optional prop interface to define the default props
const defaultProps: MoodStepOptionalProps = {
  "data-testid": "space-configurator-moodSelectorStep",
};

const MoodStep: React.FC<MoodStepProps> = ({
  className,
  stepConfig,
  onUserSelectionChange,
  userSelection,
  complex,
  confirmedUserSelection,
  ...rest
}) => {
  const classList = classNames("space-configurator-mood-step", className);
  const { t } = useTranslation();
  const device = useDevice();

  const handleSelectMood = (mood: MoodName) => {
    onUserSelectionChange(mood);
  };

  const cardsOptions: CardsListOptions[] = stepConfig.options
    .map((mood) => {
      return {
        title: t([
          `${complex}.mood-picker.moods.${getMoodNameTranslation(mood.name)}`,
          `mood-picker.moods.${getMoodNameTranslation(mood.name)}`,
          getCleanName(mood.name),
        ]),
        image: mood.image,
        onCardClick: () => handleSelectMood(mood.name),
        active: userSelection === mood.name,
        confirmed: confirmedUserSelection === userSelection,
      };
    })
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <div className={classList} data-testid={rest["data-testid"]}>
      <InfoMessage
        message={t("mood-picker.info-message.message")}
        tooltipMessage={
          <Trans
            i18nKey='mood-picker.info-message.tooltip-message'
            components={{ 1: <span /> }}
          />
        }
      />

      <div className='mood-picker-container'>
        {stepConfig?.options ? (
          <CardList
            usePagination={cardsOptions.length > 4}
            cardsOptions={cardsOptions}
            mode={device.type === "mobile" ? "scroll" : "wrap"}
          />
        ) : (
          <p>Loading</p>
        )}
      </div>
    </div>
  );
};

MoodStep.defaultProps = defaultProps as Partial<MoodStepOptionalProps>;

export default MoodStep;
