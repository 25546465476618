import type { UserSelections, UserSelectionsParams } from "steps/selectors";
import { createAction } from "@reduxjs/toolkit";

const SPACE_TYPE_SELECTOR_TYPE = "spaceType";

export const spaceTypeSelectorActions = {
  initConfig: createAction<UserSelectionsParams>(
    `${SPACE_TYPE_SELECTOR_TYPE}/initConfig`,
  ),
  updateConfig: createAction<UserSelectionsParams>(
    `${SPACE_TYPE_SELECTOR_TYPE}/updateConfig`,
  ),
  checkDataChanges: createAction<{
    userSelectionsParams: UserSelectionsParams;
    userSelections: UserSelections;
  }>(`${SPACE_TYPE_SELECTOR_TYPE}/checkDataChanges`),
  initUndoConfigSaga: createAction<{
    userSelectionsParams: UserSelectionsParams;
    userSelections: UserSelections;
  }>(`${SPACE_TYPE_SELECTOR_TYPE}/initUndoConfigSaga`),
  updateSpaceType: createAction<SpaceTypeName>(
    `${SPACE_TYPE_SELECTOR_TYPE}/updateSpaceTypeRequest`,
  ),
  updateUnconfirmedSpaceType: createAction<SpaceTypeName>(
    `${SPACE_TYPE_SELECTOR_TYPE}/updateUnconfirmedSpaceTypeRequest`,
  ),
  setIsStepLoading: createAction<boolean>(
    `${SPACE_TYPE_SELECTOR_TYPE}/setIsStepLoadingRequest`,
  ),
};
