import React, { ReactElement } from "react";
import classNames from "classnames";
import { Card, Icon } from "@tecma/ui";
import { Trans } from "@tecma/i18n";

import type { DefaultProps } from "utils/types/defaultProps";

// styles
import "./cardItem.scss";

// Required Props
interface CardItemRequiredProps {
  title: string;
  onCardClick: React.MouseEventHandler;
}

// Optional Props
interface CardItemOptionalProps extends DefaultProps {
  subtitle?: string;
  image?: string;
  price?: number;
  itemList?: ReactElement[] | string[];
  active?: boolean;
  confirmed?: boolean;
}

// Combined required and optional props to build the full prop interface
export interface CardItemProps
  extends CardItemRequiredProps,
    CardItemOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: CardItemOptionalProps = {
  "data-testid": "spaceConfigurator-cardItem",
};

const CardItem: React.FC<CardItemProps> = ({
  className,
  onCardClick,
  title,
  subtitle,
  image,
  price,
  itemList,
  active,
  confirmed,
  ...rest
}) => {
  const classList = classNames("space-configurator-card-item", className, {
    active,
    confirmed,
    "with-image": image,
  });

  return (
    <button type='button' className={classList} onClick={onCardClick} {...rest}>
      <Card>
        <Card.Media>
          <img src={image} alt='' />
        </Card.Media>
        <Card.Content>
          <Card.Header>
            {subtitle && <p className='subtitle'>{subtitle}</p>}
            <div className='title'>
              <p>{title}</p>
            </div>
          </Card.Header>
          {(price || itemList?.length) && (
            <Card.Footer>
              {itemList?.length && (
                <div className='item-list'>
                  {itemList.map((element) => (
                    <div className='list-element'>{element}</div>
                  ))}
                </div>
              )}
              {price && (
                <Trans
                  i18nKey='card-item.from-price'
                  values={{ price: Math.round(price), currency: "€" }}
                  components={{
                    p: <p />,
                    s: <span />,
                  }}
                />
              )}
            </Card.Footer>
          )}
        </Card.Content>
      </Card>
    </button>
  );
};

CardItem.defaultProps = defaultProps as Partial<CardItemOptionalProps>;

export default React.memo(CardItem);
