import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { applicationStateActions } from "store/slices/applicationState/slice";
import { selectCurrentStepImages } from "../selector";

const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useCarouselImages = () => {
  const dispatch = useDispatch();
  const { carouselImages, imagesStepIndex } = useSelector(
    selectCurrentStepImages,
  );
  const currentStepIndex = useSelector(
    applicationStateSelectors.selectCurrentStepIndex,
  );
  const carouselIndex = useSelector(
    applicationStateSelectors.selectCurrentCarouselIndex,
  );

  const previousCarouselImages = usePrevious(carouselImages);
  const previousImagesStepIndex = usePrevious(imagesStepIndex);

  useEffect(() => {
    const imagesAreFromSameStep = previousImagesStepIndex === currentStepIndex;

    const imagesNotModified =
      carouselImages &&
      previousCarouselImages &&
      carouselImages.length === previousCarouselImages.length &&
      carouselImages.every(
        (image, index) => image === previousCarouselImages[index],
      );

    const isCarouselIndexInBounds =
      carouselIndex < (carouselImages?.length || 0);

    if (
      (imagesAreFromSameStep && isCarouselIndexInBounds) ||
      imagesNotModified
    ) {
      return;
    }

    dispatch(applicationStateActions.setCarouselIndex(0));
    // reset of carousel index should occur only if images change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselImages]);

  return carouselImages;
};
