import FloorStep from "./component/FloorStep";
import { FloorRecap } from "./component/FloorRecap";
import { floorStepActions } from "./sagas/actions";
import { floorStepSlice } from "./state/slice";
import { floorStepRootInitSaga } from "./sagas/saga";
import { floorStepSelectors } from "./state/selectors";
import type { StepModule } from "../types/step";

export type FloorStepModule = StepModule<
  FloorStepSlice,
  FloorStepConfig,
  FloorNumber,
  Floor
>;

export const module: FloorStepModule = {
  type: "FLOOR_STEP",
  name: "floor-picker",
  icon: "layers",
  priceCalculationMode: "REPLACEMENT",
  stepRenderer: FloorStep,
  recapStepRender: FloorRecap,
  firstDateAvailableSelector: floorStepSelectors.selectUserSelectionFirstDate,
  configSelector: floorStepSelectors.selectConfig,
  userSelectionSelector: floorStepSelectors.selectUserSelection,
  unconfirmedUserSelectionSelector:
    floorStepSelectors.selectUnconfirmedUserSelection,
  userSelectionDetailSelector: floorStepSelectors.selectUserSelectionDetail,
  userSelectionPriceSelector: floorStepSelectors.selectUserSelectionPrice,
  userSelectionPriceDetailsSelector:
    floorStepSelectors.selectUserSelectionPriceDetails,
  updateUserSelectionAction: floorStepActions.updateFloor,
  updateUnconfirmedUserSelectionAction: floorStepActions.updateUnconfirmedFloor,
  initConfigAction: floorStepActions.initConfig,
  resetUserSelectionAction: floorStepSlice.stepConfigActions.resetFloor,
  stepReducer: floorStepSlice.reducer,
  initSaga: floorStepRootInitSaga,
  stepPageContentCarouselImagesSelector:
    floorStepSelectors.stepPageContentCarouselImagesSelector,
  areOptionsAvailableSelector: floorStepSelectors.areOptionsAvailableSelector,
  setStepLoading: floorStepSlice.stepConfigActions.setIsStepLoading,
  isStepLoading: floorStepSelectors.isStepLoading,
};
