/* eslint-disable no-param-reassign */
import { createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../..";
import {
  UserInteractEntities,
  UserInteractEntitiesType,
} from "../../enums/entities";

export interface GenericUserInteractionRecord {
  id: string;
  selected?: boolean;
}

export type EntityGenericUserInteractionRecord = Omit<
  GenericUserInteractionRecord,
  "id"
>;

export interface GenericUserInteractionRecordBeforeEntity {
  selected?: boolean;
}

interface InteractionsRecords {
  [key: string]: GenericUserInteractionRecord;
}

export type InteractionsCatalog = {
  -readonly [Property in keyof typeof UserInteractEntities]: InteractionsRecords;
};

const initUserInteractions: () => InteractionsCatalog = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const obj: any = {};
  (
    Object.keys(UserInteractEntities) as Array<UserInteractEntitiesType>
  ).forEach((key) => {
    obj[key] = {};
  });
  return obj;
};
interface UserInteractions {
  interactions: InteractionsCatalog;
}

export interface UserInteractionPayload {
  interaction: GenericUserInteractionRecord;
  interactionType: UserInteractEntitiesType;
}

export interface UserInteractionResetPayload {
  interactionId: EntityId | string;
  interactionType: UserInteractEntitiesType;
}

const initialState: UserInteractions = {
  interactions: initUserInteractions(),
};

export const getUserInteractionsState = (state: RootState) =>
  state.userInteractions;

const slice = createSlice({
  name: "userInteractions",
  initialState,
  reducers: {
    userInteractionUpdated: (
      state,
      action: PayloadAction<UserInteractionPayload>,
    ) => {
      const { interaction, interactionType } = action.payload;
      if (!state.interactions?.[interactionType]?.[interaction.id]) {
        state.interactions[interactionType][interaction.id] = interaction;
      } else {
        Object.assign(
          state.interactions[interactionType][interaction.id],
          interaction,
        );
      }
    },
    userInteractionsReset: (
      state,
      action: PayloadAction<UserInteractionResetPayload>,
    ) => {
      const { interactionType, interactionId } = action.payload;
      if (state.interactions?.[interactionType]?.[interactionId]) {
        delete state.interactions[interactionType][interactionId];
      }
    },
    catalogToInitialState: (state) => {
      state.interactions = initUserInteractions();
    },
  },
});

export const {
  userInteractionUpdated,
  userInteractionsReset,
  catalogToInitialState,
} = slice.actions;

export const { reducer } = slice;
