/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../..";

interface ApplicationState {
  isInit: boolean;
  isResetting: boolean;
  isLoading: boolean;
  isOpenAlert: boolean;
  isOpenRecap: boolean;
  isOpenPriceDetails: boolean;
  isSavingConfiguration: boolean;
  isFinishClientSavedSelectionNewAuth: boolean;
  isLoadingSavedSelection: boolean;
  projectInfo?: ProjectInfo;
  currentStepIndex: number;
  farthestStepIndex: number;
  currentOptionPrice: number;
  currentCarouselIndex: number;
  totalPrice: number;
  alert: Alert | null;
  zoomedStepIndex: number | null;
  hasError: boolean;
  token?: TokenInfo;
}

const initialState: ApplicationState = {
  isInit: true,
  isResetting: false,
  isLoading: true,
  isSavingConfiguration: false,
  isLoadingSavedSelection: false,
  isFinishClientSavedSelectionNewAuth: false,
  projectInfo: undefined,
  currentStepIndex: 0,
  farthestStepIndex: 0,
  currentCarouselIndex: 0,
  isOpenRecap: false,
  isOpenPriceDetails: false,
  isOpenAlert: false,
  currentOptionPrice: 0,
  totalPrice: 0,
  alert: null,
  zoomedStepIndex: null,
  hasError: false,
  token: undefined,
};

const slice = createSlice({
  name: "applicationState",
  initialState,
  reducers: {
    startReset: (state) => {
      state.isResetting = true;
    },
    stopReset: (state) => {
      state.isResetting = false;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setIsSavingConfiguration: (state, action: PayloadAction<boolean>) => {
      state.isSavingConfiguration = action.payload;
    },
    setIsFinishClientSavedSelectionNewAuth: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isFinishClientSavedSelectionNewAuth = action.payload;
    },
    openRecap: (state) => {
      state.isOpenRecap = true;
    },
    closeRecap: (state) => {
      state.isOpenRecap = false;
    },
    startLoadSavedSelection: (state) => {
      state.isLoadingSavedSelection = true;
    },
    stopLoadSavedSelection: (state) => {
      state.isLoadingSavedSelection = false;
    },
    openPriceDetails: (state) => {
      state.isOpenPriceDetails = true;
    },
    closePriceDetails: (state) => {
      state.isOpenPriceDetails = false;
    },
    openAlert: (state, action: PayloadAction<Alert>) => {
      state.isOpenAlert = true;
      state.alert = action.payload;
    },
    closeAlert: (state) => {
      state.isOpenAlert = false;
      state.alert = null;
    },
    startInit: (state) => {
      state.isInit = true;
    },
    stopInit: (state) => {
      state.isInit = false;
    },
    setProjectInfo: (state, action: PayloadAction<ProjectInfo>) => {
      state.projectInfo = action.payload;
    },
    setToken: (state, action: PayloadAction<TokenInfo>) => {
      state.token = action.payload;
    },
    goToNextStep: (state, action: PayloadAction<number>) => {
      state.currentStepIndex = action.payload + 1;
      state.farthestStepIndex = action.payload + 1;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.currentStepIndex = action.payload;
    },
    setFarthestStep: (state, action: PayloadAction<number>) => {
      state.farthestStepIndex = action.payload;
    },
    resetStepIndex: (state) => {
      state.currentStepIndex = 1; // TODO: fix indexes
      state.farthestStepIndex = 1;
    },
    setCarouselIndex: (state, action: PayloadAction<number>) => {
      state.currentCarouselIndex = action.payload;
    },
    setZoomedStepIndex: (state, action: PayloadAction<number>) => {
      state.zoomedStepIndex = action.payload;
    },
    closeZoom: (state) => {
      state.zoomedStepIndex = null;
    },
    setError: (state) => {
      state.hasError = true;
    },
  },
});

export const getApplicationState = createSelector(
  (state: RootState) => state.applicationState,
  (state) => state,
);

export const applicationStateActions = slice.actions;

export const { reducer } = slice;
