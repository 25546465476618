import React, { MouseEventHandler } from "react";
import { DefaultProps } from "utils/types/defaultProps";

import "./pageContentBodyWrapper.scss";

interface PageContentBodyWrapperRequiredProps {
  condition: boolean;
  action: MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
}

type PageContentBodyWrapperOptionalProps = DefaultProps;

export interface PageContentBodyWrapperProps
  extends PageContentBodyWrapperRequiredProps,
    PageContentBodyWrapperOptionalProps {}

const defaultProps: PageContentBodyWrapperOptionalProps = {
  "data-testid": "space-configurator-pageContentBodyWrapper",
};

const PageContentBodyWrapper: React.FC<PageContentBodyWrapperProps> = ({
  condition,
  action,
  children,
}) => {
  return condition ? (
    <button
      className='page-content-body-wrapper'
      onClick={action}
      type='button'
    >
      {children}
    </button>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

PageContentBodyWrapper.defaultProps =
  defaultProps as Partial<PageContentBodyWrapperOptionalProps>;

export default React.memo(PageContentBodyWrapper);
