/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: FloorStepSlice = {
  config: null,
  selectedFloor: null,
  unconfirmedFloor: null,
  isLoading: false,
};

const slice = createSlice({
  name: "floorStep",
  initialState,
  reducers: {
    putConfig: (state, action: PayloadAction<FloorStepConfig>) => {
      state.config = action.payload;
    },
    setFloor: (state, action: PayloadAction<FloorNumber>) => {
      state.selectedFloor = action.payload;
    },
    setUnconfirmedFloor: (state, action: PayloadAction<FloorNumber>) => {
      state.unconfirmedFloor = action.payload;
    },
    resetFloor: (state) => {
      state.selectedFloor = null;
      state.unconfirmedFloor = null;
    },
    setIsStepLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

const stepConfigActions = slice.actions;

const { reducer } = slice;

export const floorStepSlice = {
  stepConfigActions,
  reducer,
};
