import React, {
  createRef,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import { Carousel as TecmaCarousel, LazyImage, Spinner } from "@tecma/ui";
import { useDispatch, useSelector } from "react-redux";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";

import { useDevice } from "hooks/device/device";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { applicationStateActions } from "store/slices/applicationState/slice";

import type { DefaultProps } from "utils/types/defaultProps";

// styles
import "./carousel.scss";

interface CarouselRequiredProps {
  isZoomed: boolean;
  setIsZoomed: (e?: any) => void;
  images: string[];
}
interface CarouselOptionalProps extends DefaultProps {
  isShrinked?: boolean;
}
export interface CarouselProps
  extends CarouselRequiredProps,
    CarouselOptionalProps {}

const defaultProps: CarouselOptionalProps = {
  "data-testid": "space-configurator-carousel",
};

interface CarouselImageProps {
  src: string;
  index: number;
  onLoad?: (img: HTMLImageElement) => void;
  onRendered?: () => void;
}

const CAROUSEL_IMAGES_LOAD_DELAY_MS = 50;

const CarouselImage = memo(
  ({ src, index, onLoad, onRendered }: CarouselImageProps) => (
    <LazyImage.Image
      loadingElement={
        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      }
      loadDelay={index * CAROUSEL_IMAGES_LOAD_DELAY_MS}
      src={src}
      alt='Image'
      onLoad={onLoad}
      onRendered={onRendered}
      className={`carousel-img-${index}`}
    />
  ),
);

interface ZoomableImageProps {
  visible: boolean;
  zoomed: boolean;
  index: number;
  src: string;
}

const ZoomableImage = memo(
  ({ src, index, visible, zoomed }: ZoomableImageProps) => {
    const transformWrapperRef = createRef<ReactZoomPanPinchContentRef>();
    const wrapperRef = createRef<HTMLDivElement>();
    const [loaded, setLoaded] = useState(false);
    const [rendered, setRendered] = useState(false);
    const [aspectRatio, setAspectRatio] = useState<
      "horizontal" | "vertical" | undefined
    >();

    useEffect(() => {
      if (visible && loaded && rendered) {
        transformWrapperRef.current?.centerView(1, 0);
      }
    }, [visible, loaded, rendered, zoomed, transformWrapperRef]);

    const onRendered = useCallback(() => {
      setRendered(true);
    }, [setRendered]);

    const onLoad = useCallback(
      (img: HTMLImageElement) => {
        const wrapperW = wrapperRef.current?.clientWidth;
        const wrapperH = wrapperRef.current?.clientHeight;
        if (wrapperW && wrapperH) {
          setAspectRatio(
            img.width / img.height > wrapperW / wrapperH
              ? "horizontal"
              : "vertical",
          );
        }
        setLoaded(true);
      },
      [wrapperRef],
    );

    return (
      <div ref={wrapperRef} style={{ width: "100%", height: "100%" }}>
        <TransformWrapper
          initialScale={1}
          maxScale={3}
          doubleClick={{ mode: "reset" }}
          centerOnInit
          limitToBounds
          ref={transformWrapperRef}
        >
          <TransformComponent
            contentClass={classNames(
              aspectRatio && `aspect-ratio-${aspectRatio}`,
            )}
          >
            <CarouselImage
              src={src}
              index={index}
              onLoad={onLoad}
              onRendered={onRendered}
            />
          </TransformComponent>
        </TransformWrapper>
      </div>
    );
  },
);

const Carousel: React.FC<CarouselProps> = ({
  className,
  isZoomed,
  isShrinked,
  images,
  setIsZoomed,
  ...rest
}) => {
  const dispatch = useDispatch();
  const setCarouselIndex = (index: number) => {
    dispatch(applicationStateActions.setCarouselIndex(index));
  };
  const carouselIndex = useSelector(
    applicationStateSelectors.selectCurrentCarouselIndex,
  );

  const classList = classNames("space-configurator-carousel", className, {
    isZoomed,
  });
  const imagesLength = images.length;
  const device = useDevice();

  const next = () => {
    setCarouselIndex((carouselIndex + 1) % imagesLength);
  };

  const prev = () => {
    if (carouselIndex === 0) {
      setCarouselIndex(imagesLength - 1);
    } else {
      setCarouselIndex((carouselIndex - 1) % imagesLength);
    }
  };

  const carouselZoomButtonIcon = () => {
    if (isZoomed) {
      return "cross";
    }
    return "size";
  };

  const touchZoomEnabled = isZoomed;

  const carouselContent = images.map((el, index) =>
    touchZoomEnabled ? (
      <ZoomableImage
        key={el}
        index={index}
        src={el}
        visible={carouselIndex === index}
        zoomed={isZoomed}
      />
    ) : (
      <CarouselImage key={el} index={index} src={el} />
    ),
  );

  return (
    <TecmaCarousel
      className={classList}
      showPagination={device.type !== "desktop" && !isZoomed && !isShrinked}
      selectedItemIndex={carouselIndex}
      onChange={(index: number) => setCarouselIndex(index)}
      loop={false}
      swipe={
        !isZoomed && device.type !== "desktop" ? { prev, next } : undefined
      }
      {...rest}
      buttons={
        !isShrinked && (
          <>
            {(isZoomed || device.type !== "mobile") && (
              <TecmaCarousel.Button
                iconName={carouselZoomButtonIcon()}
                className='zoom'
                disabled={false}
                onClick={setIsZoomed}
                size='small'
              />
            )}

            <TecmaCarousel.Button
              iconName='arrow-left'
              className='prev'
              onClick={prev}
              size='small'
            />
            <TecmaCarousel.Button
              iconName='arrow-right'
              className='next'
              onClick={next}
              size='small'
            />
          </>
        )
      }
    >
      {carouselContent}
    </TecmaCarousel>
  );
};

Carousel.defaultProps = defaultProps as Partial<CarouselOptionalProps>;

export default React.memo(Carousel);
