/* eslint-disable @typescript-eslint/no-empty-interface */
import React from "react";
import classNames from "classnames";
import { DefaultProps } from "utils/types/defaultProps";
import { Button, Portal } from "@tecma/ui";
import { useDispatch } from "react-redux";
import { applicationStateActions } from "store/slices/applicationState/slice";

// styles
import "./mobileModal.scss";

// Required Props
interface MobileModalRequiredProps {
  step: string;
  children: React.ReactNode;
}

// Optional Props
interface MobileModalOptionalProps extends DefaultProps {
  title?: string | JSX.Element;
  description?: string | JSX.Element;
}

// Combined required and optional props to build the full prop interface
export interface MobileModalProps
  extends MobileModalRequiredProps,
    MobileModalOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: MobileModalOptionalProps = {
  "data-testid": "space-configurator-mobileModal",
};

const MobileModal: React.FC<MobileModalProps> = ({
  className,
  step,
  children,
  title,
  description,
  ...rest
}) => {
  const classList = classNames("space-configurator-mobileModal", className);
  const dispatch = useDispatch();

  return (
    <Portal id={`${step}-modal`}>
      <div className={classList} {...rest}>
        <div className='modal-header'>
          <div className='modal-header-description'>
            <p className='title'>{title}</p>
            <span className='subtitle'>{description}</span>
          </div>
          <Button
            onClick={() => dispatch(applicationStateActions.closeAlert())}
            iconName='cross'
          />
        </div>
        <div className='modal-container'>{children}</div>
      </div>
    </Portal>
  );
};

MobileModal.defaultProps = defaultProps as Partial<MobileModalOptionalProps>;

export default React.memo(MobileModal);
