import { useState, useEffect } from "react";

const initBeforeUnLoad = (blockRefresh: boolean) => {
  window.onbeforeunload = (event) => {
    if (blockRefresh) {
      const e = event || window.event;
      e.preventDefault();
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    }
    return undefined;
  };
};

export const usePreventRefreshPage = (bool: boolean) => {
  const [blockRefresh, setBlockRefresh] = useState(bool);

  window.onload = () => {
    initBeforeUnLoad(blockRefresh);
  };

  useEffect(() => {
    initBeforeUnLoad(blockRefresh);
  }, [blockRefresh]);

  return { blockRefresh, setBlockRefresh };
};
