import { useState } from "react";
import "react-phone-input-2/lib/style.css";
import ReactPhoneInput from "react-phone-input-2";
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldValues,
  UseFormTrigger,
  ValidationRule,
} from "react-hook-form";
import { useTranslation } from "@tecma/i18n";
import { Country } from "../../../../utils/types/form";
import "./phone-input.scss";

interface TelInputProps {
  control: Control<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  name: string;
  required: string | ValidationRule<boolean>;
  className: string;
  placeholder: string;
}
/**
 * TODO: refactor this component. The commented lines caused an awful behavior and broke the form validation
 */

const PhoneInput: React.FC<TelInputProps> = ({
  control,
  trigger,
  name,
  required,
  className,
  placeholder,
}) => {
  const { t } = useTranslation();
  const invalidPhoneText = t("auth.form.invalid-phone");

  const [isValidNumber, setIsValidNumber] = useState(true);
  /* const [dialCode, setDialCode] = useState(''); */

  const validatePhoneNumber = (
    inputNumber: string,
    country: Country,
    isDirty: boolean,
    phoneLength: number,
  ) => {
    if (isDirty) {
      if (
        inputNumber.length === country.dialCode?.length ||
        inputNumber.length < phoneLength
      ) {
        setIsValidNumber(false);
        return false;
      }
    }
    setIsValidNumber(true);
    return true;
  };

  const onChangeHandle = (field: ControllerRenderProps, inputValue: string) => {
    trigger(name);
    field.onChange(inputValue);
  };

  /* const onFocusHandle = (field: ControllerRenderProps, country: Country) => {
     if (!field.value) {
      field.onChange(country.dialCode);
    } 
    setDialCode(country.dialCode);
  }; */

  /* const onBlurHandle = (field: ControllerRenderProps, country: Country) => {
    if (field.value === country.dialCode) {
      setDialCode('');
      field.onChange('');
    }
  }; */

  return (
    <Controller
      name={name}
      control={control}
      render={(props) => {
        return (
          <ReactPhoneInput
            onChange={(inputValue) => onChangeHandle(props.field, inputValue)}
            /* onBlur={(e, inputNumber: Country) => onBlurHandle(props.field, inputNumber)} */
            /* onFocus={(e, inputNumber: Country) => onFocusHandle(props.field, inputNumber)} */
            inputProps={{
              id: name,
              name,
              required,
              autoComplete: "none",
              "data-testid": "input-id",
            }}
            dropdownClass='am-dropdown-phone'
            placeholder={placeholder}
            country='it'
            value={props.field.value}
            isValid={(inputNumber, country: Country) => {
              const phoneLength = Math.ceil((country.format?.length || 0) / 2);
              return validatePhoneNumber(
                inputNumber,
                country,
                props.formState.isDirty,
                phoneLength,
              );
            }}
            specialLabel={placeholder}
            inputClass={className}
            containerClass='react-tel-input-active'
            countryCodeEditable={false}
            /*
            disableCountryCode={dialCode.length === 0} 
           */
          />
        );
      }}
      rules={{
        required,
        validate: () => isValidNumber || invalidPhoneText,
      }}
    />
  );
};

export default PhoneInput;
