import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeEvery } from "redux-saga/effects";

import { applicationStateActions } from "../../slices/applicationState/slice";
import {
  catalogToInitialState,
  UserInteractionPayload,
  UserInteractionResetPayload,
  userInteractionsReset,
  userInteractionUpdated,
} from "../../slices/userInteractions/slice";
import { userInteractionSagaActions } from "./userInteractions.actions";

function* resetUserInteractionCatalog(
  action: PayloadAction<UserInteractionResetPayload>,
) {
  yield put(
    userInteractionsReset({
      ...action.payload,
    }),
  );
}

function* updateUserInteraction(action: PayloadAction<UserInteractionPayload>) {
  yield put(
    userInteractionUpdated({
      ...action.payload,
    }),
  );
}

function* resetCatalog() {
  yield put(catalogToInitialState());
}

export default function* mux() {
  yield takeEvery(
    userInteractionSagaActions.interactionsReset,
    resetUserInteractionCatalog,
  );
  yield takeEvery(
    userInteractionSagaActions.appendedInteraction,
    updateUserInteraction,
  );
  yield takeEvery(applicationStateActions.startReset, resetCatalog);
  yield takeEvery(applicationStateActions.resetStepIndex, resetCatalog);
}
