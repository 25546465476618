import React from "react";
import { Button } from "@tecma/ui";
import { useTranslation } from "@tecma/i18n";
import type { PlanimetryStepModule } from "steps/PlanimetryStep";
import type { DefaultProps } from "../../../../utils/types/defaultProps";
import { RecapStepsComponentProps } from "../../../types/step";

import "./planimetry-recap.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PlanimetryRecapRequiredProps {}

type PlanimetryRecapOptionalProps = DefaultProps;

export interface PlanimetryRecapProps
  extends PlanimetryRecapRequiredProps,
    PlanimetryRecapOptionalProps,
    RecapStepsComponentProps<PlanimetryStepModule> {}

const PlanimetryRecap: React.FC<PlanimetryRecapProps> = ({
  userSelectionDetails,
  setZoomed,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <p className='planimetry-recap'>
        {userSelectionDetails &&
          `${t("planimetry-item.floor-plan")} ${userSelectionDetails.name}`}
      </p>
      <Button
        transparent
        onClick={() => setZoomed(true)}
        className='planimetry-recap-button'
      >
        {t("planimetry-picker.recap.button")}
      </Button>
    </>
  );
};

export default PlanimetryRecap;
