import React from "react";
import { Button } from "@tecma/ui";
import { useTranslation } from "@tecma/i18n";
import type { PlanimetryStepModule } from "steps/PlanimetryStep";
import type { DefaultProps } from "../../../../utils/types/defaultProps";
import { RecapStepsComponentProps } from "../../../types/step";

import "./planimetry-recap.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PlanimetryRecapApartmentRequiredProps {}

type PlanimetryRecapApartmentOptionalProps = DefaultProps;

export interface PlanimetryRecapApartmentProps
  extends PlanimetryRecapApartmentRequiredProps,
    PlanimetryRecapApartmentOptionalProps,
    RecapStepsComponentProps<PlanimetryStepModule> {}

const PlanimetryRecapApartment: React.FC<PlanimetryRecapApartmentProps> = ({
  userSelectionDetails,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <p className='planimetry-recap'>
        {userSelectionDetails &&
          t("planimetry-item.apartment", {
            apartment: userSelectionDetails?.apartment.name,
          })}
      </p>
      <span className='planimetry-recap-address'>
        {userSelectionDetails?.address}
      </span>
    </>
  );
};

export default PlanimetryRecapApartment;
