/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { datadogRum } from "@datadog/browser-rum";

import type { RootState } from "store";
import { IToken, IUser } from "../../../utils/types/auth";
import getDomain from "../../../utils/functions/getDomain";

export interface AuthState {
  token: IToken | null;
  user: IUser | null;
  impersonatedUser?: IUser | null;
  isImpersonating: boolean;
}

const initialState: AuthState = {
  token: null,
  user: null,
  impersonatedUser: null,
  isImpersonating: false,
};

export const IMPERSONATED_CLIENT_COOKIE = "impersonatedClient";
export const JWT_RENT_COOKIE = "jwtRent";
export const REFRESH_TOKEN_RENT_COOKIE = "refreshTokenRent";
export const EXPIRES_IN_RENT_COOKIE = "expiresInRent";
export const CLIENT_ID_RENT_COOKIE = "clientIdRent";
export const DO_LOGOUT_COOKIE = "doLogout";

export const USER_SELECTION_LOCAL_STORAGE_KEY = "SPACECONFIGURATOR_data";
export const IS_AUTHENTICATE_QUERY_PARAM = "isAuthenticate";
export const CLIENT_ID_QUERY_PARAM = "clientId";
export const TOOL_DATA_QUERY_PARAM = "toolData";

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedUser: (state, action: PayloadAction<IUser>) => {
      const cookieOptions = {
        expires: 1,
        domain: getDomain(),
      };
      Cookies.set(DO_LOGOUT_COOKIE, "false", cookieOptions);
      state.user = action.payload;
      if (process.env.REACT_APP_NAMESPACE === "biz-tecma-prod") {
        datadogRum.setUser({
          id: action.payload.id,
          name: action.payload.firstName,
          surname: action.payload.lastName,
          email: action.payload.email,
          role: action.payload.role,
        });
      }
    },
    unsetLoggedUser: () => {
      const cookieOptions = {
        expires: 1,
        domain: getDomain(),
      };
      Cookies.set(DO_LOGOUT_COOKIE, "true", cookieOptions);

      Cookies.remove(IMPERSONATED_CLIENT_COOKIE, cookieOptions);

      return {
        ...initialState,
      };
    },
    setTokenUser: (state, action: PayloadAction<IToken>) => {
      state.token = action.payload;
    },
    setImpersonatedUser: (state, action: PayloadAction<IUser>) => {
      state.isImpersonating = true;
      state.impersonatedUser = action.payload;
    },
  },
});

export const selectCurrentUser = (
  state: RootState,
): IUser | null | undefined => {
  return state.auth.user;
};

export const selectCurrentUserAndToken = (
  state: RootState,
): { token: IToken | null | undefined; user: IUser | null | undefined } => {
  return { token: state.auth.token, user: state.auth.user };
};

export const selectImpersonatedUser = (
  state: RootState,
): IUser | null | undefined => {
  return state.auth.impersonatedUser;
};

export const selectIsImpersonating = (state: RootState): boolean =>
  state.auth.isImpersonating;

export const selectUserLogInStatus = (state: RootState): boolean | null =>
  state.auth.user && !!Cookies.get(JWT_RENT_COOKIE);

export const {
  setLoggedUser,
  unsetLoggedUser,
  setTokenUser,
  setImpersonatedUser,
} = slice.actions;

export const { reducer } = slice;
