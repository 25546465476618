import React from "react";
import { FloorStepModule } from "steps/FloorStep";
import type { DefaultProps } from "../../../../utils/types/defaultProps";
import type { RecapStepsComponentProps } from "../../../types/step";
import "./floor-recap.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FloorRecapRequiredProps {}

// Optional Props
type FloorRecapOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface FloorRecapProps
  extends FloorRecapRequiredProps,
    FloorRecapOptionalProps,
    RecapStepsComponentProps<FloorStepModule> {}

const MoodRecap: React.FC<FloorRecapProps> = ({ userSelectionDetails }) => {
  return (
    <p className='floor-recap'>
      {userSelectionDetails && userSelectionDetails.number}
    </p>
  );
};

export default MoodRecap;
