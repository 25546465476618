/* eslint-disable no-use-before-define */
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMutation } from "@apollo/client";
import { Trans, useTranslation } from "@tecma/i18n";
import { logger } from "@tecma/logs-fe";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "@tecma/ui";
import { useNavigate } from "react-router-dom";

import paths from "routes/routesPaths";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { authActions } from "store/sagas/auth/auth.actions";
import { applicationStateActions } from "store/slices/applicationState/slice";
import { applicationActions } from "store/sagas/application/application.actions";
import { setLoggedUser } from "store/slices/auth/slice";
import { goToReservedArea } from "utils/functions/detectUrl";
import { ExpiredTokenModal } from "components/AuthModal/components/ExpiredTokenModal";
import { ErrorMessage } from "./components/RightPartModal/RightPartModal";
import Client from "../../client/Client";
import LeftPartModal from "./components/LeftPartModal";
import RightPartModal from "./components/RightPartModal";
import {
  CreatePasswordData,
  EditPasswordData,
  LoginData,
  RecoveryData,
  RegistrationData,
} from "../../utils/types/form";
import "./auth-modal.scss";

interface AuthModalProps {
  mode: string;
  token?: string;
  isConfigurationComplete?: boolean;
}

const AuthModal: React.FC<AuthModalProps> = ({
  mode,
  token,
  isConfigurationComplete,
}) => {
  const initialHeight = useRef(window.innerHeight);
  const amModalRef = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [callError, setCallError] = useState<ErrorMessage | null>(null);
  const [modalAction, setModalAction] = useState(mode);
  const projectInfo = useSelector(
    applicationStateSelectors.selectProjectInfo,
  ) as ProjectInfo;
  const tokenInfo = useSelector(applicationStateSelectors.selectToken);
  const navigate = useNavigate();

  // MUTATIONS
  const [doConfirmUser, confirmUserData] = useMutation(Client.DO_CONFIRM_USER);
  const [doChangePassword, changePasswordData] = useMutation(
    Client.DO_CHANGE_PASSWORD,
  );
  const [createQuote, createQuoteData] = useMutation(
    Client.CREATE_QUOTE_MUTATION,
  );
  const [doLogin, loginData] = useMutation(Client.DO_LOGIN);
  const [doRegistration, registrationData] = useMutation(
    Client.DO_REGISTRATION,
  );
  const [doRecoveryPassword] = useMutation(Client.DO_RECOVERY_PASSWORD);
  const [doCheckClient, clientData] = useMutation(Client.CHECK_CLIENT_INFO);

  const doneConfirmUserCalling =
    confirmUserData &&
    confirmUserData.called &&
    !confirmUserData.loading &&
    confirmUserData.data?.confirmUserAndChangePassword &&
    confirmUserData.data.confirmUserAndChangePassword !== "wrongPassword";
  const doneChangePasswordCalling =
    changePasswordData &&
    changePasswordData.called &&
    !changePasswordData.loading &&
    changePasswordData.data?.changePassword;
  const doneLoginCalling =
    loginData &&
    loginData.called &&
    !loginData.loading &&
    loginData.data?.loginByProject;

  const doneRegistrationCalling =
    registrationData &&
    registrationData.called &&
    !registrationData.loading &&
    registrationData.data?.createClientAndUser;

  const privacyLink = useSelector(applicationStateSelectors.selectProjectInfo)
    ?.config.privacyLink;

  const useConditionLink = `https://${
    useSelector(applicationStateSelectors.selectProjectInfo)?.hostname
  }/documents/Condizioni%20dUso.pdf`;

  const keyboardOpenListener = () => {
    const AppRef = document.querySelector(
      "div#rent-platform-app.turn-your-phone",
    );
    if (
      window.innerHeight < initialHeight.current &&
      !AppRef &&
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      amModalRef.current?.classList.add("keyboard-open");
    } else {
      amModalRef.current?.classList.remove("keyboard-open");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", keyboardOpenListener);
    return () => {
      window.removeEventListener("resize", keyboardOpenListener);
    };
  }, []);

  useEffect(() => {
    if (doneLoginCalling) {
      dispatch(
        authActions.loggedInStuff({
          ...loginData.data.loginByProject,
          projectId: projectInfo.projectID,
          isConfigurationComplete,
          skipValidation: true,
        }),
      );
    }
  }, [doneLoginCalling]);

  useEffect(() => {
    if (doneConfirmUserCalling || doneChangePasswordCalling) {
      navigate(paths.root);
      dispatch(
        applicationStateActions.openAlert({
          type: "login",
          props: {
            confirmButtonText: "",
          },
        }),
      );
      setModalAction("login");
    }
  }, [doneConfirmUserCalling, doneChangePasswordCalling, navigate]);

  useEffect(() => {
    if (doneRegistrationCalling) {
      if (isConfigurationComplete) {
        dispatch(
          applicationActions.saveSelectionsRequested({ isSigningUp: true }),
        );
      }

      dispatch(
        applicationStateActions.openAlert({
          type: "success",
          props: {
            title: "auth.confirm-user",
            content: isConfigurationComplete
              ? "auth.send-activate-email-complete"
              : "auth.send-activate-email",
            closeAlertAction: isConfigurationComplete
              ? applicationActions.goToReservedAreaRequested()
              : applicationActions.closeAlertRequested(),
          },
        }),
      );
    }
  }, [doneRegistrationCalling]);

  const handleAuthMode = (authMode: string) => {
    setModalAction(authMode);
    setCallError(null);
  };

  const handleLogin = async (data: LoginData) => {
    const checkClientData = {
      email: data.loginMail,
      project_id: projectInfo.projectID,
    };
    const mutationData = {
      email: data.loginMail,
      password: data.password,
      project_id: projectInfo.projectID,
    };

    const { data: resp } = await doCheckClient(
      Client.CHECK_CLIENT_INFO_OPTIONS(checkClientData),
    );
    if (resp.checkClientInfo.status === "userAlreadyRegistered") {
      // dispatch(authActions.doLogin(mutationData));
      doLogin(Client.DO_LOGIN_OPTIONS(mutationData))
        .then(() => dispatch(applicationStateActions.closeAlert()))
        .catch(() => {
          setCallError({
            inputField: "password",
            message: t("auth.error.email-password-wrong"),
          });
        });
    } else if (resp.checkClientInfo.status === "createClientAndUser") {
      setCallError({
        inputField: "loginMail",
        message: t("auth.error.email-not-registered"),
      });
    }
  };

  const handleConfirmUser = async (data: CreatePasswordData) => {
    if (data.newPassword === data.confirmPassword) {
      const mutationData = {
        password: data.newcode,
        newPassword: data.newPassword,
        project_id: projectInfo.projectID,
        token: token || "",
      };

      const { data: resp } = await doConfirmUser(
        Client.DO_CONFIRM_USER_OPTIONS(mutationData),
      );
      if (resp.confirmUserAndChangePassword === "wrongPassword") {
        setCallError({
          inputField: "newcode",
          message: t("auth.error.invalid-code"),
        });
      } else {
        setModalAction("login");
      }
    } else {
      setCallError({
        inputField: "confirmPassword",
        message: t("auth.error.password-mismatch"),
      });
    }
  };

  const handleChangePassword = (data: EditPasswordData) => {
    if (data.newPassword === data.confirmPassword) {
      const mutationData = {
        password: data.newPassword,
        project_id: projectInfo.projectID,
        token: token || "",
      };

      doChangePassword(Client.DO_CHANGE_PASSWORD_OPTIONS(mutationData)).catch(
        (err) => {
          logger.info("err", err);
        },
      );
    } else {
      setCallError({
        inputField: "confirmPassword",
        message: t("auth.error.passwords-mismatch"),
      });
    }
  };

  const handleSignUp = async (data: RegistrationData) => {
    const mutationCheckData = {
      project_id: projectInfo.projectID,
      email: data.email,
    };

    const mutationRegistrationData = {
      project_id: projectInfo.projectID,
      platform: "SPACECONFIGURATOR",
      input: {
        firstName: data.nome,
        lastName: data.cognome,
        email: data.email,
        tel: data.telefono,
        trattamento: data.trattamento,
        profilazione: data.privacy,
        marketing: data.privacy,
      },
      isConfigurationComplete,
    };
    const { data: resp } = await doCheckClient(
      Client.CHECK_CLIENT_INFO_OPTIONS(mutationCheckData),
    );
    if (resp.checkClientInfo.status === "createClientAndUser") {
      doRegistration(Client.DO_REGISTRATION_OPTIONS(mutationRegistrationData))
        .then((registrationResp) => {
          if (isConfigurationComplete) {
            const clientId = registrationResp.data.createClientAndUser;
            const { firstName, lastName, email, tel } =
              mutationRegistrationData.input;
            dispatch(
              setLoggedUser({
                client: clientId,
                email,
                firstName,
                id: clientId,
                lastName,
                tel,
                language: "",
              }),
            );
          }
        })
        .catch((err) => logger.error(err));
    } else if (resp.checkClientInfo.status === "userAlreadyRegistered") {
      setCallError({
        inputField: "email",
        message: t("auth.error.email-registered"),
      });
    }
  };

  const handleRecoveryPassword = async (data: RecoveryData) => {
    const mutationCheckData = {
      project_id: projectInfo.projectID,
      email: data.email,
    };
    const mutationData = {
      email: data.email,
      template_type: "changePasswordRent",
      platform: "SPACECONFIGURATOR",
      project_id: projectInfo.projectID,
    };
    const { data: checkClientResp } = await doCheckClient(
      Client.CHECK_CLIENT_INFO_OPTIONS(mutationCheckData),
    );
    if (checkClientResp.checkClientInfo.status !== "userAlreadyRegistered") {
      setCallError({
        inputField: "email",
        message: t("auth.error.email-not-registered"),
      });
    } else if (
      checkClientResp.checkClientInfo.status === "userAlreadyRegistered"
    ) {
      const { data: resp } = await doRecoveryPassword(
        Client.DO_RECOVERY_PASSWORD_OPTIONS(mutationData),
      );
      if (resp && resp.askChangePassword.success) {
        dispatch(
          applicationStateActions.openAlert({
            type: "success",
            props: {
              title: "auth.recovery-title",
              content: "auth.send-recovery-email",
            },
          }),
        );
      }
    }
  };

  const handleRefreshToken = async () => {
    if (tokenInfo?.email) {
      const doRecoveryInput = {
        email: tokenInfo.email,
        platform: "SPACECONFIGURATOR",
        project_id: projectInfo.projectID,
        template_type: "changePasswordRent",
      };
      try {
        await doRecoveryPassword(
          Client.DO_RECOVERY_PASSWORD_OPTIONS(doRecoveryInput),
        ).then((res) => {
          if (res?.data?.askChangePassword.success) {
            setModalAction("recoveryDone");
            navigate(paths.root);
          } else {
            logger.error(res?.data?.askChangePassword.error);
            throw Error(res?.data?.askChangePassword.error);
          }
        });
      } catch (err) {
        logger.error(err);
        throw Error();
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleEditPassword = (data: EditPasswordData) => {
    setCallError(null);
    /* let mutationData = {
      email: data.email,
      password: data.password,
      project_id: projectInfo.projectId,
    }; */
    /* doLogin(Client.DO_LOGIN_OPTIONS(mutationData)).catch((err) => {
      console.log("err", err, loginData);
    }); */
  };

  const getAuthContent = useCallback(() => {
    if (modalAction === "login") {
      return (
        <>
          <LeftPartModal
            title={t("auth.login-title")}
            subTitle={t("auth.login-subtitle")}
            footerTitle={t("auth.login-footer")}
            footerActionText={t("auth.login-cta")}
            switchAuthMode={() => handleAuthMode("signup")}
          />
          <RightPartModal
            formType='login'
            submitText='Login'
            inputFields={[
              {
                fieldName: "loginMail",
                placeholder: `${t("auth.form.email")}*`,
                type: "email",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.email-required"),
                  },
                  maxLength: {
                    value: 256,
                    message: t("auth.form.maximum-length"),
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("auth.form.invalid-email"),
                  },
                },
              },
              {
                fieldName: "password",
                placeholder: `${t("auth.form.password")}*`,
                type: "password",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.password-required"),
                  },
                  minLength: {
                    value: 6,
                    message: t("auth.form.min-length"),
                  },
                },
              },
            ]}
            footerLinks={[
              {
                label: t("auth.recover-password"),
                callBack: () => handleAuthMode("askRecovery"),
              },
            ]}
            handleFormAction={handleLogin}
            apiError={callError}
          />
        </>
      );
    }
    if (modalAction === "confirm") {
      return (
        <>
          <LeftPartModal
            title={t("auth.confirm-title")}
            subTitle={t("auth.confirm-subtitle")}
          />
          <RightPartModal
            formType='edit'
            submitText={t("auth.form.create-password")}
            inputFields={[
              {
                fieldName: "newcode",
                placeholder: `${t("auth.form.verification-code")}*`,
                className: "am-input-code",
                type: "text",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.verification-code-required"),
                  },
                  minLength: {
                    value: 8,
                    message: t("auth.form.verification-code-min"),
                  },
                  maxLength: {
                    value: 8,
                    message: t("auth.form.verification-code-max"),
                  },
                },
              },
              {
                fieldName: "newPassword",
                placeholder: `${t("auth.form.new-password")}*`,
                className: "am-input",
                type: "password",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.new-password-required"),
                  },
                  minLength: {
                    value: 6,
                    message: t("auth.form.new-password-length"),
                  },
                },
              },
              {
                fieldName: "confirmPassword",
                className: "am-input",
                placeholder: `${t("auth.form.confirm-password")}*`,
                type: "password",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.confirm-password-required"),
                  },
                  minLength: {
                    value: 6,
                    message: t("auth.form.confirm-password-length"),
                  },
                },
              },
            ]}
            handleFormAction={handleConfirmUser}
            apiError={callError}
          />
        </>
      );
    }
    if (modalAction === "recovery") {
      return (
        <>
          <LeftPartModal
            title={t("auth.edit-title")}
            subTitle={t("auth.new-password-subtitle")}
          />
          <RightPartModal
            formType='editPass'
            submitText={t("auth.form.edit-password")}
            inputFields={[
              {
                fieldName: "newPassword",
                placeholder: `${t("auth.form.new-password")}*`,
                type: "password",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.new-password-required"),
                  },
                  minLength: {
                    value: 6,
                    message: t("auth.form.new-password-length"),
                  },
                },
              },
              {
                fieldName: "confirmPassword",
                placeholder: `${t("auth.form.repeat-password")}*`,
                type: "password",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.confirm-password-required"),
                  },
                  minLength: {
                    value: 6,
                    message: t("auth.form.confirm-password-length"),
                  },
                },
              },
            ]}
            handleFormAction={handleChangePassword}
            apiError={callError}
          />
        </>
      );
    }
    if (modalAction === "signup") {
      return (
        <>
          <LeftPartModal
            title={t("auth.sign-up-title")}
            subTitle={t("auth.sign-up-subtitle")}
            footerTitle={t("auth.sign-up-footer")}
            footerActionText={t("auth.sign-up-cta")}
            switchAuthMode={() => handleAuthMode("login")}
          />
          <RightPartModal
            formType='register'
            submitText={t("auth.form.sign-up")}
            inputFields={[
              {
                fieldName: "nome",
                placeholder: `${t("auth.form.name")}*`,
                className: "am-register-input",
                containerClass: "am-register-container",
                type: "text",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.name-required"),
                  },
                  pattern: {
                    value: /^([^0-9]*)$/,
                    message: t("auth.form.invalid-name"),
                  },
                },
              },
              {
                fieldName: "cognome",
                placeholder: `${t("auth.form.surname")}*`,
                containerClass: "am-register-container",
                type: "text",
                className: "am-register-input",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.surname-required"),
                  },
                  pattern: {
                    value: /^([^0-9]*)$/,
                    message: t("auth.form.invalid-surname"),
                  },
                },
              },
              {
                fieldName: "email",
                placeholder: `${t("auth.form.email")}*`,
                type: "email",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.email-required"),
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("auth.form.invalid-email"),
                  },
                },
              },
              {
                fieldName: "telefono",
                placeholder: `${t("auth.form.phone")}*`,
                type: "tel",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.phone-required"),
                  },
                },
              },
            ]}
            checkboxFields={[
              {
                fieldName: "useCondition",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.useCondition"),
                  },
                },
                content: (
                  <>
                    <Trans
                      i18nKey='auth.form.useCondition'
                      components={{
                        1: (
                          // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                          <a
                            href={useConditionLink}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='am-privacy-link'
                          />
                        ),
                      }}
                    />
                    <span> *</span>
                  </>
                ),
              },
              {
                fieldName: "trattamento",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.processing-required"),
                  },
                },
                content: (
                  <>
                    <Trans
                      i18nKey='auth.form.processing-content'
                      components={{
                        1: (
                          // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                          <a
                            href={privacyLink}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='am-privacy-link'
                          />
                        ),
                      }}
                    />
                    <span> *</span>
                  </>
                ),
              },
              {
                fieldName: "privacy",
                options: {
                  required: {
                    value: false,
                    message: "",
                  },
                },
                content: t("auth.form.privacy-content"),
              },
            ]}
            handleFormAction={handleSignUp}
            apiError={callError}
          />
        </>
      );
    }
    if (modalAction === "askRecovery") {
      return (
        <>
          <LeftPartModal
            title={t("auth.recovery-title")}
            subTitle={t("auth.recovery-subtitle")}
            footerTitle={t("auth.back-to-login")}
            footerActionText={t("auth.login-account")}
            switchAuthMode={() => handleAuthMode("login")}
          />
          <RightPartModal
            formType='recovery'
            submitText={t("auth.form.recovery-password")}
            inputFields={[
              {
                fieldName: "email",
                placeholder: `${t("auth.form.email")}*`,
                type: "email",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.email-required"),
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("auth.form.invalid-email"),
                  },
                },
              },
            ]}
            footerInfo={
              <div className='am-email-footer'>
                <div className='am-email-footer-info'>
                  {t("auth.form.recovery-info")}
                </div>
                {/*
              <div className="am-email-footer-actions">
                <span className="am-email-footer-label">
                  Hai già la password ma vuoi cambiarla?
                </span>{" "}
                <span
                  onClick={() => handleAuthMode("login")}
                  className="am-email-footer-link"
                >
                  Modifica password
                </span>
              </div>
                */}
              </div>
            }
            handleFormAction={handleRecoveryPassword}
            apiError={callError}
          />
        </>
      );
    }
    if (modalAction === "edit") {
      return (
        <>
          <LeftPartModal
            title={t("auth.edit-title")}
            subTitle={t("auth.edit-subtitle")}
            footerTitle={t("auth.back-to-login")}
            footerActionText={t("auth.login-account")}
            switchAuthMode={() => handleAuthMode("login")}
          />
          <RightPartModal
            formType='edit'
            submitText={t("auth.edit-title")}
            inputFields={[
              {
                fieldName: "currentPassword",
                placeholder: `${t("auth.form.current-password")}*`,
                type: "password",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.current-password-required"),
                  },
                  minLength: {
                    value: 6,
                    message: t("auth.form.current-password-length"),
                  },
                },
              },
              {
                fieldName: "newPassword",
                placeholder: `${t("auth.form.new-password")}*`,
                type: "password",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.new-password-required"),
                  },
                  minLength: {
                    value: 6,
                    message: t("auth.form.new-password-length"),
                  },
                },
              },
              {
                fieldName: "confirmPassword",
                placeholder: `${t("auth.form.confirm-password")}*`,
                type: "password",
                options: {
                  required: {
                    value: true,
                    message: t("auth.form.confirm-password-required"),
                  },
                  minLength: {
                    value: 6,
                    message: t("auth.form.confirm-password-length"),
                  },
                },
              },
            ]}
            handleFormAction={handleEditPassword}
            apiError={callError}
          />
        </>
      );
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callError, modalAction, privacyLink]);

  const closeModalButton = () => {
    dispatch(applicationStateActions.closeAlert());
  };

  return mode === "refreshToken" ? (
    <ExpiredTokenModal
      handleRefreshToken={handleRefreshToken}
      email={tokenInfo?.email}
    />
  ) : (
    <div className='am-modal-container'>
      <div
        ref={amModalRef as MutableRefObject<HTMLDivElement>}
        className={`am-modal-form ${modalAction}`}
      >
        <div
          style={{
            display: `${
              confirmUserData.loading ||
              changePasswordData.loading ||
              createQuoteData.loading ||
              loginData.loading ||
              registrationData.loading ||
              clientData.loading
                ? "flex"
                : `none`
            }`,
          }}
          className='am-modal-loader'
        >
          <Spinner type='dotted' />
        </div>
        {getAuthContent()}
        {!["confirm", "recovery"].includes(modalAction) && (
          <Button
            className='close-button'
            onClick={closeModalButton}
            iconName='cross'
            rounded
            size='extra-small'
          />
        )}
        <div className='am-special-bottom' />
      </div>
    </div>
  );
};

export default AuthModal;
