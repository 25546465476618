import React from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "@tecma/i18n";
import { useDispatch, useSelector } from "react-redux";

import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import type { DefaultProps } from "utils/types/defaultProps";
import { useStoreRepositories } from "hooks/storeRepositories/useStoreRepositories";
import { useStepRegistry } from "steps/hooks/useStepRegistry";
import { applicationStateActions } from "store/slices/applicationState/slice";
import { RecapSectionHeader } from "../RecapSectionHeader";

import "./recap-step.scss";

interface RecapStepRequiredProps {
  stepId: EntityId;
  stepIndex: number;
}

// Optional Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RecapStepOptionalProps extends DefaultProps {
  buildingName?: string;
}

// Combined required and optional props to build the full prop interface
export interface RecapStepProps
  extends RecapStepRequiredProps,
    RecapStepOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: RecapStepOptionalProps = {
  "data-testid": "space-configurator-recapStep",
};

const RecapStep: React.FC<RecapStepProps> = ({
  stepId,
  stepIndex,
  buildingName,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { StepsEntityRepository } = useStoreRepositories();
  const step = StepsEntityRepository?.selectById(stepId as string);
  const farthestStepIndex = useSelector(
    applicationStateSelectors.selectFarthestStepIndex,
  );
  if (!step) {
    throw new Error("Step is missing");
  }
  const {
    stepModule,
    RecapStepRenderer,
    RecapAdditionalStepRenderer,
    userSelectionDetail,
    isUserSelectionComplete,
  } = useStepRegistry(step);

  const setIsZoomed = (zoomed: boolean) => {
    if (zoomed) {
      dispatch(applicationStateActions.setZoomedStepIndex(stepIndex));
    } else {
      dispatch(applicationStateActions.closeZoom());
    }
  };

  if (isUserSelectionComplete && stepIndex <= farthestStepIndex) {
    return (
      <>
        <div className='recap-step'>
          <RecapSectionHeader title={t(`${stepModule.name}.recap.title`)} />
          <RecapStepRenderer
            userSelectionDetails={userSelectionDetail}
            buildingName={buildingName}
            setZoomed={setIsZoomed}
          />
        </div>
        {RecapAdditionalStepRenderer && (
          <div className='recap-step'>
            <RecapSectionHeader
              title={t(`${stepModule.name}.recap.additional.title`)}
            />
            <RecapAdditionalStepRenderer
              userSelectionDetails={userSelectionDetail}
              setZoomed={setIsZoomed}
            />
          </div>
        )}
      </>
    );
  }
  return null;
};

RecapStep.defaultProps = defaultProps as Partial<RecapStepOptionalProps>;

export default RecapStep;
