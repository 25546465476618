import React, { useState } from "react";
import classNames from "classnames";
import { Button, Card, Icon, LazyImage, Spinner } from "@tecma/ui";
import { Trans, useTranslation } from "@tecma/i18n";
import { DefaultProps } from "utils/types/defaultProps";
import { getCleanName } from "utils/functions/formatters";

// styles
import "./packCard.scss";

// Required Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PackCardRequiredProps {
  packName: string;
  packImage: string;
  packDescription: string;
  packPrice: number;
  packDetails: string[];
  added: boolean;
  included: boolean;
  onChange: (newAdded: boolean) => void;
}

// Optional Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PackCardOptionalProps extends DefaultProps {
  complex?: string;
}

// Combined required and optional props to build the full prop interface
export interface PackCardProps
  extends PackCardRequiredProps,
    PackCardOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: PackCardOptionalProps = {
  "data-testid": "space-configurator-PackCard",
};

const PackCard: React.FC<PackCardProps> = ({
  className,
  packName,
  packImage,
  packDescription,
  packPrice,
  packDetails,
  added,
  included,
  onChange,
  complex,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isViewDetails, setIsViewDetails] = useState(false);
  const classList = classNames(
    "space-configurator-packCard",
    { included },
    className,
  );
  const contentClassList = classNames("card-content", {
    "show-details": isViewDetails,
  });
  const imageClassList = classNames({
    "show-details": isViewDetails,
  });
  const footerClassList = classNames({ added });

  const getPackStatus = () => {
    if (included) return "included";
    return added ? "added" : "add";
  };

  const handleClick = () => {
    onChange(!added);
  };

  return (
    <Card className={classList} {...rest}>
      <Card.Media className={imageClassList}>
        <LazyImage.Image
          src={packImage}
          loadingElement={<Spinner type='dotted' />}
        />
        <Button
          onClick={() => setIsViewDetails(!isViewDetails)}
          iconName={isViewDetails ? "cross" : ""}
          iconSize='small'
          rightIcon
        >
          {t(
            `pack-picker.pack-card.${isViewDetails ? "close" : "view-details"}`,
          )}
        </Button>
        <div className='price-container'>
          <Trans
            i18nKey='pack-picker.starting-price'
            components={{
              1: <span />,
            }}
            values={{
              price: Math.round(packPrice),
              currency: "€",
            }}
          />
        </div>
      </Card.Media>
      <div className={contentClassList}>
        {!isViewDetails && (
          <Card.Header>
            <h3>
              <Trans
                i18nKey={t([
                  `${complex}.pack-picker.pack.${packName}.label`,
                  `pack-picker.pack.${packName}.label`,
                  getCleanName(packName),
                ])}
                components={{ 1: <span /> }}
              />
            </h3>
          </Card.Header>
        )}
        <Card.Content>
          {!isViewDetails && (
            <p className='description'>
              {t([
                `${complex}.pack-picker.pack.${packName}.description`,
                `pack-picker.pack.${packName}.description`,
              ])}
            </p>
          )}
          {isViewDetails && (
            <div className='pack-details'>
              {/* {packDetails.map((detail) => (
                <li key={detail} className='detail'>
                  <Icon iconName='check' size='small' />
                  {t(`pack-picker.pack.${packName}.${detail}`)}
                </li>
              ))} */}
              <Trans
                i18nKey={t([
                  `${complex}.pack-picker.pack.${packName}.includes`,
                  `pack-picker.pack.${packName}.includes`,
                ])}
                components={{
                  1: <li className='detail' />,
                  2: <Icon iconName='check' size='small' />,
                }}
              />
            </div>
          )}
        </Card.Content>
      </div>
      <Card.Footer className={footerClassList}>
        <span>{t(`pack-picker.pack-card.${getPackStatus()}`)}</span>
        {!included && (
          <Button
            onClick={handleClick}
            iconName={added ? "" : "plus"}
            iconSize='small'
          >
            {added && `${t("pack-picker.pack-card.delete")}`}
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};

PackCard.defaultProps = defaultProps as Partial<PackCardOptionalProps>;

export default React.memo(PackCard);
