import type { PayloadAction } from "@reduxjs/toolkit";
import { all, put, select, takeEvery } from "redux-saga/effects";
import { userSelectionsToDTO } from "steps/api";
import {
  type UserSelections,
  type UserSelectionsParams,
} from "steps/selectors";
import { complexStepSlice } from "../state/slice";
import { complexStepActions } from "./actions";
import { getComplexesByUserSelection } from "./api";
import { genericStepActions } from "../../actions/generic.actions";
import {
  StartEndDates,
  getCurrentStartEndDateSelector,
} from "../../../store/slices/dateSelectorState/slice";
import { complexStepSelectors } from "../state/selectors";
import type { RootStateSelector } from "../../types/step";

function* initConfigSaga(
  userSelectionsParams: PayloadAction<UserSelectionsParams>,
) {
  yield all([
    put(complexStepSlice.stepConfigActions.resetConfig()),
    put(complexStepSlice.stepConfigActions.setIsStepLoading(true)),
  ]);
  const userSelectionsDTO = userSelectionsToDTO({
    userSelections: userSelectionsParams.payload.userSelections,
    checkInDate: userSelectionsParams.payload.checkInDate,
    checkOutDate: userSelectionsParams.payload.checkOutDate,
    complex: userSelectionsParams.payload.complex,
  });
  const config: ComplexStepConfig = yield getComplexesByUserSelection(
    userSelectionsParams.payload.projectId,
    userSelectionsDTO,
  );
  const selectedComplexName =
    userSelectionsParams.payload.userSelections.COMPLEX_STEP;

  const currentDateSelected: StartEndDates = yield select(
    getCurrentStartEndDateSelector,
  );

  yield all([
    put(
      complexStepSlice.stepConfigActions.putConfig({
        options: config.options.filter(
          ({ availability }) =>
            availability.count || availability.firstEntryAvailable?.startDate,
        ),
      }),
    ),
    put(
      complexStepSlice.stepConfigActions.setStartEndDateFetched(
        currentDateSelected,
      ),
    ),
  ]);

  const defaultComplexName =
    selectedComplexName ||
    userSelectionsParams.payload.complex ||
    config.options.find(
      (complex: Complex) => complex.default && complex.availability.count,
    )?.name;

  if (defaultComplexName) {
    yield put(complexStepActions.updateComplex(defaultComplexName));
  }
  yield put(genericStepActions.initConfigCompleted());
  yield put(complexStepSlice.stepConfigActions.setIsStepLoading(false));
}

function* initUndoConfigSaga(
  userSelection: PayloadAction<{
    userSelectionsParams: UserSelectionsParams;
    userSelections: UserSelections;
  }>,
) {
  yield all([
    put(complexStepSlice.stepConfigActions.resetConfig()),
    put(complexStepSlice.stepConfigActions.setIsStepLoading(true)),
  ]);
  const userSelectionsDTO = userSelectionsToDTO({
    userSelections: userSelection.payload.userSelections,
    checkInDate: userSelection.payload.userSelectionsParams.checkInDate,
    checkOutDate: userSelection.payload.userSelectionsParams.checkOutDate,
    complex: userSelection.payload.userSelectionsParams.complex,
  });
  const config: ComplexStepConfig = yield getComplexesByUserSelection(
    userSelection.payload.userSelectionsParams.projectId,
    userSelectionsDTO,
  );
  const selectedComplexName =
    userSelection.payload.userSelectionsParams.userSelections.COMPLEX_STEP;

  const currentDateSelected: StartEndDates = yield select(
    getCurrentStartEndDateSelector,
  );

  yield all([
    put(
      complexStepSlice.stepConfigActions.putConfig({
        options: config.options.filter(
          ({ availability }) =>
            availability.count || availability.firstEntryAvailable?.startDate,
        ),
      }),
    ),
    put(
      complexStepSlice.stepConfigActions.setStartEndDateFetched(
        currentDateSelected,
      ),
    ),
  ]);

  const defaultComplexName =
    selectedComplexName ||
    userSelection.payload.userSelectionsParams.complex ||
    config.options.find(
      (complex: Complex) => complex.default && complex.availability.count,
    )?.name;

  if (defaultComplexName) {
    yield put(complexStepActions.updateComplex(defaultComplexName));
  }
  yield put(genericStepActions.initConfigCompleted());
  yield put(complexStepSlice.stepConfigActions.setIsStepLoading(false));
}

function* updateConfigSaga(
  userSelectionsParams: PayloadAction<UserSelectionsParams>,
) {
  const selectedComplexName =
    userSelectionsParams.payload.userSelections.COMPLEX_STEP;

  const defaultComplexName =
    selectedComplexName || userSelectionsParams.payload.complex;

  if (defaultComplexName) {
    yield put(complexStepActions.updateComplex(defaultComplexName));
  }
  yield put(genericStepActions.initConfigCompleted());
  yield put(complexStepSlice.stepConfigActions.setIsStepLoading(false));
}

function* checkDataChangesSaga(
  userSelection: PayloadAction<{
    userSelectionsParams: UserSelectionsParams;
    userSelections: UserSelections;
  }>,
) {
  const [currentDateSelected, previousFetchDate]: [
    StartEndDates,
    StartEndDates,
  ] = yield all([
    select(getCurrentStartEndDateSelector),
    select(
      complexStepSelectors.selectPreviousFetchDataSelection as RootStateSelector<StartEndDates>,
    ),
  ]);

  if (
    currentDateSelected.startDate !== previousFetchDate.startDate ||
    currentDateSelected.endDate !== previousFetchDate.endDate
  ) {
    yield put(complexStepActions.initUndoConfigSaga(userSelection.payload));
  }
  yield put(genericStepActions.initConfigCompleted());
}

function* updateComplex(action: PayloadAction<ComplexName>): any {
  yield all([
    put(complexStepSlice.stepConfigActions.setSelectedComplex(action.payload)),
  ]);
}

function* updateUnconfirmedComplex(action: PayloadAction<ComplexName>): any {
  yield all([
    put(
      complexStepSlice.stepConfigActions.setUnconfirmedComplex(action.payload),
    ),
  ]);
}

export function* complexStepRootInitSaga() {
  yield takeEvery(complexStepActions.initConfig, initConfigSaga);
  yield takeEvery(complexStepActions.initUndoConfigSaga, initUndoConfigSaga);
  yield takeEvery(complexStepActions.updateConfig, updateConfigSaga);
  yield takeEvery(complexStepActions.checkDataChanges, checkDataChangesSaga);
  yield takeEvery(complexStepActions.updateComplex, updateComplex);
  yield takeEvery(
    complexStepActions.updateUnconfirmedComplex,
    updateUnconfirmedComplex,
  );
}
