import MoodStep from "./component/MoodStep";
import { MoodRecap } from "./component/MoodRecap";
import { moodStepActions } from "./sagas/actions";
import { moodStepSlice } from "./state/slice";
import { moodStepRootInitSaga } from "./sagas/saga";
import { moodStepSelectors } from "./state/selectors";
import type { StepModule } from "../types/step";

export type MoodStepModule = StepModule<
  MoodStepSlice,
  MoodStepConfig,
  MoodName,
  Mood
>;

export const module: MoodStepModule = {
  type: "MOOD_STEP",
  name: "mood-picker",
  icon: "heart",
  priceCalculationMode: "REPLACEMENT",
  stepRenderer: MoodStep,
  recapStepRender: MoodRecap,
  configSelector: moodStepSelectors.selectConfig,
  userSelectionSelector: moodStepSelectors.selectUserSelection,
  unconfirmedUserSelectionSelector:
    moodStepSelectors.selectUnconfirmedUserSelection,
  userSelectionDetailSelector: moodStepSelectors.selectUserSelectionDetail,
  userSelectionPriceSelector: moodStepSelectors.selectUserSelectionPrice,
  userSelectionPriceDetailsSelector:
    moodStepSelectors.selectUserSelectionPriceDetails,
  updateUserSelectionAction: moodStepActions.updateMood,
  updateUnconfirmedUserSelectionAction: moodStepActions.updateUnconfirmedMood,
  initConfigAction: moodStepActions.initConfig,
  resetUserSelectionAction: moodStepSlice.stepConfigActions.resetMood,
  stepReducer: moodStepSlice.reducer,
  initSaga: moodStepRootInitSaga,
  stepPageContentCarouselImagesSelector:
    moodStepSelectors.stepPageContentCarouselImagesSelector,
  areOptionsAvailableSelector: moodStepSelectors.areOptionsAvailableSelector,
  setStepLoading: moodStepSlice.stepConfigActions.setIsStepLoading,
  isStepLoading: moodStepSelectors.isStepLoading,
};
