import React from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import i18n, { Trans, useTranslation } from "@tecma/i18n";
import { Button, Icon, Modal } from "@tecma/ui";

import {
  firstLetterCapitalize,
  getCleanName,
} from "utils/functions/formatters";
import type { DefaultProps } from "utils/types/defaultProps";
import { dateSelectorActions } from "store/sagas/availabilitySelector/availabilitySelector.actions";
import { firstDatePopupActions } from "./actions";

import "./first-date-popup.scss";

type FirstDatePopupRequiredProps = FirstDateProps;

// Optional Props
type FirstDatePopupOptionalProps = DefaultProps;

export interface FirstDatePopupProps
  extends FirstDatePopupRequiredProps,
    FirstDatePopupOptionalProps {}

const defaultProps: FirstDatePopupOptionalProps = {
  "data-testid": "space-configurator-first-date",
};

const FirstDatePopup: React.FC<FirstDatePopupProps> = ({
  closeAlertAction,
  firstDateContent,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const firstDateClasses = classNames("first-date-popup");

  const handleOnConfirm = () => {
    if (firstDateContent?.date) {
      dispatch(
        dateSelectorActions.firstAvailabilityDatesUpdateRequested({
          startDate: firstDateContent.date,
          endDate: "",
        }),
      );
    }
  };

  const wrappedCloseAlertAction = () => {
    if (closeAlertAction) {
      dispatch(closeAlertAction);
    }
    dispatch(firstDatePopupActions.cancelled());
  };

  const formatFirstAvailableDate = (): string => {
    let date = new Date();
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    if (firstDateContent?.date) {
      date = new Date(firstDateContent?.date);
    }
    return date.toLocaleDateString(i18n.language, options);
  };

  return (
    <Modal
      className={firstDateClasses}
      width={47.5}
      isOpen
      verticalPosition='center'
      onClose={wrappedCloseAlertAction}
      closeOnBackDropClick
    >
      <Modal.Header
        className='first-date-popup-header'
        closeIcon
        onClose={wrappedCloseAlertAction}
      />
      <Modal.Content>
        <div className='first-date-popup-icon'>
          <Icon size='large' iconName='error' />
          <p className='first-date-popup-icon-text'>
            {t("first-date.warning")}
          </p>
          <div className='first-date-popup-divider' />
        </div>
        <div className='first-date-popup-content'>
          <Trans
            i18nKey='first-date.description'
            components={{
              1: <span />,
              2: <strong />,
              3: <p />,
            }}
            values={
              firstDateContent?.complex
                ? {
                    type: "",
                    initiative: "",
                    complex: t(
                      `buildings.${firstDateContent.complex}`,
                      firstLetterCapitalize(
                        getCleanName(firstDateContent.complex),
                      ),
                    ),
                  }
                : {
                    type: firstDateContent?.type,
                    initiative: t(
                      `${firstDateContent?.initiative}`,
                      firstDateContent?.initiative,
                    ),
                    complex: "",
                  }
            }
          />
          <p className='first-date-popup-date'>{formatFirstAvailableDate()}</p>
          <div className='first-date-popup-divider' />
          <Trans
            i18nKey='first-date.first-date-available'
            components={{
              1: <strong />,
            }}
          />
        </div>
      </Modal.Content>
      <Modal.Footer noWrapAndCenter className='first-date-popup-footer'>
        <Button
          className='first-date-popup-button confirm'
          outlined
          onClick={() => handleOnConfirm()}
        >
          {t("first-date.start")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

FirstDatePopup.defaultProps =
  defaultProps as Partial<FirstDatePopupOptionalProps>;

export default FirstDatePopup;
