import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes as ReactRoutes, Navigate } from "react-router-dom";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import ManagePasswordPage from "views/ManagePasswordPage/ManagePasswordPage";
import { Page } from "views/Page";
import paths from "./routesPaths";

const Routes: React.FC = () => {
  const token = useSelector(applicationStateSelectors.selectToken);
  return (
    <ReactRoutes>
      <Route path={paths.root} element={<Page />} />
      <Route
        path='/confirm'
        element={
          <ManagePasswordPage
            mode={
              token?.confirmationTokenExpired || token?.confirmed
                ? "refreshToken"
                : "confirm"
            }
          />
        }
      />
      <Route
        path='/changePassword'
        element={<ManagePasswordPage mode='recovery' />}
      />
      <Route path='*' element={<Navigate to={paths.root} replace />} />
    </ReactRoutes>
  );
};

export default React.memo(Routes);
