import { createAction } from "@reduxjs/toolkit";
import type { UserSelections, UserSelectionsParams } from "steps/selectors";

const COMPLEX_STEP_TYPE = "complexStep";

export const complexStepActions = {
  initConfig: createAction<UserSelectionsParams>(
    `${COMPLEX_STEP_TYPE}/initConfigRequest`,
  ),
  initUndoConfigSaga: createAction<{
    userSelectionsParams: UserSelectionsParams;
    userSelections: UserSelections;
  }>(`${COMPLEX_STEP_TYPE}/initUndoConfigSagaRequest`),
  updateConfig: createAction<UserSelectionsParams>(
    `${COMPLEX_STEP_TYPE}/updateConfigRequest`,
  ),
  checkDataChanges: createAction<{
    userSelectionsParams: UserSelectionsParams;
    userSelections: UserSelections;
  }>(`${COMPLEX_STEP_TYPE}/checkDataChangesRequest`),
  updateComplex: createAction<ComplexName>(
    `${COMPLEX_STEP_TYPE}/updateComplexRequest`,
  ),
  updateUnconfirmedComplex: createAction<ComplexName>(
    `${COMPLEX_STEP_TYPE}/updateUnconfirmedComplexRequest`,
  ),
  setIsStepLoading: createAction<boolean>(
    `${COMPLEX_STEP_TYPE}/setIsStepLoadingRequest`,
  ),
};
