import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { logger } from "@tecma/logs-fe";
import { combinedReducers } from "./reducers";
import { applicationStateActions } from "./slices/applicationState/slice";

import rootSaga from "./sagas";

export type CombinedReducersType = ReturnType<typeof combinedReducers>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = CombinedReducersType;

export const storeFactory = (preloadedState: Partial<RootState> = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: combinedReducers,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware),
    preloadedState,
  });
  sagaMiddleware
    .run(rootSaga)
    .toPromise()
    .catch((error) => {
      logger.error(error);
      store.dispatch(applicationStateActions.setError());
    });

  return store;
};
