import { PayloadAction } from "@reduxjs/toolkit";
import { all, put, select, takeEvery } from "redux-saga/effects";
import { userSelectionsToDTO } from "steps/api";
import type { UserSelections, UserSelectionsParams } from "steps/selectors";
import { spaceTypeStepSlice } from "../state/slice";
import { spaceTypeSelectorActions } from "./actions";
import { getSpaceTypesByUserSelection } from "./api";
import { genericStepActions } from "../../actions/generic.actions";
import {
  StartEndDates,
  getDateSelectorState,
} from "../../../store/slices/dateSelectorState/slice";
import { spaceTypeStepSelectors } from "../state/selectors";
import type { RootStateSelector } from "../../types/step";

function* initUndoConfigSaga(
  userSelection: PayloadAction<{
    userSelectionsParams: UserSelectionsParams;
    userSelections: UserSelections;
  }>,
) {
  yield all([
    put(spaceTypeStepSlice.stepConfigActions.resetConfig()),
    put(spaceTypeStepSlice.stepConfigActions.setIsStepLoading(true)),
  ]);

  const userSelectionsDTO = userSelectionsToDTO({
    userSelections: userSelection.payload.userSelections,
    checkInDate: userSelection.payload.userSelectionsParams.checkInDate,
    checkOutDate: userSelection.payload.userSelectionsParams.checkOutDate,
    complex: userSelection.payload.userSelectionsParams.complex,
  });
  const spaceTypes: SpaceTypeStepConfig = yield getSpaceTypesByUserSelection(
    userSelection.payload.userSelectionsParams.projectId,
    userSelectionsDTO,
  );

  const currentDateSelected: StartEndDates = yield select(getDateSelectorState);

  yield all([
    put(spaceTypeStepSlice.stepConfigActions.putConfig(spaceTypes)),
    put(
      spaceTypeStepSlice.stepConfigActions.setStartEndDateFetched(
        currentDateSelected,
      ),
    ),
  ]);

  if (
    userSelection.payload.userSelectionsParams.userSelections.SPACE_TYPE_STEP
  ) {
    yield put(
      spaceTypeStepSlice.stepConfigActions.setSpaceType(
        userSelection.payload.userSelectionsParams.userSelections
          .SPACE_TYPE_STEP,
      ),
    );
  }
  yield put(genericStepActions.initConfigCompleted());
  yield put(spaceTypeStepSlice.stepConfigActions.setIsStepLoading(false));
}
function* initConfigSaga(
  userSelectionsParams: PayloadAction<UserSelectionsParams>,
) {
  yield all([
    put(spaceTypeStepSlice.stepConfigActions.resetConfig()),
    put(spaceTypeStepSlice.stepConfigActions.setIsStepLoading(true)),
  ]);
  const userSelectionsDTO = userSelectionsToDTO({
    userSelections: userSelectionsParams.payload.userSelections,
    checkInDate: userSelectionsParams.payload.checkInDate,
    checkOutDate: userSelectionsParams.payload.checkOutDate,
    complex: userSelectionsParams.payload.complex,
  });
  const spaceTypes: SpaceTypeStepConfig = yield getSpaceTypesByUserSelection(
    userSelectionsParams.payload.projectId,
    userSelectionsDTO,
  );

  const currentDateSelected: StartEndDates = yield select(getDateSelectorState);

  yield all([
    put(spaceTypeStepSlice.stepConfigActions.putConfig(spaceTypes)),
    put(
      spaceTypeStepSlice.stepConfigActions.setStartEndDateFetched(
        currentDateSelected,
      ),
    ),
  ]);

  if (userSelectionsParams.payload.userSelections.SPACE_TYPE_STEP) {
    yield put(
      spaceTypeStepSlice.stepConfigActions.setSpaceType(
        userSelectionsParams.payload.userSelections.SPACE_TYPE_STEP,
      ),
    );
  }
  yield put(genericStepActions.initConfigCompleted());
  yield put(spaceTypeStepSlice.stepConfigActions.setIsStepLoading(false));
}

function* updateConfigSaga(
  userSelectionsParams: PayloadAction<UserSelectionsParams>,
) {
  if (userSelectionsParams.payload.userSelections.SPACE_TYPE_STEP) {
    yield put(
      spaceTypeStepSlice.stepConfigActions.setSpaceType(
        userSelectionsParams.payload.userSelections.SPACE_TYPE_STEP,
      ),
    );
  }
  yield put(genericStepActions.initConfigCompleted());
}

function* checkDataChangesSaga(
  userSelectionsParams: PayloadAction<{
    userSelectionsParams: UserSelectionsParams;
    userSelections: UserSelections;
  }>,
) {
  const [currentDateSelected, previousFetchDate]: [
    StartEndDates,
    StartEndDates,
  ] = yield all([
    select(getDateSelectorState),
    select(
      spaceTypeStepSelectors.selectPreviousFetchDataSelection as RootStateSelector<StartEndDates>,
    ),
  ]);
  if (
    currentDateSelected.startDate !== previousFetchDate.startDate ||
    currentDateSelected.endDate !== previousFetchDate.endDate
  ) {
    yield put(
      spaceTypeSelectorActions.initUndoConfigSaga(userSelectionsParams.payload),
    );
  }
  yield put(genericStepActions.initConfigCompleted());
}

function* updateSpaceType(action: PayloadAction<SpaceTypeName>): any {
  yield all([
    put(spaceTypeStepSlice.stepConfigActions.setSpaceType(action.payload)),
  ]);
}

function* updateUnconfirmedSpaceType(
  action: PayloadAction<SpaceTypeName>,
): any {
  yield all([
    put(
      spaceTypeStepSlice.stepConfigActions.setUnconfirmedSpaceType(
        action.payload,
      ),
    ),
  ]);
}

export function* spaceTypeStepRootInitSaga() {
  yield takeEvery(spaceTypeSelectorActions.initConfig, initConfigSaga);
  yield takeEvery(spaceTypeSelectorActions.updateConfig, updateConfigSaga);
  yield takeEvery(
    spaceTypeSelectorActions.initUndoConfigSaga,
    initUndoConfigSaga,
  );
  yield takeEvery(
    spaceTypeSelectorActions.checkDataChanges,
    checkDataChangesSaga,
  );
  yield takeEvery(spaceTypeSelectorActions.updateSpaceType, updateSpaceType);
  yield takeEvery(
    spaceTypeSelectorActions.updateUnconfirmedSpaceType,
    updateUnconfirmedSpaceType,
  );
}
