import { IRepository } from "../interfaces/IRepository";
import { StepsEntity, StepsStoreEntity } from "../entities/steps.entity";
import {
  stepsAdapter,
  stepsAdded,
  stepsDeleted,
  stepsUpdated,
  getStepsStoreState,
} from "../slices/steps.slice";

export class StepsEntityRepository extends IRepository<
  StepsEntity,
  StepsStoreEntity
> {
  protected Factory = StepsEntity;

  protected stateRetrieveFunction = getStepsStoreState;

  protected storeAdapter = stepsAdapter;

  protected createAction = stepsAdded;

  protected updateAction = stepsUpdated;

  protected deleteAction = stepsDeleted;
}
