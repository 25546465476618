import { AnyAction } from "@reduxjs/toolkit";
import { StepType } from "steps/types/step";
import { IStoreEntity } from "../interfaces/IEntity";
import { IUserInteractibleEntity } from "../interfaces/IUserInteractibleEntity";
import { UserInteractEntities } from "../../enums/entities";
import { stepsUpdated } from "../slices/steps.slice";

export type StepsStoreEntity = IStoreEntity & Step;

export class StepsEntity extends IUserInteractibleEntity<StepsStoreEntity> {
  public entityType = UserInteractEntities.steps;

  public name!: string;

  public type!: StepType;

  public icon?: string;

  public order!: number;

  public getOrder(): number {
    return this.order;
  }

  public setOrder(value: number) {
    this.order = value;
  }

  public getType(): StepType {
    return this.type;
  }

  public setType(value: StepType) {
    this.type = value;
  }

  private active!: boolean;

  protected updateAction = stepsUpdated;

  public mapFromSource(obj: StepsStoreEntity): void {
    this.name = obj.name;
    this.type = obj.type;
    this.icon = obj.icon;
    this.order = obj.order;
    this.active = obj.active;
  }

  public mapToSource(): StepsStoreEntity {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      icon: this.icon,
      order: this.order,
      active: this.active,
    };
  }

  public open(): void {
    this.dispatchInteraction({ selected: true });
  }

  public isOpen(): boolean {
    const status = this.getInteractions();
    return status?.selected || false;
  }

  public close(): void {
    this.dispatchInteraction({ selected: false });
  }

  public selectItem(action: AnyAction): void {
    this.store.dispatch(action);
  }
}
