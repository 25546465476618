/* eslint-disable max-classes-per-file */
import { ActionCreatorWithPayload, Store } from "@reduxjs/toolkit";
import type { RootState } from "../..";
import { generateID } from "../../utils/generateID";

// TODO: check if it's possible to refactoring this
export abstract class IStoreEntity {
  public id!: string;
}

export abstract class IEntity<T extends IStoreEntity> {
  protected store: Store<RootState>;

  protected abstract updateAction: ActionCreatorWithPayload<T> | undefined;

  public readonly id!: string;

  public setStore(store: Store<any>) {
    this.store = store;
  }

  constructor(obj: T, store: Store<any>) {
    this.store = store;
    this.id = obj.id;
    if (!this.id) {
      this.id = generateID();
      console.warn(
        "Entity is missing id during construction, it's being created with a new generated ID",
      );
    }
  }
  /**
   * Map from Source
   * @param obj Source Object
   *
   * ID is set as readonly by constructor
   */
  public abstract mapFromSource(obj: T): void;
  /**
   * Map to Source object
   */
  public abstract mapToSource(): T;

  public update(): void {
    if (!this.updateAction)
      throw new Error("This Entity has not updateAction implemented");
    this.store.dispatch(this.updateAction(this.mapToSource()));
  }
}
