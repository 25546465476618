import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { isEqual } from "lodash";
import { useTranslation } from "@tecma/i18n";

import { useStoreRepositories } from "hooks/storeRepositories/useStoreRepositories";
import type { DefaultProps } from "utils/types/defaultProps";
import { getCleanName } from "utils/functions/formatters";
import { RecapStep } from "./components/RecapStep";
import { applicationStateSelectors } from "../../../../store/slices/applicationState/selectors";
import { RecapSectionHeader } from "./components/RecapSectionHeader";
import "./recap-content.scss";

interface RecapContentRequiredProps {
  isOpen: boolean;
}

// Optional Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RecapContentOptionalProps extends DefaultProps {}

// Combined required and optional props to build the full prop interface
export interface RecapContentProps
  extends RecapContentRequiredProps,
    RecapContentOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: RecapContentOptionalProps = {
  "data-testid": "space-configurator-recapContent",
};

const RecapContent: React.FC<RecapContentProps> = ({ isOpen }) => {
  const { StepsEntityRepository } = useStoreRepositories();
  const steps = useSelector(() => StepsEntityRepository?.selectIds(), isEqual);
  const complex = useSelector(applicationStateSelectors.selectComplex);
  const { t } = useTranslation();
  const recapContentClasses = classNames("recap-content", {
    "is-open": isOpen,
  });

  const checkIfThereIsComplexStep = steps?.some((stepId) => {
    const step = StepsEntityRepository?.selectById(stepId as string);
    return step?.getType() === "COMPLEX_STEP";
  });

  return (
    <div className={recapContentClasses}>
      {!checkIfThereIsComplexStep && complex && (
        <div className='recap-building'>
          <RecapSectionHeader title={t("complex-picker.recap.title")} />
          <p className='recap-building-name'>
            {t([`buildings.${complex}`, getCleanName(complex)])}
          </p>
        </div>
      )}
      {steps?.map((step, index) => (
        <RecapStep
          key={step}
          stepId={step}
          stepIndex={index + 1}
          buildingName={complex}
        />
      ))}
    </div>
  );
};
RecapContent.defaultProps = defaultProps as Partial<RecapContentOptionalProps>;
export default React.memo(RecapContent);
