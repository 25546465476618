import React from "react";
import classNames from "classnames";
import { Spinner } from "@tecma/ui";
import { Trans, useTranslation } from "@tecma/i18n";
import { useSelector } from "react-redux";

import PlanimetryPicker from "./PlanimetryPicker/PlanimetryPicker";
import PlanimetryItem from "./PlanimetryPicker/PlanimetryItem";
import InfoMessage from "../../../components/StepsWizard/components/InfoMessage/InfoMessage";
import type { StepsComponentProps } from "../../types/step";
import type { DefaultProps } from "../../../utils/types/defaultProps";
import type { PlanimetryStepModule } from "..";

// styles
import "./planimetry-step.scss";
import { planimetryStepSelectors } from "../state/selectors";

export interface PlanimetryStepConfig {
  plans: Planimetry[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PlanimetryStepRequiredProps {}

// Optional Props
type PlanimetryStepOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface PlanimetryStepProps
  extends PlanimetryStepRequiredProps,
    PlanimetryStepOptionalProps,
    StepsComponentProps<PlanimetryStepModule> {}

// use the optional prop interface to define the default props
const defaultProps: PlanimetryStepOptionalProps = {
  "data-testid": "space-configurator-planimetrySelectorStep",
};

const PlanimetryStep: React.FC<PlanimetryStepProps> = ({
  className,
  stepConfig,
  userSelection,
  onUserSelectionChange,
  confirmedUserSelection,
  ...rest
}) => {
  const classList = classNames("space-configurator-planimetry-step", className);
  const { t } = useTranslation();
  const planimetryStepConfig = useSelector(
    planimetryStepSelectors.selectConfig,
  );

  const plans = planimetryStepConfig?.options;

  const handlePlanimetrySelect = (planimetryName: PlanimetryName) => {
    const selectedPlanimetry = plans?.find(
      ({ name }) => name === planimetryName,
    );
    if (selectedPlanimetry) {
      onUserSelectionChange(selectedPlanimetry);
    }
  };

  return (
    <div className={classList} data-testid={rest["data-testid"]}>
      <InfoMessage
        message={t("planimetry-picker.info-message.message")}
        tooltipMessage={
          <Trans
            i18nKey='planimetry-picker.info-message.tooltip-message'
            components={{ 1: <span /> }}
          />
        }
      />
      <PlanimetryPicker>
        {plans ? (
          plans.map((planimetry: Planimetry) => (
            <PlanimetryItem
              key={planimetry.name}
              name={planimetry.name}
              active={userSelection?.name === planimetry.name}
              confirmed={confirmedUserSelection === userSelection}
              disabled={!planimetry.available}
              onSelect={() => handlePlanimetrySelect(planimetry.name)}
            />
          ))
        ) : (
          <Spinner />
        )}
      </PlanimetryPicker>
    </div>
  );
};

PlanimetryStep.defaultProps =
  defaultProps as Partial<PlanimetryStepOptionalProps>;

export default PlanimetryStep;
