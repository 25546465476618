/* eslint-disable @typescript-eslint/no-empty-interface */
import React from "react";
import classNames from "classnames";
import { DefaultProps } from "utils/types/defaultProps";
import { Trans, useTranslation } from "@tecma/i18n";
import { ComplexStepModule } from "steps/ComplexStep";
import { StepsComponentProps } from "steps/types/step";
import { MobileModal } from "components/MobileModal";
import ComplexStep from "../ComplexStep";

// styles
import "./complexModal.scss";

// Required Props
interface ComplexModalRequiredProps {}

// Optional Props
interface ComplexModalOptionalProps extends DefaultProps {}

// Combined required and optional props to build the full prop interface
export interface ComplexModalProps
  extends ComplexModalRequiredProps,
    ComplexModalOptionalProps,
    StepsComponentProps<ComplexStepModule> {}

// use the optional prop interface to define the default props
const defaultProps: ComplexModalOptionalProps = {
  "data-testid": "space-configurator-complexModal",
};

const ComplexModal: React.FC<ComplexModalProps> = ({
  className,
  stepConfig,
  onUserSelectionChange,
  userSelection,
  zone: defaultZone,
  confirmedUserSelection,
  ...rest
}) => {
  const classList = classNames("space-configurator-complexModal", className);
  const { t } = useTranslation();

  return (
    <MobileModal
      className={classList}
      step='complex'
      title={
        <Trans
          i18nKey='complex-picker.modal.title'
          components={{ b: <strong /> }}
        />
      }
      description={t("complex-picker.modal.description")}
    >
      <ComplexStep
        stepConfig={stepConfig}
        onUserSelectionChange={onUserSelectionChange}
        userSelection={userSelection}
        confirmedUserSelection={confirmedUserSelection}
        zone={defaultZone}
        isMobileModalContent
        setZoomed={() => {}}
      />
    </MobileModal>
  );
};

ComplexModal.defaultProps = defaultProps as Partial<ComplexModalOptionalProps>;

export default React.memo(ComplexModal);
