import { UserInteractEntitiesType } from "../../enums/entities";
import {
  GenericUserInteractionRecord,
  getUserInteractionsState,
} from "./slice";
import type { RootState } from "../..";

export const selectUserInteractionById = (
  state: RootState,
  id: string,
  entityType: UserInteractEntitiesType,
): GenericUserInteractionRecord => {
  const interactionsState = getUserInteractionsState(state);
  return interactionsState.interactions[entityType][id];
};
