import React, { useEffect } from "react";
import { Spinner } from "@tecma/ui";
import classNames from "classnames";
import { Header } from "components/Header";
import { PageContent } from "components/PageContent";
import { applicationStateSelectors } from "store/slices/applicationState/selectors";
import { applicationInitActions } from "store/sagas/application/init/init.actions";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "store/sagas/auth/auth.actions";

import { getSavedConfigurations } from "utils/functions/auth";
import { applicationActions } from "store/sagas/application/application.actions";
import { usePreventRefreshPage } from "hooks/usePreventRefreshPage";
import { DefaultProps } from "../../utils/types/defaultProps";
// styles
import "./page.scss";

// Required Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PageRequiredProps {}

// Optional Props
type PageOptionalProps = DefaultProps;

// Combined required and optional props to build the full prop interface
export interface PageProps extends PageRequiredProps, PageOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: PageOptionalProps = {
  "data-testid": "space-configurator-page",
};

const Page: React.FC<PageProps> = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const classList = classNames("space-configurator-page", className);
  const isLoading = useSelector(applicationStateSelectors.selectIsLoading);
  const projectInfo = useSelector(
    applicationStateSelectors.selectProjectInfo,
  ) as ProjectInfo;
  const isCheckingSavedSelection = useSelector(
    applicationStateSelectors.selectIsLoadingSavedSelection,
  );
  const isFinishClientSavedSelectionNewAuthSelection = useSelector(
    applicationStateSelectors.selectIsFinishClientSavedSelectionNewAuthSelection,
  );
  const { user, token } = useSelector((state: RootState) => state.auth);
  const { blockRefresh, setBlockRefresh } = usePreventRefreshPage(true);
  const configuration = getSavedConfigurations(projectInfo.projectID);

  useEffect(() => {
    if (configuration) {
      dispatch(
        applicationActions.saveToDBSavedUserSelectionRequested({
          configuration,
        }),
      );
    } else {
      dispatch(applicationInitActions.bootstrapSpaceConfiguratorRequested());
    }
  }, [dispatch, configuration]);

  useEffect(() => {
    if (token && user) {
      dispatch(authActions.startCountDown({ token, user }));
    }
  }, [token, user, dispatch]);

  useEffect(() => {
    if (!isLoading && !isCheckingSavedSelection && blockRefresh) {
      setBlockRefresh(false);
    }
  }, [isLoading, isCheckingSavedSelection, blockRefresh, setBlockRefresh]);

  if (isLoading || isCheckingSavedSelection) {
    return <Spinner type='dotted' className='page-loader' />;
  }

  if (
    !isLoading &&
    !isCheckingSavedSelection &&
    isFinishClientSavedSelectionNewAuthSelection
  ) {
    return <div />;
  }

  return (
    <div className={classList} {...rest}>
      <Header projectInfo={projectInfo} />
      <PageContent />
    </div>
  );
};

Page.defaultProps = defaultProps as Partial<PageOptionalProps>;

export default React.memo(Page);
