import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import classNames from "classnames";
import { Spinner, LazyImage, Button, Icon } from "@tecma/ui";
import { Trans, useTranslation } from "@tecma/i18n";

import { useDevice } from "hooks/device/device";
import { PageSheet } from "components/PageSheet";
import { PlanDetails } from "components/PlanDetails";
import type { PlanimetryStepModule } from "steps/PlanimetryStep";
import type { DefaultProps } from "../../../../utils/types/defaultProps";
import type { StepPageContentRendererProps } from "../../../types/step";

// styles
import "./planimetry-page-content.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PlanimetryPageContentRequiredProps
  extends StepPageContentRendererProps<PlanimetryStepModule> {}

// Optional Props
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PlanimetryPageContentOptionalProps extends DefaultProps {}

// Combined required and optional props to build the full prop interface
export interface PlanimetryPageContentProps
  extends PlanimetryPageContentRequiredProps,
    PlanimetryPageContentOptionalProps {}

// use the optional prop interface to define the default props
const defaultProps: PlanimetryPageContentOptionalProps = {
  "data-testid": "space-configurator-planimetryImage",
};

const PlanimetryPageContent: React.FC<PlanimetryPageContentProps> = ({
  className,
  stepConfig,
  userSelection,
  complex,
  setZoomed,
  isZoomed,
  ...rest
}) => {
  const { t } = useTranslation();
  const device = useDevice();
  const classList = classNames(
    "planimetry-page-sheet",
    { isZoomed },
    className,
  );

  const planDetail = stepConfig.options.find(
    (plan) => plan.name === userSelection?.name,
  );

  const planimetryImage = planDetail ? (
    <LazyImage.Image
      className='plan-img'
      loadingElement={
        <div className='spinner'>
          <Spinner type='dotted' />
        </div>
      }
      src={planDetail?.image || ""}
      // FIXME
      alt='Test'
    />
  ) : (
    <span className='plan-img tecma-lazyImage'>
      <div className='spinner'>
        <Spinner type='dotted' />
      </div>
    </span>
  );

  return (
    <PageSheet
      title={
        device.type === "desktop" || isZoomed ? (
          <span className='planimetry-title'>
            {isZoomed && <Icon iconName='planimetry' />}
            <Trans
              i18nKey='planimetry-image.description.title'
              values={{
                apartment: userSelection?.name,
              }}
            />
          </span>
        ) : undefined
      }
      subtitle={
        device.type === "desktop" && !isZoomed ? (
          <Trans i18nKey='planimetry-image.description.subtitle' />
        ) : undefined
      }
      isZoomed={isZoomed}
      className={classList}
    >
      <div className='planimetry-image-container'>
        {isZoomed && device.type !== "desktop" ? (
          <TransformWrapper
            initialScale={1}
            maxScale={3}
            doubleClick={{ mode: "reset" }}
          >
            <TransformComponent>{planimetryImage}</TransformComponent>
          </TransformWrapper>
        ) : (
          planimetryImage
        )}
      </div>

      <div className='planimetry-options'>
        {(device.type !== "mobile" || isZoomed) && (
          <Button
            className='planimetry-zoom'
            onClick={() => setZoomed(!isZoomed)}
            iconName={isZoomed ? "cross" : "size"}
            rounded
            size='small'
          >
            {isZoomed &&
              device.type !== "mobile" &&
              t("planimetry-image.close")}
          </Button>
        )}
        {device.type !== "mobile" && (
          <div className='details'>
            <p className='details-title'>{t("planimetry-image.details")}</p>
            <div className='details-description'>
              {planDetail && (
                <PlanDetails
                  mainFeatures={planDetail.mainFeatures}
                  spaceName={planDetail.type || ""}
                  complex={complex}
                  horizontal
                />
              )}
            </div>
          </div>
        )}
      </div>
    </PageSheet>
  );
};

PlanimetryPageContent.defaultProps =
  defaultProps as Partial<PlanimetryPageContentOptionalProps>;

export default PlanimetryPageContent;
